import { Injectable, Inject } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    CanActivateChild,
    CanLoad,
    Route,
    UrlSegment,
} from '@angular/router';
import { UserService, ToasterService } from '../services';
import { map, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        public userService: UserService,
        @Inject(Window) private window: Window,
        public router: Router,
        public toasterService: ToasterService,
    ) { }

    toast(): boolean {
        if (this.userService.user) {
            if (this.userService.user.billDue) {
                this.toasterService.setNotification({
                    type: 'warning',
                    messages: [`Your bill is due. Please <a href="${environment.BILLING_PAYMENT_URL}">click here</a> to pay.`],
                });
            }
            if (this.userService.user.sos) {
                this.toasterService.setNotification({
                    type: 'danger',
                    messages: [
                        `You are on Suspension of Service. Please <a target="_blank" href="${
                        environment.BILLING_PAYMENT_URL
                        }">click here</a> to pay your bill, or contact Customer Support at 888-398-7171`,
                    ],
                });
                return false;
            }
            if (!this.userService.user.eulaAccepted && !this.userService.isImpersonating()) {
                this.toasterService.setNotification({
                    type: 'danger',
                    messages: ['You cannot access MAX/Center without accepting the user agreement'],
                });
                return false;
            }
            return true;
        }
        return false;
    }

    checkUser(url: string): boolean {
        if (this.userService.isLoggedIn() && this.toast()) {
            return true;
        }

        this.rejectUser(url);
        return false;
    }

    rejectUser(url: string): void {
        const { location } = this.window;

        this.userService.deauthenticate();
        this.router.navigate(['/login'], {
            queryParams: {
                fromURI: `${location.protocol}//${location.hostname + (location.port ? `:${location.port}` : '')}${url}`,
            },
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (!this.userService.user && this.userService.isLoggedIn()) {
            return this.userService.getMe()
                .pipe(map(() => this.checkUser(state.url)), first());
        } else {
            return this.checkUser(state.url);
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.canActivate(route, state);
    }
}
