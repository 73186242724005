import { HttpHeaders } from '@angular/common/http';

export class ErrorsMessaging {
    data: string;
    status: string;
    userId?: number;
    name?: string;
}

export class Errors {
    error: ErrorsMessaging;
    headers: HttpHeaders;
    message: string;
    name: string;
    ok: boolean;
    status: number;
    statusText: string;
    url: string;
}
