import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Directive({
  selector: '[mxcZeroStateImg]',
})
export class ZeroStateImgDirective {

  private zeroStateImage = `${environment.STATIC_S3_URL}/img/zero-state-tile.png`;
  private imgElement: HTMLImageElement;

  constructor(private ref: ElementRef) {
    this.imgElement = this.ref.nativeElement;
  }

  @HostListener('error')
  loadZeroStateOnError(): void {
    this.imgElement.src = this.zeroStateImage;
  }
}
