import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ListControls } from '../../models';
import { HighlighterPipe } from '../../pipes';
import { SearchService } from '../../services/search.service';

@Component({
    selector: 'mxc-list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent implements OnInit {
    @Input() controls: ListControls;
    @Input() total: number; // just for testing purposes
    @Output() sortBy: EventEmitter<string> = new EventEmitter<string>();
    @Output() clickRow: EventEmitter<string> = new EventEmitter<string>();
    clickable = false;
    searchTerm: string | undefined = undefined;

    constructor(
        private searchService: SearchService,
        public highlighter: HighlighterPipe,
    ) {}

    ngOnInit(): void {
        this.clickable = this.clickRow.observers.length > 0;
        this.searchService.searchTerm.subscribe((term) => {
            this.searchTerm = term;
        });
    }

    isString(val): boolean { return typeof val === 'string'; }
}
