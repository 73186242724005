import { Injectable, OnInit } from '@angular/core';
import { PaginationComponent } from '../pagination/pagination.component';
import { orderBy, UrlParams, UserSearchFilter } from '../../../roster/roster.model';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { SearchFilters, OptionsObject, userClassOptions } from '../../models';
import { FilterObject } from '../../../content/content.model';
import { combineLatest, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { UserService, BannerService } from '../../services';
import { Banner, BannerResponse } from '../../models';
import { Meta, Title } from '@angular/platform-browser';
import { SegmentService } from '../../services/segment.service';

@Injectable()
export abstract class RosterBaseComponent extends PaginationComponent implements OnInit {
    isCorporateUser = false;
    approvedSupplier = false;
    apiUrl = environment.API_URL;
    s3Path: string = environment.STATIC_S3_URL;
    currentUserClass: userClassOptions;
    totalResults = 0;
    currentResultsStart = 1;
    searchObject = 'User';
    rosterService: any;
    rosterFilters: SearchFilters = {
        location: { label: 'Search by City, State, Postal Code, Country, Region', type: 'text', selected: '' },
        locationId: { label: '', type: 'text', selected: '' },
        title: { selected: '', options: [], type: 'multiselect', label: 'Title' },
        specialty: { selected: '', options: [], type: 'multiselect', label: 'Specialty' },
        subspecialty: { selected: '', options: [], type: 'multiselect', label: 'Subspecialty' },
        awards: { selected: '', type: 'multiselect', options: [], label: 'Awards' },
        designations: { selected: '', options: [], type: 'multiselect', label: 'Designations' },
        yearJoined: { selected: '', options: [], type: 'multiselect', label: 'RE/MAX Joined Date' },
        yearsExp: {
            selected: '',
            options: ['0-3', '3-5', '5-10', '10-20', '20+'],
            type: 'multiselect',
            label: 'Years Experience',
        },
        languages: { selected: '', options: [], type: 'multiselect', label: 'Languages' },
        photoUrl: { label: 'Has Photo', type: 'checkbox', selected: '' },
        licensed: { label: 'Licensed', type: 'checkbox', selected: '' },
        includeServiceArea: { label: 'Include Service Area', type: 'checkbox', selected: '1' },
    };
    fallbackMessage = 'Enter a search to view roster results.';
    emptyResults = false;
    urlQueryParams: UrlParams = {};
    bannerData: Banner[] = [];
    private tilesPerPage = 24;
    viewType: string;

    constructor(
        router: Router,
        public meta: Meta,
        public title: Title,
        public activatedRoute: ActivatedRoute,
        public userService: UserService,
        public bannerService: BannerService,
        public segmentService: SegmentService,
    ) {
        super(router);
        this.meta.updateTag({ name: 'description', content: 'View All RE/MAX Affiliates' });
        this.title.setTitle('MAX/Center: Find an Affiliate');
    }

    abstract searchUsers(): void;

    ngOnInit(): void {
        this.getBannerData(this.bannerService.getAll());
        this.currentUserClass = this.userService.user.class;
        this.isCorporateUser = this.userService.user.groups.includes('Corporate');
        this.activatedRoute.queryParamMap.subscribe(queryParams => {
            this.rosterService.clearFilterOptions();
            this.searchTerm = queryParams.get('search');
            this.handleParams(queryParams);

            if (this.searchTerm) {
                let orderBy: orderBy;
                if (queryParams.get('order') === 'asc') {
                    orderBy = 'asc';
                } else if (queryParams.get('order') === 'desc') {
                    orderBy = 'desc';
                }
                this.urlQueryParams = {
                    search: this.searchTerm,
                    page: queryParams.get('page') || '1',
                    sortBy: queryParams.get('sortBy'),
                    order: orderBy,
                    locationId: queryParams.get('locationId'),
                };
                this.rosterService.setFilterOptions({ query: this.searchTerm }, 'user');
            }

            combineLatest([this.rosterService.getStaticUserFilters(), this.rosterService.getDynamicUserFilters()]).subscribe(
                // @ts-ignore
                ([staticFilters, dynamicFilters]) => {
                    const newFilters: SearchFilters = {
                        ...this.rosterFilters,
                        title: {
                            ...this.rosterFilters.title,
                            options: [...staticFilters['data'].title],
                        },
                        specialty: {
                            ...this.rosterFilters.specialty,
                            options: [...staticFilters['data'].specialty],
                        },
                        subspecialty: {
                            ...this.rosterFilters.subspecialty,
                            // @ts-ignore
                            options: [...dynamicFilters['data'].subspecialty],
                        },
                        languages: {
                            ...this.rosterFilters.languages,
                            // @ts-ignore
                            options: [...dynamicFilters['data'].language],
                        },
                        designations: {
                            ...this.rosterFilters.designations,
                            // @ts-ignore
                            options: [...dynamicFilters['data'].designations],
                        },
                        awards: {
                            ...this.rosterFilters.awards,
                            options: [...staticFilters['data'].awards],
                        },
                        yearJoined: {
                            ...this.rosterFilters.yearJoined,
                            options: [...staticFilters['data'].yearJoined],
                        },
                    };
                    this.rosterFilters = this.generateFilters(newFilters, queryParams);

                    if (
                        this.searchTerm ||
                        this.rosterFilters.title.selected ||
                        this.rosterFilters.specialty.selected ||
                        this.rosterFilters.subspecialty.selected ||
                        this.rosterFilters.languages.selected ||
                        this.rosterFilters.designations.selected ||
                        this.rosterFilters.location.selected ||
                        this.rosterFilters.awards.selected ||
                        this.rosterFilters.yearsExp.selected ||
                        this.urlQueryParams.licensed ||
                        this.urlQueryParams.photoUrl ||
                        !this.urlQueryParams.includeServiceArea ||
                        this.rosterFilters.yearJoined.selected
                    ) {
                        this.urlQueryParams = { ...this.urlQueryParams, page: `${this.currentPage}` };
                        this.rosterService.setFilterOptions({ currentPage: `${this.currentPage}` }, 'user');
                        this.searchUsers();
                    } else {
                        this.tileData = [];
                        this.isLoading = false;
                    }
                },
            );
        });
    }

    getSelectFilterValue(value: string, isCheckbox: boolean): string {
        if (isCheckbox) {
            return value === '1' ? value : '';
        } else {
            return value || '';
        }
    }

    generateFilters(filters: SearchFilters, params): UserSearchFilter {
        return Object.keys(filters).reduce(
            (memo, filterName) => {
                const filterOptions: (string | OptionsObject)[] = filters[filterName].options;
                let selected = '';
                if (filters[filterName].type === 'checkbox' && !this.urlQueryParams.page) {
                    selected = this.getSelectFilterValue(
                        params.get(filterName) === null ? filters[filterName].selected : params.get(filterName),
                        filters[filterName].type === 'checkbox',
                    );
                } else {
                    selected = this.getSelectFilterValue(params.get(filterName), filters[filterName].type === 'checkbox');
                }

                if (selected) {
                    const filterOption = {
                        [filterName]: selected,
                    };

                    this.rosterService.setFilterOptions(filterOption, 'user');

                    this.urlQueryParams = {
                        ...this.urlQueryParams,
                        ...filterOption,
                    };
                    this.updateUrl();
                }

                return {
                    ...memo,
                    [filterName]: {
                        ...(filters[filterName].label || filters[filterName].type ? filters[filterName] : {}),
                        selected,
                        ...(filterOptions ? { options: filterOptions } : {}),
                    },
                };
            },
            {} as UserSearchFilter,
        );
    }

    searchRoster(searchTerm: string): void {
        if (searchTerm) {
            this.urlQueryParams = {
                ...this.urlQueryParams,
                search: searchTerm,
                page: '1',
            };
        } else {
            const { search, ...otherQueryParams } = this.urlQueryParams;
            this.urlQueryParams = {
                ...otherQueryParams,
                page: '1',
            };
            this.totalResults = 0;
        }
        this.updateUrl();
    }

    removeFilter(keyToRemove: string, collectionToRemoveFrom: FilterObject): FilterObject {
        let { [keyToRemove]: discard, ...remainingParams } = collectionToRemoveFrom;

        //  Set flags rather than remove fields entirely for booleans
        const value = parseInt(discard + '', 10);
        if (value === 1 || value === 0) {
            collectionToRemoveFrom[keyToRemove] = '0';

            return collectionToRemoveFrom;
        }

        return remainingParams;
    }

    changeFilter(filter: FilterObject): void {
        const clearedFilters = Object.values(filter).every((element) => (element === null || element === ''));
        let filterKey = this.rosterService.filterOptions ? 'filterOptions' : 'officeFilterOptions';
        if (this.rosterService.filterOptions) { // Sometimes filterOptions is defined, but is just an empty object
            filterKey = Object.keys(this.rosterService.filterOptions).length !== 0 ? 'filterOptions' : 'officeFilterOptions';
        }

        if (clearedFilters) {
            this.segmentService.track('Search Option Cleared', {
                search_type: this.rosterService.constructor.name === 'ApprovedSupplierService' ? 'Approved Supplier' : 'Roster',
                search_object: this.searchObject,
                query: this.searchTerm,
                view_Type: this.viewType,
                ...this.rosterService[filterKey],
            });
        }

        Object.keys(filter).forEach(key => {
            if (!filter[key] || filter[key].length === 0 || filter[key] === '0') {
                this.urlQueryParams = this.removeFilter(key, this.urlQueryParams);
                this.rosterService[filterKey] = this.removeFilter(key, this.rosterService[filterKey]);
            } else {
                let joinedFilter: any;
                if (Array.isArray(filter[key])) {
                    joinedFilter = (filter[key] as string[]).join(',');
                }
                this.urlQueryParams = {
                    ...this.urlQueryParams,
                    ...{ [key]: joinedFilter || filter[key] },
                    page: '1',
                };
            }
        });
        this.totalResults = 0;
        this.updateUrl();
    }

    updateFilters(filterCollection: FilterObject): void {
        this.urlQueryParams = {
            ...this.urlQueryParams,
            ...filterCollection,
        };
    }

    handleParams(params: ParamMap): void {
        const page = +params.get('page');
        if (page) {
            this.currentPage = page;
        }
    }

    handleEmptyResult(): void {
        this.fallbackMessage = '<h1>No results. Please try again.</h1>';
        this.isLoading = false;
        this.emptyResults = true;
        this.tileData = [];
    }

    getBannerData(getBanner: Observable<BannerResponse>): void {
        getBanner.subscribe((res: BannerResponse) => {
            this.bannerData = res.data;
        });
    }

    calculateNumberOfPages(numberOfIds: number): number {
        return Math.ceil(numberOfIds / this.tilesPerPage);
    }

    getCurrentPageOfIds(ids: number[]): number[] {
        return ids.slice((this.currentPage - 1) * this.tilesPerPage, this.currentPage * this.tilesPerPage);
    }
}
