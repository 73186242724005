import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { VideoModalComponent } from './video-modal.component';
import { SharedModule } from '../../../shared';

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule],
    exports: [VideoModalComponent],
    declarations: [VideoModalComponent],
})
export class VideoModalComponentModule { }
