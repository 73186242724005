import { BlockResponse, AssignedBlock } from '../models/block.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Block, DashboardResponse } from '../models/block.model';
import { ToasterService } from './toaster.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BlockService {

  constructor(private http: HttpClient, private toaster: ToasterService) { }

  getDashboardForUser(): Observable<DashboardResponse> {
    return this.http.get(`${environment.API_URL}/block/user-dashboard`)
      .pipe(
        catchError(err => this.toaster.handleErrors(err)),
        map((res: DashboardResponse) => res),
      );
  }

  getCustomBlocksForUser(): Observable<Block[]> {
    return this.http.get(`${environment.API_URL}/block/user`)
      .pipe(
        catchError(err => this.toaster.handleErrors(err)),
        map((res: BlockResponse) => res.blocks),
      );
  }

  getAllBlocksForRole(role: string): Observable<Block[]> {
    return this.http.get(`${environment.API_URL}/block/role/${role}`)
      .pipe(
        catchError(err => this.toaster.handleErrors(err)),
        map((res: BlockResponse) => res.blocks),
      );
  }

  updateRoleBlocks(role: string, blocks: AssignedBlock[]): Observable<BlockResponse> {
    return this.http.post(`${environment.API_URL}/block/role`, { role: role, blocks: blocks })
      .pipe(
        catchError(err => this.toaster.handleErrors(err)),
        map((res: BlockResponse) => res),
      );
  }

  updateUserBlocks(blocks: AssignedBlock[]): Observable<BlockResponse> {
    return this.http.post(`${environment.API_URL}/block/user`, { blocks: blocks })
      .pipe(
        catchError(err => this.toaster.handleErrors(err)),
        map((res: BlockResponse) => res),
      );
  }
}
