<mxc-header-alt-v2></mxc-header-alt-v2>
<div class="container-fluid px-0 py-0 mb-4">
    <ng-container *ngIf="errors && errors.error">
        <div class="d-flex flex-row justify-content-center mb-4" >
            <mxc-list-errors icon="error" [errors]="errors"></mxc-list-errors>
        </div>
    </ng-container>
    <div class="d-flex flex-row justify-content-center">
        <div class="col-12 col-xs-10 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <mxc-auth-form
                [title]="'login.formTitle'"
                [details]="'login.formDetails'"
                data-hook="login-form"
            >
                <div class="row">
                    <div class="col-12">
                        <mxc-input
                            dataHook="email"
                            type="email"
                            [error]="emailError"
                            [label]="'inputLabels.remaxNetEmail'"
                            [status]="emailStatus"
                            [required]="true"
                            [disabled]="isSubmitting"
                            [tabindex]="0"
                            [name]="'email'"
                            (onChange)="onEmailInput($event)"
                            [placeholder]="'E.g Ssmith@remax.net'"
                        ></mxc-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mxc-input
                            dataHook="password"
                            type="password"
                            [label]="'inputLabels.password'"
                            [required]="true"
                            [error]="loginError"
                            [disabled]="isSubmitting"
                            [tabindex]="0"
                            [name]="'password'"
                            [secureTextEntry]="true"
                            (onChange)="onPasswordInput($event)"
                        ></mxc-input>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 mt-4 text-center">
                        <button 
                            type="submit"
                            class="form-button full-width"
                            tabindex="1"
                            data-hook="login-button"
                            [disabled]="!canSubmit()||isSubmitting" 
                            [attr.aria-label]="'login.submitLabel' | translate" 
                            (click)="submit($event)" 
                        >
                            <span>{{'login.submitLabel' | translate}}</span>
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 mt-4 text-center">
                        <a class="brand-link" href="/forgot-password" data-hook="forgot-password-link">{{'login.forgotPassword' | translate}}</a>
                    </div>
                </div>
            </mxc-auth-form>
        </div>
    </div>
</div>
<mxc-footer-alt-v2 [links]="[{'path': '/register', 'label': 'login.registerLabel' | translate, 'hook': 'register-link'}]">
</mxc-footer-alt-v2>
<ng-template #eulaModal let-d="dismiss">
    <div class="modal-body" data-hook="legal-modal">
        <mxc-legal (eulaAccepted)="userAcceptedEula($event)"></mxc-legal>
    </div>
</ng-template>