<!-- SHORT PROFILE CARD -->
<div class="short-profile card">
    <div class="card-header-group d-flex">
        <div *ngIf="photo != 0" class="card-header-group-prepend d-flex">
            <a title="User Profile Photo" routerLink="/roster/user/{{userId}}">
                <img class="nav-img" data-hook="profile-image"
                    src="{{ userId | photoUrl:'profilePhoto':{staticTimestamp:true} }}">
            </a>
        </div>
        <div class="card-header bg-tertiary d-flex align-items-center">
            <div *ngIf="isProfile; else linkToProfile">
                <h3 class="text-dark m-0" data-hook="user-name">{{ name }}</h3>
                <div class="text-dark" data-hook="user-title">{{ title }}</div>
            </div>
            <ng-template #linkToProfile>
                <a class="btn btn-block btn-link text-dark text-left d-flex align-items-center justify-content-between p-0"
                    data-hook="profile-link" routerLink="/roster/user/{{userId}}">
                    <h3 class="m-0" data-hook="user-name">{{ name }}</h3>
                    <fa-icon icon="angle-right"></fa-icon>
                </a>
            </ng-template>
        </div>
    </div>
</div>