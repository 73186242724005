import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    templateUrl: './style-guide.component.html',
    styleUrls: ['../shared/components/short-profile/short-profile.component.scss'],
})

export class StyleGuideComponent {
    selectValue = 'Choose an option...';
    selectOptions = [
        'Option 1',
        'Option 2',
        'Option 3',
    ];
    active: boolean;
    postId: number;

    constructor(
        private meta: Meta,
        private title: Title,
    ) {
        this.meta.updateTag({ name: 'description', content: 'Internal Style Guide' });
        this.title.setTitle('MAX/Center: Style Guide');
    }

    changeMessage(selectedItem: string): void {
        this.selectValue = selectedItem;
    }
}
