import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
declare let ga: Function;

@Injectable()
export class GoogleAnalyticsEventsService {
    emitEvent(
        eventAction: string,
        eventLabel: string,
        fields: any = {},
    ): void {
        ga('send', {
            hitType: 'event',
            eventCategory: environment.APP_NAME,
            eventAction,
            eventLabel,
            ...{
                ...fields,
                appName: environment.APP_NAME,

            },
        });
    }
}
