import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToasterService } from './toaster.service';
import { HttpClient } from '@angular/common/http';
import { StaticUserFilterResponse, DynamicUserFilterResponse, FilterOptions, UserReferralStatsResponse, UserAwardStatsResponse } from '../../roster/roster.model';

@Injectable()
export class SharedRosterService {
    filterOptions: FilterOptions = {};
    officeFilterOptions: FilterOptions = {};

    constructor(protected http: HttpClient, protected toaster: ToasterService) { }

    getStaticUserFilters(): Observable<StaticUserFilterResponse> {
        return this.http
            .get(`${environment.API_URL}/users/get-filters`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getDynamicUserFilters(): Observable<DynamicUserFilterResponse> {
        return this.http
            .get(`${environment.API_URL}/user/roster-filters`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    setFilterOptions(options: FilterOptions, context: 'user' | 'office'): void {
        const filterOptionKey = context === 'user' ? 'filterOptions' : 'officeFilterOptions';

        this[filterOptionKey] = {
            ...this[filterOptionKey],
            ...options,
        };
    }

    clearFilterOptions(): void {
        this.filterOptions = {};
        this.officeFilterOptions = {};
    }

    generateUrlFilters(context): string {
        const filter = (context === 'user') ? this.filterOptions : this.officeFilterOptions;
        return Object.keys(filter).reduce((memo, filterName) => {
            return `${memo}${filterName}=${filter[filterName]}&`;
        }, '');
    }

    getReferralStats(): Observable<UserReferralStatsResponse> {
        return this.http.get(`${environment.API_URL}/referral/stats`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getAwardStats(): Observable<UserAwardStatsResponse> {
        return this.http.get(`${environment.API_URL}/user/awards-progress`)
            .pipe(catchError(err => {
                err.error.data = 'There was an error retrieving your awards.';
                return this.toaster.handleErrors(err);
            }));
    }
}
