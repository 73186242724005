import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  userService: UserService;
  inFlight: boolean = false;
  constructor(private inj: Injector) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.userService = this.inj.get(UserService);
    if (!this.userService.jwtPayload) {
      return next.handle(request);
    }
    if (this.userService.isSessionExpiringSoon() && !this.inFlight) {
      this.inFlight = true;
      this.userService.extendSession().subscribe(res => {
        this.inFlight = false;
        this.userService.saveTokens({
          token: res.data.token,
          sessionToken: res.data.sessionToken,
          timestamp: res.data.timestamp,
        });
      }, error => {
        this.inFlight = false;
      });
    }
    return next.handle(request);
  }
}
