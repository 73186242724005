import { trigger, transition, animate, style, state } from '@angular/animations';

export const slideOutAnimation = trigger('slideOut', [
    state('open',
        // start
        style({ width: '20em' }),
        // end
    ),
    state('closed',
        // start
        style({ width: '5em' }),
        // end
    ),
    transition('open => closed', [
        animate('0.1s'),
    ]),
    transition('closed => open', [
        animate('0.1s'),
    ]),
]);
