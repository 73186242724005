import { Component, Inject } from '@angular/core';
import { UserService } from '../../../shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'mxc-change-user',
    templateUrl: './change-user.component.html',
})

export class ChangeUserComponent {

    changeUserForm: FormGroup;
    impersonating = this.userService.isImpersonating();

    constructor(
        private userService: UserService,
        private fb: FormBuilder,
        @Inject(Window) private window: Window,
        private activeModal: NgbActiveModal,
    ) {
        this.changeUserForm = this.fb.group({
            username: ['', Validators.compose([Validators.required, Validators.email])],
        });
    }

    submitForm(): void {
        this.activeModal.close();
        this.userService.changeUser(this.changeUserForm.value).subscribe(() => this.window.location.reload());
    }

    revertUser(): void {
        this.activeModal.close();
        this.userService.revertUser().subscribe(() => this.window.location.reload());

    }

    closeModal(): void {
        this.activeModal.dismiss();
    }
}
