
  <a
  title="Launch App"
  class="app-image"
  data-hook="app-link"
  (click)="clickAction(linkUrl, newWindow ? true : false);"
>
  <div class="app-image--image">
    <img [alt]="title" [src]="thumbnailUrl" data-hook="app-image" mxcZeroStateImg/>
  </div>
  <div class="app-image--content">
    <div class="app-image--content--header">
        <h4 class="app-image--content--header--title" data-hook="app-title" [innerHTML]="title | highlighter:searchTerm:false:!searchTerm?.length"></h4>
        <div>
          <mxc-favorite type="apps" [id]="appId" responsive="true" [appName]="title" app="app"></mxc-favorite>
        </div>
    </div>
    <div class="app-image--content--body">
      <p data-hook="app-description" [innerHTML]="description | highlighter:searchTerm:null:!searchTerm?.length"></p>
    </div>
  </div>
</a>
