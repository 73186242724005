<div class="mxc-session-timeout"
     *ngIf="displayed"
>
    <mxc-modal-v2
        [title]="'components.sessionTimeout.title' | translate"
        [buttons]="buttons"
        data-hook="mxc-modal-v2"
        class="session-timeout"
    >
        <div class="mxc-paywall-content">
            <p class="mxc-paywall-p">{{ 'components.sessionTimeout.preMessage' | translate }} <mxc-timer></mxc-timer> {{ 'components.sessionTimeout.postMessage' | translate }}</p>
        </div>
    </mxc-modal-v2>

</div>