import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ListErrorsComponent, OktaService, PasswordModule, SharedModule } from '../shared';
import { ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

import { LayoutModule } from '../layout/layout.module';
import { RouteViewAltComponent } from '../layout';
import { LoginComponent } from './login.component';
import { LegalModalModule } from '../legal/legal-modal/legal-modal.module';
import { ExpiredPasswordComponent } from './expired-password/expired-password.component';

// font awesome imports
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faExclamationTriangle, faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


const authRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'login/:stateToken',
        component: RouteViewAltComponent,
        children: [{
            path: '',
            component: ExpiredPasswordComponent,
        }],
    },
]);

@NgModule({
    imports: [
        authRouting,
        CommonModule,
        ReactiveFormsModule,
        LayoutModule,
        NgbModule,
        LegalModalModule,
        FontAwesomeModule,
        PasswordModule,
        SharedModule,
    ],
    declarations: [
        LoginComponent,
        ListErrorsComponent,
        ExpiredPasswordComponent,
    ],
    providers: [
        CookieService,
        { provide: Window, useValue: window },
        OktaService,
    ],
})
export class LoginModule {
    constructor (
        public library: FaIconLibrary,
    ) {
        library.addIcons(faExclamationTriangle, faTimes, faCheckCircle, faTwitter, faEnvelope);
    }
}
