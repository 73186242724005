<div class="w-100 d-table pl-4 mb-1">
  <div class="nav-icon" [ngClass]="{ active: isActive }">
    <a
      class="nav-link d-flex align-items-center w-100"
      [ngClass]="{ 'is-active': isActive }"
      data-hook="app-nav-link"
      [attr.data-nav]="navKey"
      name="{{ title }}"
      (click)="isOpen = !isOpen; open()"
    >
      <fa-icon
        *ngIf="title === 'News & Resources'; else icon"
        [icon]="getCurrentIcon()"
        class="fa-rotate-45"
      ></fa-icon>
      <ng-template #icon>
        <fa-icon [fixedWidth]="true" [icon]="getCurrentIcon()" [title]="title"> </fa-icon>
      </ng-template>
      <span class="ml-3 mr-auto nav-icon-title">{{ title }}</span>
      <fa-icon class="align-middle mr-4 caret-icon mb-2" icon="caret-right" [ngClass]="{ active: isOpen }"></fa-icon>
    </a>
  </div>
  <ul *ngIf="isOpen && navIsOpen" class="list-unstyled nested-nav-item" data-hook="sub-nav-menu">
    <li *ngFor="let link of links" class="pb-2">
      <a
        data-hook="nested-nav-link"
        [attr.data-nav]="link.navKey"
        class="nested-link"
        (click)="close(); userService.continueOrLogout();"
        [routerLink]="link.path"
        routerLinkActive="active"
        title="{{ link.name }}"
        >{{ link.name }}</a
      >
    </li>
    <li *ngIf="isTools && isImpersonator && !userService.isImpersonating()" class="py-1">
      <button class="nested-link btn-link p-0" (click)="openChangeUserModal()" data-hook="change-user-menu-item" data-nav="change-user">
        Change User
      </button>
    </li>
    <li *ngIf="isTools && userService.isImpersonating()" class="py-1">
      <button class="nested-link btn-link p-0" (click)="openChangeUserModal()" data-hook="change-user-menu-item" data-nav="stop-impersonating">
        Stop Impersonating
      </button>
    </li>
  </ul>
</div>
