import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { BannerResponse } from '../models/banner.model';
import { ToasterService } from './toaster.service';


@Injectable()
export class BannerService {

    constructor(
        private http: HttpClient,
        private toaster: ToasterService,
    ) { }

    getAll(): Observable<BannerResponse> {
        return this.http.get(`${environment.API_URL}/banner`)
            .pipe(
                catchError(err => {
                    return this.toaster.handleErrors(err);
                }),
            );
    }
}
