import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
@Injectable({
    providedIn: 'root',
})
export class ProfileMenuItemService {
  private scrolledMenuItemSubject = new BehaviorSubject<any>(null);
  currentAnchor = this.scrolledMenuItemSubject.asObservable();
  private clickedMenuItemSubject = new BehaviorSubject<any>(null);
  currentClickedMenuItem = this.clickedMenuItemSubject.asObservable();

  setScrolledMenuItemObj(menuItemObj: any) {
    this.scrolledMenuItemSubject.next(menuItemObj);
  }

  setClickedMenuItem(payload: any) {
    this.clickedMenuItemSubject.next(payload);
  }
}
