import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAppCategoryId',
})
export class FilterAppCategoryIdPipe implements PipeTransform {

  transform(items: Array<any>, appCategoryId: string): any {
    return items.filter(item => item.appCategoryId === appCategoryId || appCategoryId === 'all');
  }

}
