import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, BehaviorSubject, of, Subject} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { FavoriteCache, FavoriteData, FavoriteResponse, favId, favEndpointType, SuccessResponse } from '../models';
import { HttpCache } from '../interceptors/HttpCache';
import { ToasterService } from './toaster.service';

interface Fav { id: favId; }

@Injectable()
export class FavoriteService {
    loadingFav = false;
    private subject = new BehaviorSubject<FavoriteCache>(new FavoriteCache());
    public favoritesUpdate = new Subject<boolean>();
    constructor(
        private http: HttpClient,
        private cache: HttpCache,
        private toaster: ToasterService,
    ) { }

    getFavorites(): Observable<FavoriteResponse> {
        if (this.loadingFav) {
            return of(null);
        }
        this.loadingFav = true;
        return this.http.get(`${environment.API_URL}/favorite`)
            .pipe(
                catchError(err => {
                    this.loadingFav = false;
                    return this.toaster.handleErrors(err);
                }),
                map((res: FavoriteResponse) => {
                    this.loadingFav = false;
                    this.setFavoriteCache(this.favoriteHashKeying(res.data));
                    return res;
                }),
            );
    }

    favoriteHashKeying(favs: FavoriteData): FavoriteCache {
        return Object.keys(favs).reduce((memo, favType) => {
            const favArray = favs[favType];
            return {
                ...memo,
                [favType]: favArray.reduce((favMemo, fav) => {
                    return {
                        ...favMemo,
                        [fav.id]: true,
                    };
                }, {}),
            };
        },
            { apps: {}, posts: {}, offices: {}, users: {} });
    }

    postFavorite(id: favId, type: favEndpointType): Observable<{ status: 'success' }> {
        this.invalidateFavoriteHttpCache();
        return this.http.post(`${environment.API_URL}/favorite/${type}`, { id })
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    deleteFavorite(id: favId, type: favEndpointType): Observable<SuccessResponse> {
        this.invalidateFavoriteHttpCache();
        return this.http.delete(`${environment.API_URL}/favorite/${type}?id=${id}`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    invalidateFavoriteHttpCache(): void {
        this.cache.delete(`${environment.API_URL}/favorite`);
        this.cache.delete(`${environment.API_URL}/apps/top`);
    }

    setFavoriteCache(favorites: FavoriteCache): void {
        this.subject.next(favorites);
    }

    getFavoriteCache(): Observable<FavoriteCache> {
        return this.subject.asObservable();
    }
}
