import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class MenuV2Service {

  public sectionSubMenuToggle: boolean[] = [];
  routeIndex: number;
  currentRouteIndex: number;

  constructor() { }

}
