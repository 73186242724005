import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';
import { UserService } from './user.service';

import {
    AppEvent,
    ClickEvent,
    FavoriteEvent,
    LinkEvent, LoginEvent,
    QREvent,
    ReferralEvent, SearchEvent,
    ViewEvent
} from '../utilities/google-tag-manager-helpers/google-tag-manager-helpers';
import { PostPackage } from '../../content/content.model';
import * as moment from 'moment/moment';
import { EventsService } from './events.service';

@Injectable({
    providedIn: 'root'
})
export class GoogleTagManagerService {
    public window;

    protected login: boolean = false;

    constructor(
        private userService: UserService,
        private eventsService: EventsService,
        private _windowRef: WindowReferenceService
    ) {
        this.window = _windowRef.nativeWindow;

        this.window.dataLayer = this.window.dataLayer || [];
    }

    public pushDataLayer(obj: any): void {
        const { user } = this.userService;

        if (!user) {
            return;
        }

        const newObject = {...{
            user_id: user.id,
            user_category: user.title
        }, ...obj};

        delete newObject.event_name;

        if (newObject) {
            this.window.dataLayer.push(newObject);
        }
    }

    public loginEvent(eventData: LoginEvent): void {
        if (!localStorage.getItem('sessionToken') || localStorage.getItem('recordedLoginSession')) {
            return;
        }

        if (localStorage.getItem('sessionToken')) {
            localStorage.setItem('recordedLoginSession', 'true');
            const {event_name} = eventData;
            this.pushDataLayer({
                event: event_name,
                method: eventData.method
            });

        }
    }

    public clickEvent(eventData: ClickEvent, eventTarget?: Event): void {
        const { event_name } = eventData;
        if (eventTarget?.target) {
            const {innerHTML} = (eventTarget.target as HTMLElement);
            eventData.element_text = innerHTML;
        }

        const typeAddSuffix = !eventData.type.includes('link') ?
            `${eventData.type} link` : eventData.type;

        this.pushDataLayer({
            event: this.adjustEventName(event_name, 'click'),
            element_text: eventData.element_text ?? '',
            element_type: typeAddSuffix
        });
    }

    public viewEvent(eventData: ViewEvent): void {
        const { event_name } = eventData;
        const gmtData = this.returnNestedData(event_name, eventData);

        this.pushDataLayer({
            event: this.adjustEventName(event_name, 'view', false),
            ...gmtData
        });
    }

    public searchEvent(eventData: SearchEvent): void {
        const { event_name } = eventData;

        this.pushDataLayer({
            event: event_name,
            ...eventData
        });
    }

    public appEvent(eventData: AppEvent): void {
        const { event_name } = eventData;

        this.pushDataLayer({
            event: this.adjustEventName(event_name, 'app', false),
            ...eventData
        });
    }

    public favoriteEvent(eventData: FavoriteEvent): void {
        const { event_name } = eventData;

        this.pushDataLayer({
            event: this.adjustEventName(event_name, 'to_favorites'),
            ...eventData
        });
    }

    public referralEvent(eventData: ReferralEvent): void {
        const { event_name } = eventData;
        const gmtData = this.returnNestedData(event_name, eventData);

        this.pushDataLayer({
            event: this.adjustEventName(event_name, 'referral'),
            ...gmtData
        });
    }

    public linkEvent(eventData: LinkEvent): void {
        const { event_name } = eventData;

        this.pushDataLayer({
            event: this.adjustEventName(event_name, 'link'),
            ...eventData
        });
    }

    public qrEvent(eventData: QREvent): void {
        const { event_name } = eventData;

        this.pushDataLayer({
            event: this.adjustEventName(event_name, 'download'),
            ...eventData
        });
    }

    public eventDateFormatForGTM(event: PostPackage, format: string = 'MM/DD/YYYY'): string {
        const start = moment(event.startsAt * 1000 ).tz('America/Denver', true).tz(this.eventsService.userTimezone);
        const end = moment(event.endsAt * 1000).tz('America/Denver', true).tz(this.eventsService.userTimezone);

        if ( (start.month() !== end.month()) || (start.month() === end.month() && start.day() !== end.day()) ) {
            return `${start.format(format)} - ${end.format(format)}`;
        }

        return `${start.format(format)}`;
    }

    private returnNestedData(eventName: string, data: any): any {
        return data[eventName];
    }

    private adjustEventName(eventName: string, affix: string, suffix: boolean = true): any {
        let modifyEventName = `${eventName}_${affix}`;

        if (!suffix) {
            modifyEventName = `${affix}_${eventName}`;
        }

        return modifyEventName;
    }
}

export class MockWindowReferenceService {
    nativeWindow = {
        dataLayer: [],
    };
}

export class MockGoogleTagManagerService {
    public pushedData: any[] = [];

    pushDataLayer(obj: any): void {
        // Instead of pushing to the actual data layer, store the pushed data in the property
        this.pushedData.push(obj);
    }

    loginEvent(eventData: LoginEvent): void {}
    clickEvent(eventData: ClickEvent, eventTarget?: Event): void {}
    viewEvent(eventData: ViewEvent): void {}
    searchEvent(eventData: SearchEvent): void {
        this.pushedData.push(eventData);
    }
    appEvent(eventData: AppEvent): void {}
    favoriteEvent(eventData: FavoriteEvent): void {}
    referralEvent(eventData: ReferralEvent): void {}
    linkEvent(eventData: LinkEvent): void {}
    qrEvent(eventData: QREvent): void {}
    eventDateFormatForGTM(event: PostPackage, format: string = 'MM/DD/YYYY'): void {}
    returnNestedData(eventName: string, data: any): any {}
    adjustEventName(eventName: string, affix: string, suffix: boolean = true): any {}
}
