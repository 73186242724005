import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, timer } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { HttpCache } from '../shared/interceptors';
import { ToasterService } from '../shared/services';
import { AppResponse, FilterableResponse } from './app.model';

@Injectable()
export class AppsService {
    currentApps: string[] = [];
    allApps = new ReplaySubject<AppResponse>();
    constructor(
        private http: HttpClient,
        private cache: HttpCache,
        private toaster: ToasterService,
    ) {
        // Poll the user apps in case any have been added during session
        timer(0, 120000).pipe(
            take(3), // only poll a total of 3 times (initially, then every 3 minutes)
            switchMap(() => this.refreshApps()),
        ).subscribe(
            (res) => {
                const refreshedApps = res.data.map(item => item.id);
                const difference = refreshedApps.filter(element => !this.currentApps.includes(element));
                // Prep toaster messages
                let messages: string[] = [];
                res.data.forEach((app) => {
                    if (difference.includes(app.id)) {
                        messages.push('App Added: ' + app.title);
                    }
                });
                if (this.currentApps.length && messages.length) {
                    this.toaster.setNotification({
                        type: 'success',
                        messages: messages,
                        smallBannerStyle: true,
                        link: { url: ['/apps'], text: 'Go to Apps' },
                    });
                }

                this.currentApps = refreshedApps;
                this.allApps.next(res);
            },
        );
    }

    refreshApps(): Observable<AppResponse> {
        this.cache.delete(`${environment.API_URL}/apps/user`);
        return this.getAllApps();
    }

    getAllApps(): Observable<AppResponse> {
        return this.http.get<AppResponse>(`${environment.API_URL}/apps/user`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getTopApps(): Observable<AppResponse> {
        return this.http.get<AppResponse>(`${environment.API_URL}/apps/top`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    postFavorite(id: string): Observable<AppResponse> {
        return this.http.post<AppResponse>(`${environment.API_URL}/favorite/app`, { id })
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    deleteAppFavorite(id: string): Observable<AppResponse> {
        this.invalidateFavoriteCache();
        return this.http.delete<AppResponse>(`${environment.API_URL}/favorite/app?id=${id}`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    invalidateFavoriteCache(): void {
        this.cache.delete(`${environment.API_URL}/favorite`);
        this.cache.delete(`${environment.API_URL}/apps/top`);
    }

    storeAppEvent(id: string, action: string): Observable<AppResponse> {
        return this.http.post<AppResponse>(`${environment.API_URL}/apps/track`, { 'id': id, 'action': action })
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    searchApps(searchTerm: string): Observable<AppResponse> {
        return this.http.get<AppResponse>(`${environment.API_URL}/apps/search/${searchTerm}`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getFilterableApps(): Observable<FilterableResponse> {
        return this.http.get<FilterableResponse>(`${environment.API_URL}/apps/filterable`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }
}
