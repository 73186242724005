import { Component } from "@angular/core";
import { environment } from "environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "mxc-header-alt-v2",
  templateUrl: "./header-alt-v2.component.html",
  styleUrls: ["./header-alt-v2.component.scss"],
})
export class HeaderAltV2Component {
  logo: string = environment.IMAGES.logo.primary;
  showWelcomeText = false;

  constructor(router: Router) {
    if (router.url.indexOf("/login") > -1) {
      this.showWelcomeText = true;
    }
  }
}
