import { Inject, Injectable } from '@angular/core';
import { App, NavigationAnalyticInfo } from '../../apps/app.model';
import {
  AppOpenedEvent,
  OfficeFavoredEvent,
  PostFavoredEvent,
  UserFavoredEvent,
  AppFavoredEvent,
  SearchRunEvent,
} from '../models/segment.model';
import { HttpCache } from '../interceptors';
import { Post } from '../../content/content.model';
import { User, UserProfile } from '../models';
import { Office } from '../../roster/roster.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  officeViewTypeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('list');
  officeViewType: string;
  userViewTypeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('list');
  userViewType: string;
  postViewTypeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('list');
  postViewType: string;
  appViewTypeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('list');
  appViewType: string;
  lastSearch: SearchRunEvent;

  constructor(
      private cache: HttpCache,
      @Inject(Window) protected window: any,
  ) {
    this.userViewTypeSubject.subscribe(val => {
      this.userViewType = val;
    });
    this.officeViewTypeSubject.subscribe(val => {
      this.officeViewType = val;
    });
    this.postViewTypeSubject.subscribe(val => {
      this.postViewType = val;
    });
    this.appViewTypeSubject.subscribe(val => {
      this.appViewType = val;
    });
  }

  track(event: string, properties?: {}): void {
    this.window.analytics.track(event, properties);
  }

  // App Opened
  segmentTrackAppOpened(app: App, additionalAnalytics?: NavigationAnalyticInfo): void {
    let eventProperties: AppOpenedEvent = {
      app_id: app.id,
      name: app.title,
      target_device: app.target_device,
      app_category_id: app.appCategoryId,
      new_window: app.newWindow,
      description: app.description,
      is_featured: app.highlighted,
      is_favorited: !!(this.cache && this.cache[app.id]),
      signon_type: app.signOnMode,
      property_clicked_from: window.location.pathname,
    };
    if (additionalAnalytics) {
      eventProperties = {...additionalAnalytics, ...eventProperties};
    }
    this.track('App Opened', eventProperties);
  }

  segmentTrackFilterApplied(filterLocation: string, filterType: string, filterText: string)
  {
    this.track('Filter Applied', {
      location: filterLocation,
      filter_type: filterType,
      setting_applied: filterText
    });
  }

  // Special for side nav links since they are not apps but we need to track clicks as such
  segmentTrackSideNavClick(data: NavigationAnalyticInfo): void {
    this.track('App Opened', data);
  }

  segmentUserFavoritedEvent(user: UserProfile | User): void {
    const eventProperties: UserFavoredEvent = {
      user_id: user.id,
      remaxnet_email: user.remaxEmail,
      first_name: user.firstName,
      last_name: user.lastName,
      view_type: this.userViewType,
    };
    this.track('User Favorited', eventProperties);
  }

  segmentUserUnfavoritedEvent(user: UserProfile | User): void {
    const eventProperties: UserFavoredEvent = {
      user_id: user.id,
      remaxnet_email: user.remaxEmail,
      first_name: user.firstName,
      last_name: user.lastName,
      view_type: this.userViewType,
    };
    this.track('User Un-Favorited', eventProperties);
  }

  segmentOfficeFavoritedEvent(office: Office): void {
      const eventProperties: OfficeFavoredEvent = {
      office_id: office.id,
      name: office.name,
      region_id: office.regionId,
      subclass_code: office.subspecialty,
      view_type: this.officeViewType,
    };
    this.track('Office Favorited', eventProperties);
  }

  segmentOfficeUnfavoritedEvent(office: Office): void {
    const eventProperties: OfficeFavoredEvent = {
    office_id: office.id,
    name: office.name,
    region_id: office.regionId,
    subclass_code: office.subspecialty,
    view_type: this.officeViewType,
    };
    this.track('Office Un-Favorited', eventProperties);
  }

  segmentPostFavoritedEvent(post: Post): void {
    const eventProperties: PostFavoredEvent = {
      post_id: post.id,
      name: post.title,
      type: post.post_type,
      is_featured: post.featured,
      view_Type: this.postViewType,
    };
    this.track('Post Favorited', eventProperties);
  }

  segmentPostUnfavoritedEvent(post: Post): void {
    const eventProperties: PostFavoredEvent = {
      post_id: post.id,
      name: post.title,
      type: post.post_type,
      is_featured: post.featured,
      view_Type: this.postViewType,
    };
    this.track('Post Un-Favorited', eventProperties);
  }

  segmentAppFavoritedEvent(app: App): void {
    const eventProperties: AppFavoredEvent = {
      app_id: app.id,
      name: app.name,
      view_Type: this.appViewType,
    };
    this.track('App Favorited', eventProperties);
  }

  segmentAppUnfavoritedEvent(app: App): void {
    const eventProperties: AppFavoredEvent = {
      app_id: app.id,
      name: app.name,
      view_Type: this.appViewType,
    };
    this.track('App Un-Favorited', eventProperties);
  }

  segmentSearchRunEvent(details: SearchRunEvent): void {
    const eventProperties: SearchRunEvent = this.lastSearch = details;
    this.track('Search Run', eventProperties);
  }

  segmentListViewChangeEvent(viewType: string, searchObject?: string, searchType?: string): void {
    if (!this.lastSearch) {
      this.track('Search Results View Changed', {
        result_count: 0,
        search_object: searchObject ? searchObject : null,
        search_type: searchType ? searchType : null,
        view_type: viewType,
      });
      return;
    }
    this.lastSearch.view_type = viewType;
    this.track('Search Results View Changed', this.lastSearch);
  }
}
