<div class="pl-2">
  <div
    *ngFor="let option of filter.options"
    class="d-block custom-control custom-control-inline custom-checkbox"
  >
    <input
      class="custom-control-input"
      [id]="
        'multiselect-option--' +
        ($any(option).value || option).replace(findStringsRegex, '-')
      "
      type="checkbox"
      [value]="$any(option).value || option"
      [checked]="selected.indexOf($any(option).value || option) > -1"
      (change)="changeCheckbox($any(option).value || option)"
    />
    <label
      [for]="
        'multiselect-option--' +
        ($any(option).value || option).replace(findStringsRegex, '-')
      "
      class="custom-control-label"
    >
      {{ $any(option).key || option }}
    </label>
  </div>
</div>
