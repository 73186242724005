import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpCache } from './HttpCache';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: HttpCache) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // don't cache referral requests
    const regex = new RegExp(/^.+\/(referral|download)\/?.*?/);

    if (req.method !== 'GET' || regex.test(req.url)) {
      return next.handle(req);
    }

    const cachedResponse = this.cache.get(req);

    if (cachedResponse) {
      return of(cachedResponse);
    }

    return next.handle(req).pipe(
      tap((event: HttpResponse<any>) => {
        this.cache.put(req, event);
      }),
    );
  }
}
