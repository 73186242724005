import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'environments/environment';
import {
    AllPostsResponse,
    SinglePostResponse,
    PostTypes,
    PostsResponse,
    DepartmentLabelResponse,
    AllRegionsResponse,
    DownloadUrlResponse,
} from './content.model';

import { HttpCache, ToasterService } from '../shared';

@Injectable()
export class ContentService {
    department_type = '';
    region_id = null;
    category = '';
    query = '';
    post_type: PostTypes;
    tag = '';
    viewType: BehaviorSubject<string|null> = new BehaviorSubject<string|null>('list');

    constructor(
        private http: HttpClient,
        private cache: HttpCache,
        private toaster: ToasterService,
    ) { }

    getAllPosts(): Observable<AllPostsResponse> {
        return this.getPosts();
    }

    getPaginatedPosts(currentPage: number): Observable<AllPostsResponse> {
        return this.http.get(
            `${environment.API_URL}/post/search/attributes?${
            this.addQuery('department_type')
            }${
            this.addQuery('region_id')
            }${
            this.addQuery('query')
            }${
            this.addQuery('category')
            }${
            this.addQuery('tag')
            }${
            this.addQuery('post_type')
            }currentPage=${currentPage}`,
        )
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getPosts(params: object = {}): Observable<AllPostsResponse> {
        //  Build URL with params
        let path = `${environment.API_URL}/post/search/attributes`;

        if (Object.keys(params).length) {
            path = `${path}?`;

            for (let p in params) {
                if ( params[p] === null || typeof params[p] === 'undefined' ) {
                    continue;
                }
                path = `${path}${(/\?$/.test(path) ? '' : '&')}${p}=${encodeURIComponent(params[p])}`;
            }

            path = path.replace(/\&$/, '');
        }

        return this.http
                   .get(path)
                   .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getSinglePost(postId: number): Observable<SinglePostResponse> {
        return this.http.get(`${environment.API_URL}/post/${postId}`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getMultiplePosts(postIds): Observable<AllPostsResponse> {
        return this.http.get(`${environment.API_URL}/post/by-id?ids=${postIds}`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getBoojFeaturedPosts(): Observable<AllPostsResponse> {
        return this.http.get(`${environment.API_URL}/post/boojfeatured`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getFeaturedPosts(postType = null): Observable<AllPostsResponse> {
        let path = `${environment.API_URL}/post/featured`;
        if (postType) {
            path = `${path}?postType=${postType}`;
        }
        return this.http.get(path)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getPopularResources(): Observable<AllPostsResponse> {
        return this.http.get(`${environment.API_URL}/post/popular-media?count=4`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getAllDepartments(): Observable<DepartmentLabelResponse> {
        return this.http.get(`${environment.API_URL}/department-label/withDepartments`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getPopularDepartmentsRegions(): Observable<DepartmentLabelResponse> {
        return this.http.get(`${environment.API_URL}/department-label/popular-departments-regions`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getPopularResourceDepartmentsRegions(): Observable<DepartmentLabelResponse> {
        return this.http.get(`${environment.API_URL}/department-label/popular-resource-departments-regions`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    setSelectedDepartment(department: string): void {
        this.department_type = department;
    }

    getAllRegionsByType(): Observable<AllRegionsResponse> {
        return this.http.get(`${environment.API_URL}/office/regions/all`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getBoojSearchResults(page): Observable<AllPostsResponse> {
        return this.http.get(`${environment.API_URL}/post/search/attributes?query=booj&currentPage=${page}`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    setSelectedRegion(region: number): void {
        this.region_id = region;
    }

    setCategory(category: string): void {
        this.category = category;
    }

    setPostType(type: PostTypes): void {
        this.post_type = type;
    }

    setTag(tag: string): void {
        this.tag = tag;
    }

    postFavorite(id: number): Observable<AllPostsResponse> {
        return this.http.post(`${environment.API_URL}/favorite/post`, { id })
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    deleteFavorite(id: number): Observable<AllPostsResponse> {
        return this.http.delete(`${environment.API_URL}/favorite/post?id=${id}`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    invalidateFavoriteCache(): void {
        this.cache.delete(`${environment.API_URL}/favorite`);
    }

    searchPosts(term: string): Observable<AllPostsResponse> {
        return this.http.get(`${environment.API_URL}/post/search/attributes?query=${term}`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    addQuery(queryType: 'category' | 'department_type' | 'region_id' | 'post_type' | 'query' | 'tag'): string {
        return this[queryType] ? `${queryType}=${this[queryType]}&` : '';
    }

    updateSearchTerm(searchTerm: string): void {
        this.query = searchTerm;
    }

    getPreviewPost(postId: string): Observable<SinglePostResponse> {
        return this.http.get(`${environment.API_URL}/post/preview/${postId}`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getDownload(id): Observable<DownloadUrlResponse> {
        return this.http.get(`${environment.API_URL}/asset/download/${id}?download=true`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getContentPosts(filter: string): Observable<PostsResponse> {
        return this.http.get(`${environment.API_URL}/post/in-the-know?filter=${filter}`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }
}
