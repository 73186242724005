import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mxcVideoEmbed',
})
export class VideoEmbedPipe implements PipeTransform {
    transform(markup: string): string {
        const regex = /<img class="ta-insert-video".*? ta-insert-video="(.*?)" .*?>/g;
        return markup.replace(regex, this.replaceVideo);
    }

    replaceVideo(markup = '', videoUrl: string): string {
        return `<div class="embed-responsive embed-responsive-16by9">
      <iframe class="embed-responsive-item" src="${videoUrl}" allow="autoplay" allowfullscreen></iframe>
    </div>`;
    }
}
