import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { ToasterService } from '../shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BuildYourBusinessChecklist,
  BuildYourBusinessChecklistResponse,
  BuildYourBusinessChecklistToggleResponse,
} from './build-your-business.model';

@Injectable({
  providedIn: 'root',
})
export class BuildYourBusinessService {
  showDashboardChecklistBlock: BehaviorSubject<boolean> = new BehaviorSubject<boolean> (true);
  checkedGettingStartedValuated: boolean = false;
  public myChecklist: ReplaySubject<BuildYourBusinessChecklist[]> = new ReplaySubject();
  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
  ) {
    this.getMyChecklist().subscribe(res => {
      this.myChecklist.next(res.data);
    });
  }

  private setHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  getMyChecklist(): Observable<BuildYourBusinessChecklistResponse> {
    return this.http.get<BuildYourBusinessChecklistResponse>(
      `${environment.API_URL}/build-your-business/checklist`,
      { headers: this.setHeader() },
    )
      .pipe(
        catchError(err => {
          err.error.data = 'There was an error retrieving your checklist.';
          this.toasterService.handleErrors(err);
          return throwError(err);
        }),
      );
  }

  completeChecklistItem(checklistId: number, completed: 0|1): Observable<BuildYourBusinessChecklistToggleResponse> {
    return this.http.post<BuildYourBusinessChecklistToggleResponse>(
      `${environment.API_URL}/build-your-business/checklist/${checklistId}/${completed}`,
      [],
      { headers: this.setHeader() },
    )
      .pipe(
        catchError(err => {
          err.error.data = 'There was an error updating your checklist.';
          this.toasterService.handleErrors(err);
          return throwError(err);
        }),
      );
  }
}
