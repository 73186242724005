import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'officeName',
})

export class OfficeNamePipe implements PipeTransform {
  transform (input: string): string {
    input = input.replace(/\w\S*/g, function(txt: string): string {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

    const replaceMap = {
      'Re\/max' : 'RE/MAX',
      'Llc' : 'LLC',
    };

    input = input.replace(/Re\/max|Llc/gi, function(matched: string): string {
      return replaceMap[matched];
    });

    return input;
  }
}
