import { trigger, transition, animate, style } from '@angular/animations';

export const fadeInAnimation = trigger('fadeInAnimation', [
  transition(':enter', [
    // start
    style({opacity: '0'}),
    // end
    animate('0.4s ease-out', style({opacity: '1'})),
  ]),
]);
