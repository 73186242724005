<div *ngIf="(mobileSideNavOpen && isMobile) || !isMobile" [@flyOutAnimationLeft] [@.disabled]="!isMobile" id="side-navigation-wrapper" data-hook="side-navigation" [ngClass]="{'mw-md-240px': maxWidthMd240 }">
    <div id="side-navigation-container" [ngClass]="{'display-side-menu': mobileSideNavOpen}">
        <div class="close-icon-container d-md-none menu-close menu-close-mobile" (click)="closeSideMenu()">
            <i class="material-icons">close</i>
        </div>
        <ul id="app-nav" data-hook="navigation" class="mb-0">
            <li *ngIf="onProfileRoutes" class="side-nav-item position-relative mb-2">
                <div
                    (click)="navigateToRoute('/home'); setSelectedNav(0);"
                >
                    <div class="d-flex">
                        <a
                            data-hook="app-nav-link"
                            class="side-nav-item nav-highlight-wrapper d-flex align-items-center pr-4"
                            title="Back to Main Menu"
                        >
                            <i class="material-icons material-nav-icons">chevron_left</i>
                            <div class="text-center">
                                Back to Main Menu
                            </div>
                        </a>
                    </div>
                </div>
                <hr class="mx-3">
            </li>
            <li class="side-nav-item position-relative mb-2" *ngFor="let routes of dynamicNavigation; let i = index">
                <div
                    *ngIf="routes && routes.length === 1; else isNestedNav"
                    (click)="routes[0].isExternal ? null : navigateToRoute(routes[0].path); triggerGTMEvent(routes[0].name); setSelectedNav(i);"
                    [attr.data-nav]="routes[0].navKey"
                >
                    <div class="position-relative d-flex" [ngClass]="{ active: selectedNav === routes }">
                        <span
                            class="position-absolute red-line"
                            *ngIf="menuV2Service.currentRouteIndex === i"
                        ></span>
                        <a
                            *ngIf="!routes[0].isExternal"
                            [routerLink]="routes[0].path"
                            data-hook="app-nav-link"
                            class="side-nav-item nav-highlight-wrapper d-flex align-items-center pr-4"
                            title="{{ routes[0].name }}"
                            [ngClass]="{ 'side-nav-item-active': selectedNav === routes }"
                        >
                            <i class="material-icons material-nav-icons">{{ routes[0].icon }}</i>
                            <div class="text-center" [ngClass]="{'side-nav-item-active': selectedNav === routes}">
                                {{ routes[0].nestedNavName }}
                            </div>
                        </a>
                        <a
                            *ngIf="routes[0].isExternal"
                            [href]="routes[0].path"
                            target="_blank"
                            data-hook="app-nav-link"
                            class="side-nav-item nav-highlight-wrapper d-flex align-items-center pr-4"
                            title="{{ routes[0].name }}"
                            (click)="routes[0].name === supportApp?.navDisplayName
                                ? segmentService.segmentTrackAppOpened(supportApp.app, this.getAdditionalAnalytics(supportApp.navDisplayName))
                                : null;
                                triggerGTMEvent(routes[0].name);
                                trackClick(routes[0].name);"
                        >
                            <i class="material-icons material-nav-icons">{{ routes[0].icon }}</i>
                            <div class="text-center">
                                {{ routes[0].nestedNavName }}
                            </div>
                        </a>
                    </div>
                </div>
                <ng-template #isNestedNav>
                    <div>
                        <div
                            class="position-relative d-flex"
                            [ngClass]="{ active: selectedNav === routes }"
                            (click)="toggleSubNav(i); triggerGTMEvent(routes[0].nestedNavName)"
                            [attr.data-nav]="routes[0].nestedNavKey"
                        >
                            <span
                                class="position-absolute red-line"
                                *ngIf="menuV2Service.currentRouteIndex === i"
                            ></span>
                            <a
                                data-hook="app-nav-link"
                                class="side-nav-item nav-highlight-wrapper align-items-center d-inline-flex"
                                title="{{ routes[0].nestedNavName }}"
                                [ngClass]="{ 'side-nav-item-active': selectedNav === routes }"
                            >
                                <i
                                    [ngClass]="{'material-icons-outlined' : ['account_circle', 'people'].includes(routes[0].icon)}"
                                    class="material-icons material-nav-icons"
                                >
                                    {{ routes[0].icon }}
                                </i>
                                <div class="text-center align-middle">
                                    {{ routes[0].nestedNavName }}
                                </div>
                                <i
                                    class="material-icons arrows"
                                    [ngClass]="{'rotate-180': menuV2Service.sectionSubMenuToggle[i]}"
                                >keyboard_arrow_down</i>
                            </a>
                        </div>

                        <div [@openClose]="menuV2Service.sectionSubMenuToggle[i] ? 'open' : 'closed'" class="sub-nav-container" #subNavs [attr.data-calcheight]="0" [attr.data-index]="i">
                            <div class="mt-3">
                                <div *ngFor="let subNav of routes">
                                    <a
                                        *ngIf="!subNav.isExternal && !subNav.parentItemOnly"
                                        [routerLink]="subNav.path"
                                        [queryParams]="subNav.params"
                                        data-hook="app-sub-nav"
                                        [attr.data-nav]="subNav.navKey"
                                        class="'d-flex align-items-center sub-nav-item"
                                        [title]="subNav.name"
                                        (click)="setSelectedNav(i); triggerGTMEvent(subNav.name); toggleSubNav(i, true);closeSideMenu();determineAnalytics(subNav);handleSubNavClick(subNav);"
                                    >
                                        <div class="d-inline-block align-middle sub-nav-name nav-highlight-wrapper px-3 py-2" [ngClass]="{'side-nav-item-active': subNav.activeRouteMatch || subNav.activeStyles }">
                                            {{ subNav.name }}
                                        </div>
                                    </a>
                                    <a
                                        *ngIf="subNav.isExternal && !subNav.parentItemOnly"
                                        [href]="subNav.path"
                                        target="_blank"
                                        data-hook="app-sub-nav"
                                        [attr.data-nav]="subNav.navKey"
                                        class="'d-flex align-items-center sub-nav-item"
                                        [title]="subNav.name"
                                        (click)="triggerGTMEvent(subNav.name);"
                                    >
                                        <div class="d-inline-block align-middle sub-nav-name nav-highlight-wrapper px-3 py-2" [ngClass]="{'side-nav-item-active': subNav.activeRouteMatch || subNav.activeStyles }">
                                            {{ subNav.name }}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>

        <div id="secondary-nav" class="pb-3" *ngIf="!onProfileRoutes">
            <ul class="secondary-nav-items mobile-view-desktop-links" *ngIf="isMobile && mobileSideNavOpen">
                <li class="secondary-nav-item" *ngIf="contactsApp && mobileSideNavOpen">
                    <div class="nav-highlight-wrapper d-inline-flex">
                        <a
                            [href]="contactsApp.link || contactsApp.app.linkUrl" target="_blank"
                            class="px-3 py-2 top-link"
                            data-hook="app-nav-link"
                            [attr.data-nav]="contactsApp.navKey"
                            [title]="contactsApp.navDisplayName"
                            (click)="
                                gtmService.clickEvent({event_name: 'nav', type:'left_nav'}, $event);
                                segmentService.segmentTrackAppOpened(contactsApp.app, this.getAdditionalAnalytics(contactsApp.navDisplayName));"
                        >
                            {{contactsApp.navDisplayName}}
                        </a>
                    </div>
                </li>

                <li class="secondary-nav-item" *ngIf="websitesApp">
                    <div class="nav-highlight-wrapper d-inline-flex">
                        <a
                            [href]="websitesApp.link || websitesApp.app.linkUrl" target="_blank"
                            class="px-3 py-2 top-link"
                            data-hook="app-nav-link"
                            [title]="websitesApp.navDisplayName"
                            (click)="
                                gtmService.clickEvent({event_name: 'nav', type:'left_nav'}, $event);
                                segmentService.segmentTrackAppOpened(websitesApp.app, this.getAdditionalAnalytics(websitesApp.navDisplayName));"
                        >
                            {{websitesApp.navDisplayName}}
                        </a>
                    </div>
                </li>

                <li class="secondary-nav-item" *ngIf="emailApp">
                    <div class="nav-highlight-wrapper d-inline-flex">
                        <a
                            [href]="emailApp.link || emailApp.app.linkUrl" target="_blank"
                            class="px-3 py-2 top-link"
                            data-hook="app-nav-link"
                            [attr.data-nav]="emailApp.navKey"
                            [title]="emailApp.navDisplayName"
                            (click)="segmentService.segmentTrackAppOpened(emailApp.app, this.getAdditionalAnalytics(emailApp.navDisplayName));"
                        >
                            {{emailApp.navDisplayName}}
                        </a>
                    </div>
                </li>

                <li class="secondary-nav-item" *ngIf="educationApp && mobileSideNavOpen">
                    <div class="nav-highlight-wrapper d-inline-flex">
                        <a
                            [href]="educationApp.link || educationApp.app.linkUrl" target="_blank"
                            class="px-3 py-2 top-link"
                            data-hook="app-nav-link"
                            [attr.data-nav]="educationApp.navKey"
                            [title]="educationApp.navDisplayName"
                            (click)="
                                gtmService.clickEvent({event_name: 'nav', type:'left_nav'}, $event);
                                segmentService.segmentTrackAppOpened(educationApp.app, this.getAdditionalAnalytics(educationApp.navDisplayName));"
                        >
                            {{educationApp.navDisplayName}}
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
