import {
    Component,
    ViewEncapsulation,
    Renderer2,
    OnInit,
    HostListener,
} from '@angular/core';
import {
    flyOutAnimationLeft,
    flyOutAnimationRight,
    rollDownAnimation,
    slideOutAnimation,
} from '../../shared/animation';
import { environment } from 'environments/environment';
import {UserService, UserProfile, GoogleTagManagerService} from '../../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LogoutModalComponent } from '../shared/logout-modal/logout-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ChangeUserComponent } from '../shared/change-user/change-user.component';

@Component({
    selector: 'mxc-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        flyOutAnimationLeft,
        flyOutAnimationRight,
        rollDownAnimation,
        slideOutAnimation,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
    isMenuActive = false;
    logoImage: string = environment.IMAGES.logo.primary;
    logoNavbar: string = environment.IMAGES.logo.navbar;
    billPayUrl: string = environment.BILLING_PAYMENT_URL;
    windowWidth: number;
    mobile: boolean;
    hasLandingPage: boolean;
    currentUser: UserProfile;
    profileOpen = false;
    openModal: NgbModalRef;
    closeIcon = faTimes;
    searchForm: FormGroup;

    // handling for closing the subnav
    navigated = false;
    insideClick = false;

    constructor(
        private renderer: Renderer2,
        private router: Router,
        private fb: FormBuilder,
        private userService: UserService,
        public gtmService: GoogleTagManagerService,
        private modalService: NgbModal
    ) {
        this.searchForm = this.fb.group({
            term: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.setOverflow();
        this.windowWidth = window.innerWidth;
        if (this.windowWidth >= 992) {
            this.mobile = false;
        } else {
            this.mobile = true;
        }
        this.userService.getMe().subscribe(res => {
            this.currentUser = res;
            this.hasLandingPage =
                this.currentUser.features.filter(
                    feature => feature.slug === 'home'
                ).length > 0;
        });
    }

    submitSearch(): void {
        const searchTerm = this.searchForm.get('term').value;
        if (searchTerm) {
            const encodedSearchTerm = encodeURIComponent(searchTerm);
            this.router.navigate(['/search'], {
                queryParams: { search: encodedSearchTerm },
            });
        }
    }

    setOverflow(): void {
        if (this.isMenuActive || this.profileOpen) {
            this.renderer.setStyle(document.body, 'overflow', 'hidden');
        } else {
            this.renderer.setStyle(document.body, 'overflow', 'visible');
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth > 992) {
            this.mobile = false;
        } else {
            this.mobile = true;
        }
    }

    @HostListener('click')
    clickInside(): void {
        this.insideClick = true;
    }

    @HostListener('document:click')
    clickout(): void {
        if (!this.insideClick) {
            // clicked outside
            this.profileOpen = false;
        }
        this.insideClick = false;
    }

    toggleMenu(): void {
        this.isMenuActive = !this.isMenuActive;
        this.setOverflow();
    }

    openMenu(): void {
        this.isMenuActive = true;
        this.setOverflow();
    }

    closeMenu(): void {
        this.isMenuActive = false;
        this.setOverflow();
    }

    openProfile(): void {
        this.userService.continueOrLogout();
        this.profileOpen = true;
        if (this.mobile) {
            this.setOverflow();
        }
    }

    closeProfile(): void {
        this.profileOpen = false;
        if (this.mobile) {
            this.setOverflow();
        }
    }

    getEditUrl(): string {
        this.userService.continueOrLogout();
        return `${environment.PROFILE_URL}?mcid=${this.currentUser.id}`;
    }

    openLogoutModal(): void {
        this.openModal = this.modalService.open(LogoutModalComponent);
    }

    userInitials(user: UserProfile): string {
        return (
            user.firstName.charAt(0).toUpperCase() +
            user.lastName.charAt(0).toUpperCase()
        );
    }

    goToView(url: string): void {
        this.router.navigate(['/' + url]);
    }

    goToLink(url: string): void {
        window.open(url, '_blank');
    }

    openChangeUserModal(e): void {
        if (e) {
            e.preventDefault();
        }

        this.openModal = this.modalService.open(ChangeUserComponent);
    }

    revertUser(): void {
        this.userService.revertUser().subscribe(() => window.location.reload());
    }

    isImpersonator(): boolean {
        if(
            this.currentUser.groups.includes('Super Admin') ||
            this.currentUser.groups.includes('Impersonators')
        ) {
            return true;
        }
        return false;
    }
}
