import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { ToasterService } from '../shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AcceptEulaResponse, DeclineEulaResponse, EulaResponse } from './legal.model';

@Injectable()
export class LegalService {
  deepLink: string;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private toaster: ToasterService,
  ) { }

  getEula(): Observable<EulaResponse> {
    const http = new HttpClient(this.handler);
    return http.get(`${environment.API_URL}/eula`)
      .pipe(catchError(err => this.toaster.handleErrors(err)));
  }

  acceptEula(id: number): Observable<AcceptEulaResponse> {
    return this.http.post(`${environment.API_URL}/eula/accept`, { eula_id: id })
      .pipe(catchError(err => this.toaster.handleErrors(err)));
  }

  declineEula(id: number): Observable<DeclineEulaResponse> {
    return this.http.post(`${environment.API_URL}/eula/decline`, { eula_id: id })
        .pipe(catchError(err => this.toaster.handleErrors(err)));
  }
}
