import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LogEntry } from '../models/log.model';

@Injectable({
    providedIn: 'root',
})
export class LogService {
    constructor(private http: HttpClient, private handler: HttpBackend) {
        this.http = new HttpClient(this.handler);
    }

    log(logEntry: LogEntry): void {
        const message = `${logEntry.message}\n${logEntry.trace ? logEntry.trace : ''}`;
        this.http.post(`${environment.API_URL}/log/${logEntry.type}`, { app: 'Portal', error: message }).subscribe();
    }
}
