import { Component, OnInit } from '@angular/core';
import { UserProfile, UserService } from '../../shared';

// This is the general route view, only use it if you're boring and need a header, footer, and normal view
@Component({
    templateUrl: './route-view.component.html',
})
export class RouteViewComponent implements OnInit {
    currentUser: UserProfile;
    navVersion?: string;

    constructor(
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.userService.getMe().subscribe(res => {
            this.currentUser = res;
            this.navVersion = this.currentUser.features.filter(feature => (feature.slug === 'nav2')).length > 0 ? 'nav2' : null;
        });
    }
}
