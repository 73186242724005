import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SearchFilter } from '../../models';

@Component({
    selector: 'mxc-multi-select',
    templateUrl: './multi-select.component.html',
})
export class MultiSelectComponent implements OnInit, OnChanges {
    @Input() filter: SearchFilter;
    @Output() selectionUpdated: EventEmitter<string[]> = new EventEmitter<string[]>();
    selected: string[];
    findStringsRegex: RegExp = /\s+/g;

    ngOnInit(): void {
        this.selected = this.convertStringToArray(this.filter.selected);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.selected = this.convertStringToArray(changes.filter.currentValue.selected);
    }

    convertStringToArray(selection: string): string[] {
        return selection ? selection.split(',') : [];
    }

    changeCheckbox(selectedItem: string): void {
        const selectionIndex = this.selected.indexOf(selectedItem);

        const updatedSelection = selectionIndex === -1 ?
            [
                ...this.selected,
                selectedItem,
            ]
            :
            [
                ...this.selected.slice(0, selectionIndex),
                ...this.selected.slice(selectionIndex + 1),
            ];

        this.selected = updatedSelection;
        this.selectionUpdated.emit(updatedSelection);
    }
}
