import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class TabService {
  private _currentContentTab: string;
  private _lastUrl: string;

  get currentContentTab(): string {
    return this._currentContentTab;
  }

  set currentContentTab(value: string) {
    this._currentContentTab = value;
  }

  get lastUrl(): string {
    return this._lastUrl;
  }

  set lastUrl(tabUrl: string) {
    this._lastUrl = tabUrl;
  }
}
