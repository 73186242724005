import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'remaxRegion',
})
export class RemaxRegionPipe implements PipeTransform {
    transform(value: string): string {
        return value.replace(/(RE\/MAX (OF )?)|( INC(\.)?)$/gi, '');
    }
}
