import { Component, Inject, OnInit, HostListener, Renderer2 } from '@angular/core';
import { environment } from 'environments/environment';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import {
    UserService,
    ToasterService,
    DeviceDetectorService,
    CurrentUserResponse,
    GoogleTagManagerService
} from './shared';
import { TranslateService } from '@ngx-translate/core';
declare let ga: Function;
declare let window: { analytics: any };

@Component({
    selector: 'mxc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    s3Path: string = environment.STATIC_S3_URL;
    linkElements = Array.from(this.document.getElementsByTagName('link'));
    dumbIcon = this.document.getElementById('msapplication-config');
    isE2e = environment.APP_ENV === 'e2e';
    disableAnimations: boolean;
    themeVersion2Routes = [
        /\/forgot-password(\/)?/i,
        /\/login(\/)?/i
    ];
    themeVersion = 1;

    constructor(@Inject(DOCUMENT)
    private document: Document,
    private router: Router,
    private userService: UserService,
    private toasterService: ToasterService,
    private deviceDetectorService: DeviceDetectorService,
    private gtmService: GoogleTagManagerService,
    private renderer2: Renderer2,
    public translate: TranslateService
    ) {
        translate.setDefaultLang('en');
        translate.use('en');

        this.router.events.subscribe((ev) => {

            if (ev instanceof NavigationEnd) {

                gtmService.window.dataLayer = gtmService.window.dataLayer || [];
                gtmService.window.dataLayer.push({
                    event: 'content_refresh'
                });
                if (ev.url !== '/home') {
                    window.analytics.page();
                }

                this.gtmService.loginEvent({
                    event_name: 'login',
                    method: 'email'
                });

                this.themeVersion2Routes.forEach((r) => {
                    if ( r.test(ev.url) ){
                        this.themeVersion = 2;
                    }
                });
            }
        });
    }

    @HostListener('window:focus', ['$event'])
    onfocus(event: any): void {
        const user = this.userService.user;
        if (user) {
            if (user.id && (user.billDue || user.sos)) {



                this.userService.getAccessStatus(user.id).subscribe((res: CurrentUserResponse) => {
                    this.userService.user = res.data;
                    if (user.billDue && user.billDue !== this.userService.user.billDue) {
                        this.toasterService.removeNotification();
                    }
                });
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    onresize(event: any): void {
        this.deviceDetectorService.setViewport();
    }



    ngOnInit(): void {
        // update all the favicons with the S3 path
        this.linkElements.forEach(link => {
            if (link.getAttribute('rel').includes('icon') || link.getAttribute('rel').includes('manifest')) {
                const path = this.s3Path + link.getAttribute('href');
                link.setAttribute('href', path);
            }
        });
        // the one stupid IE11 icon that uses xml instead of the link rel
        this.dumbIcon.setAttribute('href', this.s3Path + '/favicon/browserconfig.xml');
        this.injectGA();

        if(this.isE2e) {
            this.disableAnimations = true;
        }
        this.loadScript('https://maps.googleapis.com/maps/api/js?libraries=places&language=en', true, 'key', environment.GOOGLE_MAPS_API_KEY);
    }

    injectGA(): void {
        (function(i, s, o, g, r, a, m): void {
            i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function(): void {
                (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * (new Date() as any); a = s.createElement(o),
                m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m);
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

        ga('create', `${environment.GTM_AUTH}`, 'auto');
    }

    loadScript(url: string, async = false, apiKeyName?: string, apiKey?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const script = this.renderer2.createElement('script');
            const apiKeyString = apiKeyName && apiKey ? `${url.includes('?') ? '&' : '?'}${apiKeyName}=${apiKey}` : '';
            script.type = 'text/javascript';
            script.src = `${url}${apiKeyString}`;
            script.async = async;
            script.defer = true;
            script.text = ``;
            script.onload = resolve;
            script.onerror = reject;
            this.renderer2.appendChild(this.document.body, script);
        }).catch(() => {});
    }
}
