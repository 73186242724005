import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StyleGuideComponent } from './style-guide.component';

// font awesome imports
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTwitter, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faHeart as faHeartOpen } from '@fortawesome/free-regular-svg-icons';
import {
    faExclamationTriangle, faTimes, faLock, faArrowLeft, faAngleRight,
    faNewspaper, faSuitcase, faVideo, faCalendar, faQuestionCircle,
    faSearch, faPlusCircle, faTimesCircle, faCaretDown, faCaretRight, faChartBar,
    faInbox, faAddressCard, faCog, faBars, faHeart, faSort, faSlidersH,
} from '@fortawesome/free-solid-svg-icons';

const styleGuideRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
    {
        path: 'style-guide',
        component: StyleGuideComponent,
    },
]);

@NgModule({
    imports: [
        styleGuideRouting,
        CommonModule,
        NgbModule,
        FontAwesomeModule,
    ],
    declarations: [
        StyleGuideComponent,
    ],
    providers: [],
})
export class StyleGuideModule {
    constructor (
        public library: FaIconLibrary,
    ) {
        library.addIcons(faTwitter, faFacebookF, faLinkedinIn,
            faExclamationTriangle, faTimes, faLock, faArrowLeft, faAngleRight,
            faNewspaper, faSuitcase, faVideo, faCalendar, faQuestionCircle,
            faSearch, faPlusCircle, faTimesCircle, faCaretDown, faCaretRight, faChartBar,
            faInbox, faAddressCard, faCog, faBars, faHeart, faHeartOpen, faSort, faSlidersH,);
    }
}
