import { Injectable, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { EventDate, EventsResponse, EventTypes, EventTypesResponse, Option, PostPackage } from '../../content/content.model';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ToasterService } from './toaster.service';
import * as momentTz from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class EventsService implements OnInit {

    public calendarEventDaySelected = new ReplaySubject<EventDate>();
    public months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    public monthsFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    public userTimezone: string;

    constructor(
        private http: HttpClient,
        private toaster: ToasterService,
    ) {
        this.userTimezone = momentTz.tz.guess();
    }

    ngOnInit (): void {
    }

    getEvents(month: string, year: number | string, eventType?: number): Observable<EventsResponse> {
        const eventTypeParameter = eventType ? `?event_type=${eventType}` : '';
        return this.http.get<EventsResponse>(`${environment.API_URL}/events/month/${month}/year/${year}${eventTypeParameter}`)
            .pipe(
                catchError(err => {
                  return this.toaster.handleErrors(err);
                }),
                map((res: EventsResponse) => {
                  return res;
                }),
            );
    }

    getEventTypes(): Observable<EventTypes[]> {
        return this.http.get<EventTypesResponse>(`${environment.API_URL}/event-types`)
            .pipe(
                catchError(err => {
                    return this.toaster.handleErrors(err);
                }),
                map((res: EventTypesResponse) => {
                    return res.data;
                }),
            );
    }

    getMonthString(month: number): string {
        if (month > 11) {
            return '';
        }
        return this.months[month];
    }

    getMonthFullString(month: number): string {
        if (month > 11) {
            return '';
        }
        return this.monthsFull[month];
    }

    eventInRange(event: PostPackage, filterRange: { start: number, end: number } | null) {
        if (!filterRange) {
            return true;
        }

        const startsAt   = event.starts_at.split("-").join("/");
        const eventStart = new Date(
            new Date(startsAt).getTime() + this.getTimezoneOffset(new Date(startsAt).getTime())
        ).getTime();

        const endsAt   = event.ends_at.split("-").join("/")
        const eventEnd = new Date(
            new Date(endsAt).getTime() + this.getTimezoneOffset(new Date(endsAt).getTime())
        ).getTime();
        if (eventStart <= filterRange.end && eventEnd >= filterRange.start) {
            return true;
        }

        return false;
    }

    getTimezoneOffset(date: number): number {
        // convert to MT to match event timezone - we currently default to MST / MDT
        return new Date(date).getTimezoneOffset() * 60 * 1000;
    }

    getRangeFromTimeframe(timeframe: string): {start: number, end: number} | null
    {
        let start: string;
        let end: string;
        let timezoneAdjust = true;
        const today = new Date();

        switch (timeframe) {
            case 'today':
                const date = today;
                start = date.toString();
                end = date.toString();
                break;
            case 'tomorrow':
                const tomorrow = new Date(today.getTime() + 86400000);
                start = end = tomorrow.toString();
                break;
            case 'this_week':
                start = this.getStartOfWeek(new Date()).toString();
                end = this.getEndOfWeek(new Date()).toString();
                timezoneAdjust = false;
                break;
            case 'next_week':
                let nextWeek = new Date();
                nextWeek = new Date(today.getTime() + 604800000);
                start = this.getStartOfWeek(nextWeek).toString();
                end = this.getEndOfWeek(nextWeek).toString();
                timezoneAdjust = false;
                break;
            default:
                return null;
        }

        if (!start || !end) {
            return null;
        }

        return {
            start: new Date(this.getDayStart(start)).getTime(),
            end: new Date(this.getDayEnd(end, timezoneAdjust)).getTime(),
        }
    }

    getStartOfWeek(date): Date {
        date = new Date(date.getTime() + this.getTimezoneOffset(date.getTime()));
        const diff = date.getDate() - date.getDay();
        date = new Date(date.setDate(diff));
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
        return date;
    }

    getEndOfWeek(date): Date {
        date = new Date(date.getTime() + this.getTimezoneOffset(date.getTime()));
        const diff = date.getDate() + 6 - date.getDay();
        date = new Date(date.setDate(diff));
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
        return date;
    }

    getDayStart(date: string): number {
        let startDate = new Date(date);
        startDate = new Date(startDate.getTime() + this.getTimezoneOffset(startDate.getTime()));
        return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0).getTime();
    }
    
    getDayEnd(date: string, timezoneAdjust = true): number {
        let endDate = new Date(date);
        if (timezoneAdjust) {
          endDate = new Date(endDate.getTime() + this.getTimezoneOffset(endDate.getTime()));
        }
        return new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59).getTime();
    }

    getScrollTypes(): Option[]
    {
        return [
            {
              value: 'any',
              option: 'Any Day',
            },
            {
              value: 'today',
              option: 'Today',
            },
            {
              value: 'tomorrow',
              option: 'Tomorrow',
            },
            {
              value: 'this_week',
              option: 'This Week',
            },
            {
              value: 'next_week',
              option: 'Next Week',
            },
        ];
    }
}
