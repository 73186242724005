import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { LegalService } from '../legal.service';
import { Eula } from '../legal.model';
import { FormGroup } from '@angular/forms';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpBackend } from '@angular/common/http';

@Component({
    selector: 'mxc-legal',
    templateUrl: './legal-modal.component.html',
    styleUrls: ['./legal-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class LegalModalComponent implements OnInit {
    @Output() eulaAccepted: EventEmitter<any> = new EventEmitter();
    @Output() eulaDeclined: EventEmitter<any> = new EventEmitter();

    eula: Eula;
    eulaForm: FormGroup;
    userSelection: string;
    accepted = false;

    constructor(
        private legalService: LegalService, private http: HttpClient, private handler: HttpBackend,
    ) {
        this.http = new HttpClient(this.handler);
    }

    ngOnInit(): void {
        this.legalService.getEula().subscribe(res => {
            this.eula = res.data;
        });
    }

    acceptEula(): void {
        this.legalService.acceptEula(this.eula.id).subscribe(() => {
            this.userSelection = 'accept';
            setTimeout(() => this.eulaAccepted.emit('accepted'), 2000);
        });
    }

    declineEula(): void {
        this.legalService.declineEula(this.eula.id).subscribe(() => {
            this.userSelection = 'decline';
            setTimeout(() => this.eulaDeclined.emit('declined'), 2000);
        });
    }

    backToEula(): void {
        this.userSelection = '';
    }

    closeModal(): void {
        this.eulaAccepted.emit('closeAccepted');
    }
}
