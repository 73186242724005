import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { ClickOutsideModule } from 'ng-click-outside';
import { ReactiveFormsModule } from '@angular/forms';
import { ContentService } from '../content/content.service';
import { ShortProfileComponent, SessionTimeoutComponent } from '../shared/components';
import { DeviceDetectorService, HoverIntentDirective, SharedModule } from '../shared';
import { VideoModalComponentModule } from '../content/components';
import { VideoModalComponent } from '../content/components/video-modal/video-modal.component';


// font awesome imports
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    faBars,
    faComments,
    faDollarSign,
    faSignOutAlt,
    faAngleDown,
    faCaretUp,
    faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

import { faSearch, faUserCircle } from '@fortawesome/pro-regular-svg-icons';

import {
    HeaderComponent,
    HeaderAltComponent,
    HeaderAltV2Component,
    MenuComponent,
    RouteViewComponent,
    SearchComponent,
    FooterAltComponent,
    FooterAltV2Component,
    LogoutModalComponent,
    ChangeUserComponent,
    RouteViewAltComponent,
    NestedNavComponent,
    SideNavigationV2Component,
    HeaderV2Component,
} from './';
import { ModalComponent } from '../shared/components/modal/modal.component';
import { PaywallComponent } from '../shared/components/paywall/paywall.component';
import {MenuDesktopComponent} from './header/components/menu/menu-desktop/menu-desktop.component';
import { VideoEmbedPipe } from '../content/video-embed.pipe';
import { LayoutV2Component } from './layout-2/layout-v2.component';
import { AppsService } from '../apps/apps.service';
import { ModalV2Component } from '../shared/components/modal-v2/modal-v2.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ClickOutsideModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        SharedModule,
        VideoModalComponentModule,
    ],
    exports: [
        ModalComponent,
        ModalV2Component,
        PaywallComponent,
        HeaderComponent,
        HeaderAltComponent,
        HeaderAltV2Component,
        MenuComponent,
        ShortProfileComponent,
        RouteViewComponent,
        RouteViewAltComponent,
        SearchComponent,
        FooterAltComponent,
        FooterAltV2Component,
        LogoutModalComponent,
        ChangeUserComponent,
        VideoModalComponent,
        MenuDesktopComponent,
        SessionTimeoutComponent,
    ],
    declarations: [
        ModalComponent,
        ModalV2Component,
        PaywallComponent,
        HeaderComponent,
        HeaderAltComponent,
        HeaderAltV2Component,
        MenuComponent,
        ShortProfileComponent,
        RouteViewComponent,
        RouteViewAltComponent,
        SearchComponent,
        FooterAltComponent,
        FooterAltV2Component,
        LogoutModalComponent,
        ChangeUserComponent,
        NestedNavComponent,
        HoverIntentDirective,
        MenuDesktopComponent,
        LayoutV2Component,
        SideNavigationV2Component,
        HeaderV2Component,
        SessionTimeoutComponent,
    ],
    providers: [
        ContentService,
        VideoEmbedPipe,
        AppsService,
        DeviceDetectorService,
    ],
})
export class LayoutModule {
    constructor (
        public library: FaIconLibrary,
    ) {
        library.addIcons(faBars, faSearch, faComments, faDollarSign, faSignOutAlt, faAngleDown, faCaretUp, faCaretDown, faUserCircle);
    }
}
