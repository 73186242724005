import { Component, Input, OnChanges } from '@angular/core';
import * as zxcvbn from 'zxcvbn';

@Component({
    selector: 'mxc-pw-strength',
    templateUrl: './pw-strength.component.html',
    styleUrls: ['./pw-strength.component.scss'],
})
export class PwStrengthComponent implements OnChanges {

    @Input() passwordToCheck = '';
    passwordStrength: zxcvbn;

    ngOnChanges(): void {
        this.passwordStrength = zxcvbn(this.passwordToCheck);
    }
}
