import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    template: '<div class="row"></div>',
})
export class PaginationComponent {
    isLoading = true;
    currentPage = 1;
    totalPages: number;
    totalItems: number;
    tileData: any[] = [];
    currentPath = '/path';
    urlQueryParams: any = {};
    searchTerm = '';
    viewType: string;

    constructor(
        public router: Router,
    ) { }

    nextPage(): void {
        this.urlQueryParams = {
            ...this.urlQueryParams,
            page: this.currentPage,
        };

        this.updateUrl();
    }

    updateUrl(childRoute: (string | number)[] = []): void {
        // the double catch is needed during testing to avoid exception: 'Unhandled Promise rejection:'
        try {
            this.router.navigate([this.currentPath, {}, ...childRoute], { queryParams: this.urlQueryParams }).catch(() => {
            });
        } catch (err) {}
    }

    updateQueryParamSortBy(sortBy: any): void {
        this.urlQueryParams = {
            ...this.urlQueryParams,
            sortBy,
            order: this.urlQueryParams.order === 'asc' ? 'desc' : 'asc',
            page: 1,
        };

        this.updateUrl();
    }
}
