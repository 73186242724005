import { ModuleWithProviders, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {RouterModule, ActivatedRouteSnapshot, Router, NavigationEnd} from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';

import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { StyleGuideModule } from './style-guide/style-guide.module';
import { TabService } from './shared';
import { CookieService } from 'ngx-cookie-service';

// elastic apm rum imports
import { ApmModule, ApmService } from '@elastic/apm-rum-angular';

// font awesome imports
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import {
    ToasterComponent, AuthInterceptor, CachingInterceptor, HttpCache,
    GlobalErrorHandler, SessionInterceptor
} from './shared';
import { MenuV2Service } from './layout/layout-2/menu-v2.service';
import { ContentService } from './content/content.service';
import { RosterService } from './roster/roster.service';
import { SegmentService } from './shared/services/segment.service';
import { BuildYourBusinessService } from './build-your-business/build-your-business.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { ResetPasswordReportComponent } from './reset-password-report/reset-password-report.component';

export class NullComponent {
    // null component for off-site links
}

const rootRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot([
    {
        path: 'apps',
        loadChildren: () => import('app/apps/apps.module').then(m => m.AppsModule),
        data: { name: 'Apps' },
    },
    {
        path: 'build-your-business',
        loadChildren: () => import('app/build-your-business/build-your-business.module').then(m => m.BuildYourBusinessModule),
        data: { name: 'Build Your Business Checklist' },
    },
    {
        path: 'content',
        loadChildren: () => import('app/content/content.module').then(m => m.ContentModule),
        data: { name: 'RE/MAX: News & Resources' },
    },
    {
        path: 'search',
        loadChildren: () => import('app/search-results/search-results.module').then(m => m.SearchResultsModule),
        data: { name: 'Search Results' },
    },
    {
        path: 'favorites',
        loadChildren: () => import('app/favorites/favorites.module').then(m => m.FavoritesModule),
        data: { name: 'Favorites' },
    },
    {
        path: 'campaigns',
        loadChildren: () => import('app/campaigns/campaigns.module').then(m => m.CampaignsModule),
        data: { name: 'Campaigns' },
    },
    {
        path: 'shortener',
        loadChildren: () => import('app/shortener/shortener.module').then(m => m.ShortenerModule),
        data: { name: 'URL Shortener' },
    },
    {
        path: 'roster',
        loadChildren: () => import('app/roster/roster.module').then(m => m.RosterModule),
        data: { name: 'Find an Affiliate/Office' },
    },
    {
        path: 'approved-supplier',
        loadChildren: () => import('app/approved-supplier/approved-supplier.module').then(m => m.ApprovedSupplierModule),
        data: { name: 'Find an Affiliate/Office' },
    },
    {
        path: 'join',
        loadChildren: () => import('app/join/join.module').then(m => m.JoinModule),
        data: { name: 'Customize JoinRemax' },
    },
    {
        path: 'site-map',
        loadChildren: () => import('app/site-map/site-map.module').then(m => m.SiteMapModule),
        data: { name: 'Site Map' },
    },
    {
        path: 'home',
        loadChildren: () => import('app/landing-page/landing-page.module').then(m => m.LandingPageModule),
        data: { name: 'Landing Page' },
    },
    {
        path: 'profile',
        loadChildren: () => import('app/profile/profile.module').then(m => m.ProfileModule),
        data: { name: 'Profile' },
    },
    {
        path: 'reset-password-report',
        loadChildren: () => import('./reset-password-report/reset-password-report.module').then(m => m.ResetPasswordReportModule),
        data: { name: 'Reset Password Report' },
    },
    // non-protected routes
    {
        path: 'forgot-password',
        loadChildren: () => import('app/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
        data: { name: 'Forgot Password' },
    },
    {
        path: 'register', loadChildren: () => import('app/register/register.module').then(m => m.RegisterModule),
        data: { name: 'Register', hidden: true },
    },
    {
        path: 'user-agreement',
        loadChildren: () => import('app/legal/legal-static/legal-static.module').then(m => m.LegalStaticModule),
        data: { name: 'User Agreement' },
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('app/legal/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
        data: { name: 'Privacy Policy' },
    },
    {
        path: '404',
        loadChildren: () => import('app/not-found/not-found.module').then(m => m.NotFoundModule),
        data: { name: 'Page Not Found' },
    },

    // offsite links follow this pattern
    { path: 'help', component: NullComponent, resolve: { path: 'https://www.google.com' }, data: { name: 'Service Desk' } },
    { path: 'billing', component: NullComponent, resolve: { path: 'https://www.youtube.com' }, data: { name: 'Pay Bill' } },

    // redirects
    { path: 'apps/join', redirectTo: 'join' },
    { path: 'apps/recruit/post/:id', redirectTo: '/content/:id' },
    { path: 'apps/tech/post/:id', redirectTo: '/content/:id' },

    // smart 404 redirects
    { path: 'connect/Pages/:any', redirectTo: 'apps' },
    { path: 'connect/webroster/Pages/AffiliateSearch.aspx', redirectTo: 'roster/user' },
    { path: 'connect/webroster/Pages/OfficeSearch.aspx', redirectTo: 'roster/office' },
    { path: 'referrals/Pages/ManageReferrals.aspx', redirectTo: 'apps' },
    { path: 'connect/myregion/Pages/default.aspx', redirectTo: 'apps' },
    { path: 'myAccount/Pages/default.aspx', redirectTo: 'apps' },
    { path: 'connect/commercialcenter/Pages/Commercial Real Estate.aspx', redirectTo: '/content?dept=commercial' },
    { path: 'connect/brokerownertools/Pages/brokerownertools.aspx', redirectTo: 'apps' },
    { path: 'pages/BrokerEmailReservation.aspx', redirectTo: 'apps' },
    { path: 'recruit/Pages/default.aspx', redirectTo: '/content?dept=recruit' },
    { path: 'connect/brokerownertools/recruitretainmanage/Pages/BusinessTools.aspx', redirectTo: 'apps' },
    { path: 'learn/Pages/:any', redirectTo: 'apps' },
    { path: 'marketing/Pages/:any', redirectTo: 'apps' },
    { path: 'marketing/downloads/Pages/default.aspx', redirectTo: 'apps' },
    { path: 'marketing/luxuryhomes/Pages/luxuryhomes.aspx', redirectTo: '/content?dept=luxury' },
    { path: 'marketing/givingback/Pages/givingback.aspx', redirectTo: '/content?dept=giving-back' },
    { path: 'events/Pages/default.aspx', redirectTo: '/content?dept=events-edr' },
    { path: 'events/conventions/Pages/conventions.aspx', redirectTo: '/content?dept=events-edr' },
    {
        path: 'News/Pages/default.aspx',
        component: NullComponent,
        resolve: {
            url: 'externalUrlRedirectResolver',
        },
        data: {
            externalUrl: 'https://abovemag.remax.com',
        },
    },
    { path: 'News/franchisesales/Pages/franchisesales.aspx', redirectTo: 'apps' },
    {
        path: 'shop/Pages/shop.aspx',
        component: NullComponent,
        resolve: {
            url: 'externalUrlRedirectResolver',
        },
        data: {
            externalUrl: 'https://shop.remax.net',
        },
    },
    {
        path: 'shop/Pages/InsuranceCenter.aspx',
        component: NullComponent,
        resolve: {
            url: 'externalUrlRedirectResolver',
        },
        data: {
            externalUrl: 'https://shop.remax.net/collections/financial-insurance-education-services',
        },
    },
    {
        path: 'marketing/Pages/SocialNetworking.aspx',
        component: NullComponent,
        resolve: {
            url: 'externalUrlRedirectResolver',
        },
        data: {
            externalUrl: 'https://www.remaxmarketing.com/digital-social',
        },
    },
    { path: 'News/Pages/Top100Lists.aspx', redirectTo: '/content?dept=membership-awards' },
    { path: 'Pages/UserAgreement.aspx', redirectTo: 'user-agreement' },
    { path: 'Pages/PrivacyPolicy.aspx', redirectTo: 'privacy-policy' },
    {
        path: 'Pages/ContactUs.aspx',
        component: NullComponent,
        resolve: {
            url: 'externalUrlRedirectResolver',
        },
        data: {
            externalUrl: 'http://servicedesk.remax.com/',
        },
    },
    { path: 'humanresources/Lists/Headquarter%20Photo%20Roster/AllItems.aspx', redirectTo: 'roster/corporate' },
    { path: 'eventmanagement/Pages/default.aspx', redirectTo: '/content?dept=events-edr' },
    { path: 'RU/Pages/default.aspx', redirectTo: '/content?dept=remax-university' },
    { path: 'Lists/REMAX Events/calendar.aspx', redirectTo: '/content?dept=events-edr' },
    { path: 'documentcenter/default.aspx', redirectTo: 'content' },
    { path: 'auth', redirectTo: 'apps' },

    // fallback for non-matching routes
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '**', redirectTo: '404', pathMatch: 'full' },
], { useHash: false });

@NgModule({
    declarations: [
        AppComponent,
        ToasterComponent,
    ],
    imports: [
        ApmModule,
        BrowserAnimationsModule,
        BrowserModule,
        LoginModule,
        NgbModule,
        rootRouting,
        StyleGuideModule,
        HttpClientModule,
        FontAwesomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            defaultLanguage: 'en',
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        HttpCache,
        TabService,
        CookieService,
        MenuV2Service,
        ContentService,
        BuildYourBusinessService,
        TranslateService,
        ApmService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: 'externalUrlRedirectResolver',
            useValue: (route: ActivatedRouteSnapshot) => {
                window.location.href = (route.data as any).externalUrl;
            },
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
})

export class AppModule {
    constructor (
        public service: ApmService,
        public library: FaIconLibrary,
        private router: Router,
    ) {
        library.addIcons(faTimes);
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd && !/^\/profile/i.test(e.url)) {
                /* scroll-to-top on route change if route does not start with '/profile'
                 previously {scrollPositionRestoration: 'enabled'} in RouterModule.forRoot() options handled this
                 removed that option to allow smooth scroll-to-anchor on /profile routes
                 this will handle scroll-to-top on route change for all other routes */
                window.scrollTo({top: 0, behavior: 'auto'});
            }
        });

        // Agent API is exposed through this apm instance
        const apm = service.init({
            serviceName: 'MXC-PORTAL',
            serverUrl: 'https://9ea6aa0f23bf4254a6e8933acfdbd4b5.apm.us-east-1.aws.cloud.es.io:443',
            environment: environment.APP_ENV,
        });
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
