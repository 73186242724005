import { trigger, transition, animate, style } from '@angular/animations';

export const flyOutAnimationLeft = trigger('flyOutAnimationLeft', [
    transition(':enter', [
        // start
        style({ left: '-100%' }),
        // end
        animate('0.4s ease-out', style({ left: 0 })),
    ]),
    transition(':leave', [
        // start
        style({ left: 0 }),
        // end
        animate('0.4s ease-in', style({ left: '-100%' })),
    ]),
]);
