import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { LogService } from '../services/log.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as stacktrace from 'stacktrace-js';
import { LogEntry } from '../models/log.model';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: Error): void {
        const logService = this.injector.get(LogService);
        if (error instanceof HttpErrorResponse) {
            const err = error.error.data;
            let message;
            if (typeof err === 'object' && err !== null && (!Array.isArray(err))) {
                message = (Object.values(err) as any).flat();
            } else if (
                typeof err[0] === 'object'
                && err !== null
                && (!Array.isArray(err[0]))
            ) {
                message = (Object.values(err[0]) as any).flat();
            } else {
                message = err;
            }
            const log: LogEntry = {
                type: 'error',
                message: `Status: ${error.status}\nEndpoint: ${error.url}\nMessage: ${message}`,
            };
            logService.log(log);
        } else {
            stacktrace.fromError(error).then(sf => {
                const stringified = sf.map(frame => {
                    frame.fileName = frame.fileName.includes('webpack') ? frame.fileName.split('webpack:///')[1] : frame.fileName;
                    return frame.toString();
                }).join('\n');
                const log: LogEntry = {
                    type: 'error',
                    message: error.message,
                    trace: stringified,
                };
                logService.log(log);
            });
        }

        console.error(error);
    }
}
