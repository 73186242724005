import { Component, OnInit, Input, Inject, NgModule, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UserService } from "../../services";
import { ToasterService } from '../../../shared';
import { SegmentService } from "../../services/segment.service";
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'mxc-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {
  @Input() public user: any;
  @Input() public sessionTimerStart: boolean = false;

  public displayed: boolean = false;
  public title: string;
  public message: string;
  public buttons: Array<{
    title: string,
    type: string,
    onClick?: any,
    url?: string,
    single?: boolean,
  }>;

  constructor(
      public userService: UserService,
      public segmentService: SegmentService,
      public router: Router,
      public toasterService: ToasterService,
      @Inject(Window) private window: Window,
      @Inject(TranslateService) private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    let popupType = '';
    
    this.title = '';
    this.message = '';
    this.buttons = [{
      title: '',
      type: 'secondary',
      single: false,
      onClick:(e: { preventDefault: () => void; })=> {
        this.logOutBtn();
        e.preventDefault();
      },
    },{
      title: '',
      type: 'primary',
      single: false,
      onClick:(e: { preventDefault: () => void; })=> {
        this.dismiss();
        e.preventDefault();
      }
    }];
    this.translateSessionTimeout();

    const sessionExpirationTime = () => {
      const exp = this.userService.getExpirationAlertTime();
      const now = moment();
      
      if (now.add(5, 'minutes').isAfter(exp)) {
        this.displayed = true;
      } else {
        this.displayed = false;
      }
      setTimeout(() => {sessionExpirationTime()}, 10000);
    };
    
    sessionExpirationTime();

    popupType = 'Session Timed Out';

    this.segmentService.track('Session Popup Shown', {
      popup_type: popupType,
    });
  }

  dismiss() {
    this.displayed = false;
    this.trackClick('Stay Logged In');
    this.userService.extendSession().subscribe((res) => {
      this.userService.saveTokens({
        token: res.data.token,
        sessionToken: res.data.sessionToken,
        timestamp: res.data.timestamp,
      });
    }, err => {
      err.error.data = '';
      this.translate.get('components.sessionTimeout.error').subscribe((value) => {
        err.error.data = value;
        this.toasterService.handleErrors(err);
      });
    });
  }

  logOutBtn() {
    this.userService.deauthenticate();
    this.router.navigate(['/login']);
    this.trackClick('Log Out');
  }

  trackClick(cta: string) {
    this.segmentService.track('Session Timeout Popup Clicked', {
      cta: `${cta}`,
    });
  }

  translateSessionTimeout() {
    this.translate.get('components.sessionTimeout.buttons.logout').subscribe((value) => {
      this.buttons[0].title = value;
    });

    this.translate.get('components.sessionTimeout.buttons.stayLoggedIn').subscribe((value) => {
      this.buttons[1].title = value;
    });
  }

}
