<div class="d-flex flex-row list-item" (click)="navigateToPost()">
  <img class="img my-auto ml-2" [src]="thumbnailUrl" [alt]="title" mxcZeroStateImg data-hook="list-item-img" />
  <div class="flex-column ml-4">
    <div class="department-name open-sans-regular" [innerHTML]="departmentName | highlighter:searchTerm:false:!searchTerm?.length">{{ departmentName.toLocaleUpperCase() }}</div>
    <div class="montserrat font-weight-bold title" data-hook="list-item-title" [innerHTML]="title | highlighter:searchTerm:false:!searchTerm?.length">{{ title }}</div>
    <div class="flex-sm-row d-none d-sm-flex">
      <div class="date-excerpt open-sans-regular">{{ date | formatDate }} <span class="excerpt" data-hook="excerpt" [innerHTML]="excerpt | highlighter:searchTerm:false:!searchTerm?.length">{{ excerpt }}</span></div>
    </div>
  </div>
  <div class="ml-auto mr-4 my-auto pl-1">
    <div class="action-bar d-flex bg-light ml-2 ml-auto">
      <div class="d-flex w-100 align-items-center">
        <button
          (click)="$event.stopPropagation(); downloadAsset(parentAsset.id)"
          *ngIf="parentAsset"
          class="btn btn-icon mr-2"
          data-hook="download-icon"
        >
          <fa-icon icon="download" title="Download {{ postType | titlecase }} Content"></fa-icon>
        </button>
        <div [ngClass]="{ 'margin-left': !parentAsset }">
          <mxc-favorite
            (click)="$event.stopPropagation()"
            type="posts"
            [id]="postId"
            responsive="true"
            [postType]="postType"
            [postName]="title"
            [post]="post"
            [viewType]="viewType"
          ></mxc-favorite>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="line mt-3"></div>
