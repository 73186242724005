import { Component, Input, OnInit } from '@angular/core';
import { Errors } from '../../models';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mxc-list-errors',
  templateUrl: './list-errors.component.html',
})
export class ListErrorsComponent implements OnInit {
  @Input() public errors: Errors;
  @Input() public icon?: string;
  iconObject: any;

  ngOnInit(): void {
    switch (this.icon) {
      case 'error': this.iconObject = faExclamationTriangle;
    }
  }
}
