<div id="expired-password" class="text-center pb-5">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-xl-4">
                <form [formGroup]="changePasswordForm" (ngSubmit)="submit()" data-hook="expired-password-form">
                    <h2 data-hook="page-header">Expired Password</h2>
                    <mxc-pw-requirements [passwordToCheck]="passwordToCheck" [userName]="userName" [oktaError]="oktaError">
                    </mxc-pw-requirements>
                    <div class="form-group">
                        <label for="oldPassword" class="sr-only">Old Password</label>
                        <input formControlName="oldPassword" id="oldPassword" placeholder="Old Password*"
                            class="form-control" type="password"
                            [ngClass]="checkInvalidClass('changePasswordForm', 'oldPassword')" data-hook="old-password"
                            required>
                        <i *ngIf="validateInput('changePasswordForm', 'oldPassword');" zxcvbn="passwordStrength"
                            class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                    </div>
                    <div class="form-group">
                        <label for="newPassword" class="sr-only">New Password</label>
                        <input formControlName="newPassword" id="newPassword" placeholder="New Password*"
                            class="form-control" type="password"
                            [ngClass]="checkInvalidClass('changePasswordForm', 'newPassword')" data-hook="new-password"
                            required>
                        <i *ngIf="validateInput('changePasswordForm', 'newPassword')"
                            class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                    </div>
                    <div class="form-group">
                        <label for="confirmPassword" class="sr-only">Confirm Password</label>
                        <input formControlName="confirmPassword" id="confirmPassword"
                            placeholder="Re-Type New Password*" class="form-control" type="password"
                            [ngClass]="checkInvalidClass('changePasswordForm', 'confirmPassword')"
                            data-hook="confirm-password" required>
                        <i *ngIf="validateInput('changePasswordForm', 'confirmPassword')"
                            class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                    </div>
                    <small *ngIf="validateInput('changePasswordForm', 'newPassword');" class="d-block text-danger"
                        data-hook="new-password--error-message">Password does not meet the requirements. Please update
                        your
                        password and try again.</small>
                    <small class="d-block text-danger" *ngIf="validateInput('changePasswordForm', 'confirmPassword')"
                        data-hook="confirm-password--error-message">Passwords do not match.</small>
                    <button class="btn btn-primary btn-xs-block" type="submit" data-hook="submit-button"
                        [disabled]="changePasswordForm.invalid">Reset Password</button>
                </form>
            </div>
        </div>
    </div>
</div>
