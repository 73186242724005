import { Component, Input } from '@angular/core';

@Component({
  selector: 'mxc-footer-alt-v2',
  templateUrl: './footer-alt-v2.component.html',
  styleUrls: ['./footer-alt-v2.component.scss'],
})

export class FooterAltV2Component {
  @Input() links: [{label: string, path: string}];
}
