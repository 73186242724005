import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";

@Injectable()
export class HttpCache {
  get(req: HttpRequest<any>): HttpResponse<any> | null {
    return this[req.url];
  }
  put(req: HttpRequest<any>, res: HttpResponse<any>): void {
    this[req.url] = res;
  }
  delete(key: string): void {
    delete this[key];
  }
}
