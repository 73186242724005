<div id="navigation" data-hook="navigation">
  <div id="scrolly-nav" class="overflow-hidden" [ngClass]="{ impersonating: impersonating }">
    <ul id="app-nav" class="nav flex-column pt-4">
      <div *ngFor="let routes of appNavigation; let i = index">
        <li class="nav-item">
          <span
            class="position-absolute red-line"
            [ngClass]="{ opened: open }"
            *ngIf="menuService.currentRouteIndex === i"
          ></span>
          <div
            class="pl-4 mt-2"
            *ngIf="routes && routes.length === 1; else isNestedNav"
            (click)="
              open = false;
              userService.continueOrLogout();
              triggerGTMEvent(routes[0].name);
              navigateToRoute(routes[0].path);
              setSelectedNav(i)"
            [attr.data-nav]="routes[0].navKey"
            data-foo="bar"
          >
            <div class="nav-icon" [ngClass]="{ active: selectedNav === routes }">
              <a
                data-hook="app-nav-link"
                class="nav-link d-inline-flex align-items-center"
                title="{{ routes[0].name }}"
                [ngClass]="{ 'is-active': selectedNav === routes }"
              >
                <fa-icon [fixedWidth]="true" [icon]="getIcon(i)" [title]="routes[0].name"> </fa-icon>
              </a>
              <span class="ml-3 nav-icon-title">{{ routes[0].nestedNavName }}</span>
            </div>
          </div>
          <ng-template #isNestedNav>
            <mxc-nested-nav
              [title]="routes[0].nestedNavName"
              [navKey]="routes[0].nestedNavKey"
              [icon]="routes[0].icon"
              [links]="routes"
              (closeMenu)="close()"
              (openNav)="openNav()"
              [navIsOpen]="open || mobile"
              [isImpersonator]="
                this.currentUser.groups.includes('Super Admin') || this.currentUser.groups.includes('Impersonators')
              "
              [isActive]="selectedNav === routes"
              (click)="setSelectedNav(i)"
              [index]="i"
            >
            </mxc-nested-nav>
          </ng-template>
        </li>
      </div>
    </ul>
  </div>
</div>
