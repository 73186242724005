<div class="container-fluid my-5">
    <h1>Style Guide</h1>
    <section id="notes">
        <p>Use this page as a reference when deciding which button, color, etc to use.</p>
    </section>
    <hr>
    <section id="cards">
        <h2> Cards </h2>
        <div class="row">
            <div class="col-md-3 mb-4">
                <!--APP CARD -->
                <div class="card app-card">
                    <div class="mxc-favorite">
                        <button class="favorite-toggle btn btn-icon" (click)="active = !active">
                            <fa-icon icon="heart" [ngClass]="{'text-danger' : this.active}" title="Toggle Favorite">
                            </fa-icon>
                        </button>
                    </div>
                    <img alt="App Name" src="http://via.placeholder.com/1280x720" data-hook="app-image">
                    <div class="card-footer">
                        <a title="Launch App" class="card-title" href="#" data-hook="app-link">
                            <span data-hook="app-title">App Name</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mb-4">
                <!-- POST CONTENT CARD-->
                <div class="card">
                    <div class="mxc-favorite">
                        <button class="favorite-toggle btn btn-icon" (click)="active = !active">
                            <fa-icon icon="heart" [ngClass]="{'text-danger' : this.active}" title="Toggle Favorite">
                            </fa-icon>
                        </button>
                    </div>
                    <div class="img-fixed-aspect">
                        <div class="content">
                            <a href="#" title="Read Full Post" data-hook="view-post-link">
                                <img src="http://via.placeholder.com/1280x1280" data-hook="card-img">
                            </a>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="card-title">
                            <a title="View Content" routerLink="/content/{{postId}}" class="h4"
                                data-hook="card-title">Post Title</a>
                        </div>
                        <p class="card-text" data-hook="excerpt">Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Si longus, levis dictata sunt. Profectus in exilium Tubulus statim nec respondere
                            ausus; Illa videamus, quae a te de amicitia dicta sunt.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mb-4">
                <!-- OFFICE PROFILE CARD -->
                <div class="card">
                    <div class="mxc-favorite">
                        <button class="favorite-toggle btn btn-icon" (click)="active = !active">
                            <fa-icon icon="heart" [ngClass]="{'text-danger' : this.active}" title="Toggle Favorite">
                            </fa-icon>
                        </button>
                    </div>
                    <div class="img-square">
                        <div class="content">
                            <a href="#" title="View Full Profile" data-hook="office-profile-link">
                                <img alt="Photo of [Office]" src="http://via.placeholder.com/1280x720"
                                    data-hook="office-profile-image">
                            </a>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="card-title">
                            <a href="#" title="View Full Profile" data-hook="office-name">Office Name</a>
                        </div>
                        <p class="card-text" data-hook="office-location">Location, US</p>
                    </div>
                    <div class="card-footer bg-light">
                        <!-- don't forget to add ngIf checks -->
                        <strong>Region:</strong> <span data-hook="office-region">Region Name</span><br>
                        <strong>Specialty:</strong> <span data-hook="office-type">Office Specialty</span><br>
                        <strong>Number of Agents:</strong> <span data-hook="office-agent-count">69</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mb-4">
                <!-- USER PROFILE CARD -->
                <div class="card">
                    <div class="mxc-favorite">
                        <button class="favorite-toggle btn btn-icon" (click)="active = !active">
                            <fa-icon icon="heart" [ngClass]="{'text-danger' : this.active}" title="Toggle Favorite">
                            </fa-icon>
                        </button>
                    </div>
                    <div class="img-square">
                        <div class="content">
                            <a href="#" title="View Full Profile" data-hook="user-profile-link">
                                <img alt="Photo of [User]" src="http://via.placeholder.com/100x100"
                                    data-hook="user-profile-image">
                            </a>
                        </div>
                    </div>
                    <div class="card-footer">
                        <!-- don't forget to add ngIf checks -->
                        <div class="card-title">
                            <a href="#" title="View Full Profile" data-hook="user-name">User Name</a>
                        </div>
                        <p class="card-text">
                            <span data-hook="user-title">User Title</span>
                            &bull;
                            <span data-hook="user-specialty">User Specialty</span>
                        </p>
                    </div>
                    <div class="card-footer bg-light">
                        <!-- don't forget to add ngIf checks -->
                        <strong>Office:</strong> <a href="#" data-hook="user-office">office name</a><br>
                        <strong>Region:</strong> <span data-hook="user-region">user region</span><br>
                        <strong>Licensed:</strong> <span data-hook="user-year-licensed">Since 1969 (420 Years)</span>
                    </div>
                </div>
                <!-- CORPORATE PROFILE CARD -->
                <div class="card">
                    <div class="mxc-favorite">
                        <button class="favorite-toggle btn btn-icon" (click)="active = !active">
                            <fa-icon icon="heart" [ngClass]="{'text-danger' : this.active}" title="Toggle Favorite">
                            </fa-icon>
                        </button>
                    </div>
                    <div class="img-square">
                        <div class="content">
                            <a href="#" title="View Full Profile" data-hook="user-profile-link">
                                <img alt="Photo of [User]" src="http://via.placeholder.com/100x100"
                                    data-hook="user-profile-image">
                            </a>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="card-title">
                            <a href="#" title="View Full Profile" data-hook="user-name">User Name</a>
                        </div>
                        <p class="card-text">
                            <span data-hook="user-title">User Title</span>
                        </p>
                    </div>
                    <div class="card-footer bg-light">
                        <!-- don't forget to add ngIf checks -->
                        <strong>Office:</strong> <a href="#" data-hook="user-office">office name</a><br>
                        <strong>Phone:</strong> <span data-hook="user-region">user region</span><br>
                        <strong>Email:</strong> <span data-hook="user-year-licensed">Since 1969 (420 Years)</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <hr>
    <section id="branding">
        <h2>Branding</h2>
        <div class="row mb-2">
            <div class="col-12 text-center">
                <img alt="MAXCNTR LOGO" class="img-fluid" src="https://dev.assets.remax.net/mc-brand/mxc-logo.svg">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="bg-primary text-light p-2 text-center">
                    <small>
                        $primary
                    </small>
                </div>
            </div>
            <div class="col">
                <div class="bg-secondary text-light p-2 text-center">
                    <small>
                        $secondary
                    </small>
                </div>
            </div>
            <div class="col">
                <div class="bg-tertiary text-light p-2 text-center">
                    <small>
                        $tertiary
                    </small>
                </div>
            </div>
            <div class="col">
                <div class="bg-success p-2 text-center">
                    <small>
                        $success
                    </small>
                </div>
            </div>
            <div class="col">
                <div class="bg-info p-2 text-center">
                    <small>
                        $info
                    </small>
                </div>
            </div>
            <div class="col">
                <div class="bg-warning p-2 text-center">
                    <small>
                        $warning
                    </small>
                </div>
            </div>
            <div class="col">
                <div class="bg-danger text-light p-2 text-center">
                    <small>
                        $danger
                    </small>
                </div>
            </div>
            <div class="col">
                <div class="bg-light p-2 text-center">
                    <small>
                        $light
                    </small>
                </div>
            </div>
            <div class="col">
                <div class="bg-dark text-light p-2 text-center">
                    <small>
                        $dark
                    </small>
                </div>
            </div>
        </div>
    </section>
    <hr>
    <section id="typography">
        <h2>Typography</h2>

        <div class="row">
            <section class="col-3">
                <h4>Font Stack</h4>
                <table>
                    <tr>
                        <td>$headings-font-family</td>
                        <td>
                            <h4>Montserrat</h4>
                        </td>
                    </tr>
                    <tr>
                        <td width="300">$font-family-base</td>
                        <td><strong>Arial</strong></td>
                    </tr>
                </table>
            </section>
            <section class="col">
                <h4>Basic Text</h4>
                <p class="lead">
                    Lead paragraph: vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                </p>
                <p>
                    Regular paragraph with <a href="#">internal link text</a>, and some <strong>bold text</strong>, as
                    well as
                    <em>italicized text</em>, and the 'abbr' tag: abbreviation of the word attribute is <abbr
                        title="attribute">attr</abbr>. Additionally there are examples of <span class="text-muted">muted
                        text</span> and <span class="text-success">success text</span>,
                    but all of the normal BS text-color classes are available. <small>This line of text using the
                        'small' tag.</small>
                </p>
            </section>
        </div>
        <div class="row">
            <section id="address" class="col-3">
                <h4>Address</h4>
                <address>
                    <strong>Twitter, Inc.</strong><br>
                    795 Folsom Ave, Suite 600<br>
                    San Francisco, CA 94107<br>
                    <abbr title="Phone">P:</abbr> (123) 456-7890
                </address>
                <address>
                    <strong>Full Name</strong><br>
                    <a href="mailto:#">first.last@example.com</a>
                </address>
            </section>

            <section id="blockquote" class="col">
                <h4>Blockquote</h4>
                <blockquote class="blockquote">
                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a
                        ante.</p>
                    <footer class="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite>
                    </footer>
                </blockquote>
            </section>

            <section id="lists" class="col">
                <h4>Lists</h4>
                <div class="row">
                    <ul class="col">
                        <li>Consectetur adipiscing elit</li>
                        <li>Integer molestie lorem at massa</li>
                        <li>Facilisis in pretium nisl aliquet</li>
                        <li>Nulla volutpat aliquam velit
                    </ul>
                    <ol class="col">
                        <li>Consectetur adipiscing elit</li>
                        <li>Integer molestie lorem at massa</li>
                        <li>Facilisis in pretium nisl aliquet</li>
                        <li>Nulla volutpat aliquam velit
                    </ol>
                </div>
            </section>
        </div>
    </section>
    <hr>
    <section id="iconography">
        <h2>Iconography</h2>
        <ul class="list-inline">
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon [icon]="['fab', 'twitter']" title="View My Twitter"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon [icon]="['fab', 'facebook-f']" title="View My Facebook"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon [icon]="['fab', 'linkedin-in']" title="View My LinkedIn"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="exclamation-triangle" class="text-danger"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="times" title="Close Element"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="lock" title="A separate account is needed to use this application."></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="arrow-left"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="newspaper"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="suitcase"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="video"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="calendar"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="question-circle"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="search"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="plus-circle"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="times-circle"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="chart-bar"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="caret-down"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="caret-right"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="inbox"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="address-card"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="cog"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="bars"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon [icon]="['far', 'heart']" class="text-danger"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="heart" class="text-danger"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="sort" class="text-black"></fa-icon>
            </li>
            <li class="list-inline-item mr-5 text-nowrap">
                <fa-icon icon="sliders-h" class="text-black"></fa-icon>
            </li>
        </ul>
    </section>
    <hr>
    <section id="components">
        <h2> Components </h2>
        <div class="row">
            <section id="buttons" class="col">
                <h4>Buttons</h4>
                <p>
                    <button type="button" class="btn btn-primary mr-2">Primary</button>
                    <button type="button" class="btn btn-primary mr-2 active">& focus</button>
                    <button type="button" class="btn btn-primary disabled">& disabled</button>
                </p>
                <p>
                    <button type="button" class="btn btn-danger mr-2">Danger</button>
                    <button type="button" class="btn btn-danger mr-2 active">& focus</button>
                    <button type="button" class="btn btn-danger disabled">& disabled</button>
                </p>
                <p>
                    <button type="button" class="btn btn-link mr-2">Link</button>
                    <button type="button" class="btn btn-link mr-2 active">& focus</button>
                    <button type="button" class="btn btn-link disabled">& disabled</button>
                </p>
            </section>
            <section id="badges" class="col">
                <h4>Badges</h4>
                <ul class="nav nav-pills mb-2">
                    <li class="nav-item">
                        <a href="#" class="nav-link active">
                            Nav Pills <span class="badge badge-light">420</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Example</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Messages <span class="badge badge-primary">69</span></a>
                    </li>
                </ul>

                <button class="btn btn-primary mb-2" type="button">
                    Basic button with badge <span class="badge badge-light">69</span>
                </button>

                <h4> Badges Used for Tags </h4>
                <span class="badge badge-pill badge-info">badge-pill</span>
                <span class="badge badge-pill badge-info">badge-info</span>
                <span class="badge badge-pill badge-dark">badge-dark</span>

            </section>
        </div>
    </section>
    <hr>
    <section id="tabs">
        <h2>Tabs</h2>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active" href="#">Active</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item">
                <a class="nav-link disabled" href="#">Disabled</a>
            </li>
        </ul>
    </section>
    <hr>
    <section id="forms">
        <h2>Forms</h2>
        <form>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="email">Input</label>
                        <input type="text" class="form-control" id="email" placeholder="Placeholder Text">
                    </div>
                    <div class="form-group">
                        <label for="email">Input Focus</label>
                        <input type="text" class="form-control focus" id="email" placeholder="Placeholder Text">
                    </div>
                    <div class="form-group">
                        <label for="email">Input Error</label>
                        <input type="text" class="form-control is-invalid" id="email" placeholder="Placeholder Text">
                        <fa-icon icon="exclamation-triangle" class="text-danger"></fa-icon>
                        <small class="text-danger">Enter a valid e-mail address.</small>
                    </div>
                    <div class="form-group">
                        <label for="email">Input Disabled / Read Only</label>
                        <input type="text" class="form-control" id="email" placeholder="Placeholder Text" disabled>
                    </div>
                    <div class="form-group">
                        <label for="email">Input Read Only</label>
                        <input type="text" class="form-control" id="email" placeholder="Placeholder Text" readonly>
                    </div>
                    <div class="form-group">
                        <label>Fake Select Input Using ngbDropdown</label>
                        <div class="form-control custom-select">
                            <div ngbDropdown>
                                <a ngbDropdownToggle>{{ selectValue }}</a>
                                <div ngbDropdownMenu>
                                    <a *ngFor="let option of selectOptions" (click)="changeMessage(option)"
                                        class="dropdown-item">{{ option }}</a>
                                </div>
                            </div>
                        </div>
                        <small>This requires some additional scripting in your component to make it work</small>
                    </div>
                    <div class="form-group">
                        <label for="inlineFormInputGroup">Input Group Append</label>
                        <div class="input-group mb-2 mb-sm-0">
                            <div class="input-group-prepend">
                                <span class="input-group-text">@</span>
                            </div>
                            <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="Username">
                            <div class="input-group-append">
                                <span class="input-group-text">POTATO</span>
                            </div>
                        </div>
                    </div>
                    <!-- large input
                    <div class="form-group">
                        <label for="name">Large Input</label>
                        <input type="text" class="form-control form-control-lg" id="name" placeholder="Name">
                    </div>
                    -->
                    <!-- small input
                    <div class="form-group">
                        <label for="password">Small Input (Read Only)</label>
                        <input type="password" class="form-control form-control-sm" id="password" placeholder="Password" readonly>
                        <small class="form-text">Example of the 'form-text' helper class.</small>
                    </div>
                    -->
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="text-box">Textarea</label>
                        <textarea id="text-box" class="form-control" rows="1"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="text-box">Textarea Error</label>
                        <textarea id="text-box" class="form-control is-invalid" rows="1"></textarea>
                        <fa-icon icon="exclamation-triangle" class="text-danger"></fa-icon>
                        <small class="text-danger">Max. character limit 500</small>
                    </div>
                    <div class="form-group">
                        <label class="d-block">Custom File Input is currently broken in BS4v3</label>
                        <label class="custom-file" for="file_upload">
                            <input type="file" id="file_upload">
                            <span class="custom-file-control"></span>
                        </label>
                    </div>
                    <div class="row">
                        <fieldset class="col">
                            <legend class="col-form-label">Checkbox</legend>
                            <div class="custom-control custom-control-inline custom-checkbox">
                                <input id="worked" type="checkbox" class="custom-control-input">
                                <label for="worked" class="custom-control-label">Label 1</label>
                            </div>
                            <div class="custom-control custom-control-inline custom-checkbox">
                                <input type="checkbox" class="custom-control-input" checked>
                                <label class="custom-control-label">Label 2</label>
                            </div>
                            <div class="custom-control custom-control-inline custom-checkbox">
                                <input type="checkbox" class="custom-control-input" disabled>
                                <label class="custom-control-label">Disabled</label>
                            </div>
                        </fieldset>
                        <fieldset class="col">
                            <legend class="col-form-label">Radio</legend>
                            <div class="custom-control custom-control-inline custom-radio">
                                <input class="custom-control-input" type="radio" name="inlineRadioOptions"
                                    id="inlineRadio1" value="option1">
                                <label for="inlineRadio1" class="custom-control-label"> Radio 1</label>
                            </div>
                            <div class="custom-control custom-control-inline custom-radio">
                                <input class="custom-control-input" type="radio" name="inlineRadioOptions"
                                    id="inlineRadio2" value="option2" checked>
                                <label class="custom-control-label"> Radio 2</label>
                            </div>
                            <div class="custom-control custom-control-inline custom-radio">
                                <input class="custom-control-input" type="radio" name="inlineRadioOptions"
                                    id="inlineRadio3" value="option3" disabled>
                                <label class="custom-control-label">Disabled Radio</label>
                            </div>
                        </fieldset>
                    </div>
                    <fieldset>
                        <legend class="col-form-label">Toggle Switch</legend>
                        <label class="toggle-switch center-block" for="toggle-switch">
                            <input id="toggle-switch" type="checkbox">
                            <div class="switch-toggle"></div>
                        </label>
                    </fieldset>
                    <div class="form-group">
                        <div ngbDropdown>
                            <a class="h1" ngbDropdownToggle>Big Dropdown Toggle</a>
                            <div ngbDropdownMenu>
                                <a class="dropdown-item">Action - 1</a>
                                <a class="dropdown-item">Another Action</a>
                                <a class="dropdown-item">Something else is here</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
        </form>
    </section>
    <hr>
    <section id="tables">
        <h2>Tables</h2>
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col" width="2%">#</th>
                            <th scope="col">BIG TABLE</th>
                            <th scope="col">Last Name</th>
                            <th scope="col" class="text-right text-sm-left">Username</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td class="text-right text-sm-left">@mdo</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td class="text-right text-sm-left">@fat</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Larry</td>
                            <td>the Bird</td>
                            <td class="text-right text-sm-left">@twitter</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col">
                <table class="table table-sm">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">SMALL TABLE</th>
                            <th scope="col">Last Name</th>
                            <th scope="col" class="text-right text-sm-left">Username</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td class="text-right text-sm-left">@mdo</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td class="text-right text-sm-left">@potato</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Larry the Bird</td>
                            <td>Onion</td>
                            <td class="text-right text-sm-left">@twitter</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <section id="pagination">
        <h2>Pagination</h2>
        <ul class="pagination">
            <li class="page-item"><a class="page-link" href="#">«</a></li>
            <li class="page-item active"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#">4</a></li>
            <li class="page-item disabled"><a class="page-link" href="#">5</a></li>
            <li class="page-item"><a class="page-link" href="#">»</a></li>
        </ul>
    </section>
    <hr>
    <section id="alerts">
        <h2>Alerts</h2>
        <div class="alert alert-primary alert-dismissible" role="alert">
            primary alert with dismiss button
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="alert alert-secondary" role="alert">
            secondary alert
        </div>
        <div class="alert alert-success" role="alert">
            success alert
        </div>
        <div class="alert alert-danger" role="alert">
            danger alert
        </div>
        <div class="alert alert-warning" role="alert">
            warning alert
        </div>
        <div class="alert alert-info" role="alert">
            info alert
        </div>
    </section>
</div>
