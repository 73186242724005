<div id="navigation" data-hook="navigation" [ngClass]="{ opened: open }" class="min-vh-100">
  <div id="scrolly-nav" [ngClass]="{ impersonating: impersonating }" class="row overflow-hidden">
    <div class="col-md-2 border-line nav-wrapper" [ngClass]="{ opened: open }">
      <ul id="app-nav" class="nav">
        <div *ngFor="let routes of appNavigation; let i = index">
          <li class="nav-item">
            <span
              class="position-absolute red-line"
              [ngClass]="{ opened: open }"
              *ngIf="menuService.currentRouteIndex === i"
            ></span>
            <div
              *ngIf="routes && routes.length === 1; else isNestedNav"
              (click)="open = false; triggerGTMEvent(routes[0].name); navigateToRoute(routes[0].path); setSelectedNav(i)"
              [attr.data-nav]="routes[0].navKey"
              data-hook="top-level-nav"
            >
              <div class="nav-icon" [ngClass]="{ active: selectedNav === routes }">
                <a
                  data-hook="app-nav-link"
                  class="nav-link d-inline-flex align-items-center"
                  title="{{ routes[0].name }}"
                  [ngClass]="{ 'is-active': selectedNav === routes }"
                >
                  <fa-icon [fixedWidth]="true" [icon]="getIcon(i)" [title]="routes[0].name"> </fa-icon>
                </a>
                <div class="text-center nav-icon-title">
                  {{ routes[0].nestedNavName }}
                </div>
              </div>
            </div>
            <ng-template #isNestedNav>
              <div (click)="setSelectedNav(i); triggerGTMEvent(routes[0].nestedNavName);  toggleMenu(routes);" [attr.data-nav]="routes[0].nestedNavKey">
                <div
                  class="nav-icon"
                  [ngClass]="{ active: selectedNav === routes }"
                >
                  <a
                    data-hook="app-nav-link"
                    class="nav-link d-inline-flex align-items-center"
                    title="{{ routes[0].nestedNavName }}"
                    [ngClass]="{ 'is-active': selectedNav === routes }"
                  >
                    <fa-icon
                      *ngIf="routes[0].nestedNavName === 'News & Resources'; else icon"
                      [fixedWidth]="true"
                      [icon]="getIcon(i)"
                      [title]="routes[0].nestedNavName"
                      class="fa-rotate-45"
                    >
                    </fa-icon>
                    <ng-template #icon>
                      <fa-icon [fixedWidth]="true" [icon]="getIcon(i)" [title]="routes[0].nestedNavName"> </fa-icon>
                    </ng-template>
                  </a>
                  <div class="text-center nav-icon-title">
                    {{ routes[0].nestedNavName }}
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </div>
      </ul>
    </div>
    <div
      class="col-md-10"
      class="sub-nav-menu  mt-md-2"
      *ngIf="selectedNav && selectedNav.length > 1 && open"
      data-hook="sub-nav-menu"
    >
      <div class="mt-md-3 mb-md-4">
        <fa-icon class="chevron" [icon]="chevronLeftIcon" (click)="clickout()"></fa-icon>
      </div>
      <h4 class="nav-name">{{ selectedNav[0].nestedNavName }}</h4>
      <div>
        <div *ngFor="let subNav of selectedNav" class="my-md-4">
          <a
            class="sub-nav-name"
            (click)="triggerGTMEvent(subNav.name); navigateToRoute(subNav.path);"
            [attr.data-nav]="subNav.navKey"
            data-hook="app-sub-nav"
            [title]="subNav.name"
            >{{ subNav.name }}</a
          >
        </div>
        <a
          *ngIf="selectedNav[0].nestedNavName === 'Tools' && isImpersonator() && !userService.isImpersonating()"
          class="sub-nav-name"
          title="Change User"
          data-hook="app-sub-nav"
          data-nav="change-user"
          (click)="openChangeUserModal(); gtmService.clickEvent({event_name: 'nav', type:'left_nav'}, $event)"
          >Change User
        </a>
        <a
          *ngIf="selectedNav[0].nestedNavName === 'Tools' && userService.isImpersonating()"
          class="sub-nav-name"
          title="Stop Impersonating"
          data-hook="app-sub-nav"
          data-nav="stop-impersonating"
          (click)="openChangeUserModal(); gtmService.clickEvent({event_name: 'nav', type:'left_nav'}, $event)"
        >Stop Impersonating
        </a>
      </div>
    </div>
  </div>
</div>
