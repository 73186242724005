import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'mxc-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
    @Input() dismissable: boolean;
    @Input() title: string;
    @Input() buttons: Array<{
        title: string,
        type: string,
        onClick?: any,
        url?: string,
        single?: boolean
    }>;

    ngOnInit(): void {
    }
}
