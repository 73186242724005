import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetectorService } from '../../shared';

@Component({
  selector: 'mxc-layout-v2',
  templateUrl: './layout-v2.component.html',
  styleUrls: ['./layout-v2.component.scss'],
})
export class LayoutV2Component implements OnInit {

  sideNavToggle = new BehaviorSubject<boolean>(false);
  sideNavOpen = false;
  profileToggle = new BehaviorSubject<boolean>(false);
  profileOpen = false;

  constructor(
      public deviceDetectorService: DeviceDetectorService,
  ) { }

  ngOnInit(): void {
    this.sideNavToggle.subscribe(open => this.sideNavOpen = open);
    this.profileToggle.subscribe(open => this.profileOpen = open);
    this.deviceDetectorService.detectMobile();
  }

}
