import { trigger, transition, animate, style } from '@angular/animations';

export const rollDownAnimation = trigger('rollDownAnimation', [
  transition(':enter', [
    // start
    style({ top: '-400%' }),
    // end
    animate('0.4s ease-out', style({ top: 0 })),
  ]),
  transition(':leave', [
    // start
    style({ top: 0 }),
    // end
    animate('0.4s ease-in', style({ top: '-400%' })),
  ]),
]);
