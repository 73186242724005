import { Component, Input, Output, OnInit, EventEmitter, ViewEncapsulation, HostListener } from '@angular/core';
import { SearchFilters, OptionsObject, AutocompleteLocationItem } from '../../models';
import { DepartmentRegion, DepartmentLabel } from '../../../content/content.model';
import { faUserAlt } from '@fortawesome/pro-light-svg-icons';
import { faUserAlt as faUserAltSolid } from '@fortawesome/pro-solid-svg-icons';
import { AutocompleteLocationService } from '../../services/autocomplete-location.service';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'mxc-scoped-search',
    templateUrl: 'scoped-search.component.html',
    styleUrls: ['scoped-search.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class ScopedSearchComponent implements OnInit {
    @Input() pageTitle: string;
    @Input() selectedDept: string;
    @Input() searchPlaceholder: string;
    @Input() dropdownItems: DepartmentLabel[];
    @Output() dropdownAction: EventEmitter<DepartmentRegion> = new EventEmitter<DepartmentRegion>();
    @Input() searchTerm: string;
    @Output() searchAction: EventEmitter<string> = new EventEmitter<string>();
    @Input() showLocation: boolean;
    @Input() totalResults: string;

    _filters: SearchFilters = {};
    @Input('filters')
    set filters(filters: SearchFilters) {
        const { location: locationFilter, ...remainingFilters } = filters;
        this._filters = this.showLocation ? remainingFilters : filters;
        this.locationSearchTerm = locationFilter ? locationFilter.selected : '';
        this.autocompleteLocationSelection = {
            id: filters.locationId?.selected ?? null,
            city: null,
            state: null,
            country: null,
            displayName: null,
        };
        this.locationQueryLength = this.locationSearchTerm?.length ?? 0;
    }
    get filters(): SearchFilters {
        return this._filters;
    }

    @Output() filterAction: EventEmitter<{
        [key: string]: (string | string[] | number),
    }> = new EventEmitter<{
        [key: string]: (string | string[] | number),
    }>();

    @Output() filterUpdateAction: EventEmitter<{
        [key: string]: (string | string[]),
    }> = new EventEmitter<{
        [key: string]: (string | string[]),
    }>();

    @Input() hasTabs: boolean;
    @Output() tabSelected: EventEmitter<any> = new EventEmitter<any>();

    faUserAlt = faUserAlt;
    faUserSolid = faUserAltSolid;
    active = false;
    selectedFilters = {};
    newsFeed = false;
    location = true;
    locationSearchTerm = '';
    icons = {
        location: {
            icon: 'map-marker-alt',
            classes: '',
        },
        search: {
            icon: faUserAlt,
            classes: '',
        },
    };
    doNotShowFilter = ['locationId'];
    // autocomplete
    autocompleteLocationOptions: AutocompleteLocationItem[];
    autocompleteLocationSelection: AutocompleteLocationItem;
    showAutocompleteLocationList = false;
    isSelectingAutocompleteLocation = false;
    isLocationFocused = false;
    locationQueryLength = 0;
    locationQueryMinLength = 3;
    showAutocompleteLocationError = false;
    showAutocompleteLocationTypo = false;

    constructor (
        private autocompleteService: AutocompleteLocationService,
    ) {
    }

    ngOnInit(): void {
        if (this.pageTitle === 'RE/MAX: News & Resources') {
            this.newsFeed = true;
        }
    }

    selectDropdownItem(selectedItem: DepartmentRegion): void {
        this.clearFilters();
        this.dropdownAction.emit(selectedItem);
    }

    getFilterKeys(): string[] {
        return Object.keys(this.filters);
    }

    selectFilterItem(filterLabel: string, selectedFilter: (string | string[])): void {
        if (selectedFilter === 'true' || selectedFilter === 'false') {
            selectedFilter = selectedFilter === 'true' ? '1' : '0';
        }

        this.selectedFilters[filterLabel] = selectedFilter;
        this.filterUpdateAction.emit(this.selectedFilters);
    }

    submitSearch(searchTerm: string): void {
        this.searchAction.emit(searchTerm);
    }

    locationFocused(): void {
        this.setIconState(true, 'location');
        this.isLocationFocused = true;
        this.checkCanShowAutocomplete();
        this.showAutocompleteLocationError = false;
    }

    locationBlurred(val?: string): void {
        this.setIconState(false, 'location');
        this.isLocationFocused = false;
        this.locationQueryLength = val?.length ?? 0;
        this.checkCanShowAutocomplete();
        this.locationErrorMessage();
    }

    locationErrorMessage(): void {
        // use a slight delay to avoid showing error when selecting from the autocomplete list
        setTimeout(() => {
            if (this.isLocationFocused) {
                this.showAutocompleteLocationError = false;
                return;
            }
            if (!this.locationQueryLength) {
                this.showAutocompleteLocationError = false;
                return;
            }
            if (this.locationQueryLength >= this.locationQueryMinLength && this.autocompleteLocationSelection?.id) {
                this.showAutocompleteLocationError = false;
                return;
            }
            this.showAutocompleteLocationError = true;
        }, 300);
    }

    checkCanShowAutocomplete(): void {
        if ( this.locationQueryLength >= this.locationQueryMinLength && this.autocompleteLocationOptions?.length &&
            (this.isLocationFocused || this.isSelectingAutocompleteLocation)
        ) {
            this.showAutocompleteLocationList = true;
            return;
        }
        this.showAutocompleteLocationList = false;
    }

    processLocationQuery(value): void {
        this.locationQueryLength = value.length;
        if (this.locationQueryLength < this.locationQueryMinLength) {
            this.checkCanShowAutocomplete();
            return;
        }
        this.autocompleteService.getAutocompleteLocationOptions(value).pipe(
            switchMap(res => this.autocompleteService.getAutocompleteLocationOptions(value)),
        ).subscribe(res => {
            this.autocompleteLocationOptions = res.data;
            if (!res.data.length) {
                this.showAutocompleteLocationTypo = true;
            } else {
                this.showAutocompleteLocationTypo = false;
            }
            this.checkCanShowAutocomplete();
        });
    }

    autocompleteSelected(selection: AutocompleteLocationItem): void {
        this.autocompleteService.autocompleteLocationSelection(selection);
        this.locationSearchTerm = selection.displayName;
        this.autocompleteLocationSelection = selection;
        this.showAutocompleteLocationList = false;
        this.showAutocompleteLocationError = false;
        this.filterAction.emit({ location: selection.displayName, locationId: selection.id });
    }

    setIconState(focused: boolean, iconHook: string, newIcon = null): void {
        const icon = this.icons[iconHook];
        icon.classes = focused ? icon.classes + ' active-icon' : icon.classes.replace('active-icon', '');
        if (newIcon) {
            icon.icon = newIcon;
        }
    }

    submitFilters(): void {
        this.filterAction.emit(this.selectedFilters);
    }

    clearFilters(): void {
        Object.keys(this.selectedFilters).forEach((filter) => {
            this.selectedFilters[filter] = null;
        });
        this.filterAction.emit(this.selectedFilters);
    }

    clearLocationSearch(): void {
        this.locationSearchTerm = null;
        this.autocompleteLocationOptions = null;
        this.autocompleteLocationSelection = null;
        this.filterAction.emit({location: null, locationId: null});
    }

    clearSearch(): void {
        this.searchAction.emit();
    }

    setSelectValue(filterOption: string | OptionsObject): string | number {
        return typeof filterOption === 'string' ? filterOption : filterOption.value;
    }

    selectTab(postTypes: string[]): void {
        this.tabSelected.emit(postTypes);
    }
}
