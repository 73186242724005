<div class="modal-body text-center" data-hook="change-user-modal">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()" data-hook="cancel-button">
        <fa-icon icon="times" title="Close Modal"></fa-icon>
    </button>
    <div *ngIf="!impersonating">
        <h1 class="modal-title sr-only">Change User</h1>
        <form [formGroup]="changeUserForm" (ngSubmit)="submitForm()" novalidate>
            <div class="form-group">
                <label for="username" class="sr-only">RE/MAX Email</label>
                <input formControlName="username" class="form-control" id="username" type="text"
                    placeholder="Enter RE/MAX email" data-hook="username" required>
            </div>
            <button type="submit" class="btn btn-primary" data-hook="submit-button">Change
                User</button>
        </form>
    </div>
    <div *ngIf="impersonating">
        <h1 class="modal-title sr-only">You are currently impersonating</h1>
        <label for="revert" class="sr-only">Stop Impersonating</label>
        <span class="modal-title h3">You cannot change user while impersonating</span>
        <button type="button" id="revert" (click)="revertUser()" class="btn btn-primary mt-3 rounded"
            data-hook="revert-button">
            Stop
            impersonating</button>
    </div>
</div>