import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TabService } from '../../../shared/services/tab.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'mxc-content-tab-nav',
  templateUrl: './content-tab-nav.component.html',
  styleUrls: ['./content-tab-nav.component.scss'],
})
export class ContentTabNavComponent implements OnInit {
  @Output() tabSelected: EventEmitter<any> = new EventEmitter<any>();

  currentUrl: string;
  tabs = [
    {
      name: 'All',
      postTypes: 'Post Type',
      active: false,
      path1: '/content',
      path2: '/content?page=1',
      badPath1: 'type=package',
      badPath2: 'type=media',
      badPath3: 'type=article',
      badPath4: 'type=event',
    },
    {
      name: 'News',
      postTypes: ['article', 'event'],
      active: false,
      path1: 'type=article',
      path2: 'type=event',
    },
    {
      name: 'Resources',
      postTypes: ['media', 'package'],
      active: false,
      path1: 'type=package',
      path2: 'type=media',
    },
  ];

  constructor(public tabService: TabService, private router: Router, private activatedRoute: ActivatedRoute,) {}

  ngOnInit(): void {
    // check the url and set the tab that matches it
    // include statements are for 'All' as it can contain a dept and page=1
    this.currentUrl = this.router.url;
    this.tabs.forEach(tab => {
      if (
        this.currentUrl.includes(tab.path1) ||
        this.currentUrl.includes(tab.path2) &&
        !this.currentUrl.includes(tab.badPath1) &&
        !this.currentUrl.includes(tab.badPath2) &&
        !this.currentUrl.includes(tab.badPath3) &&
        !this.currentUrl.includes(tab.badPath4)
      ) {
        tab.active = true;
        // We don't want to run the select tab if we are selecting
        // the All tab, it will remove the urlParams
        if (tab.name !== 'All') {
          this.selectTab(tab.postTypes, tab.name);
        } else {
          this.tabService.currentContentTab = 'All';
        }
      }
    });
  }

  setActiveTab(name: string): void {
    this.tabs.forEach(tab => {
      if (tab.name === name) {
        tab.active = true;
        this.tabService.currentContentTab = name;
      } else {
        tab.active = false;
      }
    });
  }

  selectTab(postTypes: string[] | string, name: string): void {
    this.tabService.currentContentTab = name;
    this.setActiveTab(name);
    this.tabSelected.emit(this.getPostTypesToEmit(postTypes));
  }

  getPostTypesToEmit(postTypes: string[] | string): string[] | string {
    const existingParam = this.activatedRoute.snapshot.queryParamMap.get('type') || false; 
    if (existingParam) {
      return postTypes === existingParam || postTypes.includes(existingParam) ? existingParam : postTypes;
    }
    return postTypes;
  }
}
