import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mxc-modal-v2',
  templateUrl: './modal-v2.component.html',
  styleUrls: ['./modal-v2.component.scss']
})
export class ModalV2Component implements OnInit {
  @Input() dismissable: boolean;
  @Input() title: string;
  @Input() buttons: Array<{
      title: string,
      type: string,
      onClick?: any,
      url?: string,
      single?: boolean
  }>;

  ngOnInit(): void { }
}
