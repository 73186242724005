import { Injectable } from '@angular/core';
import * as OktaAuth from '@okta/okta-auth-js';
import { environment } from 'environments/environment';

@Injectable()
export class OktaService {
    authClient: OktaAuth = new OktaAuth({
        url: environment.OKTA_URL,
        redirectUrl: environment.SITE_URL,
    });

    getAuthClient(): OktaAuth {
        return this.authClient;
    }
}
