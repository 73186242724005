import { Component, Input, OnInit, OnDestroy, Inject, HostListener } from '@angular/core';
import { GoogleAnalyticsEventsService } from '../../../shared/services/google-analytics-events.service';
import { Banner } from '../../models/banner.model';
import { Router } from '@angular/router';
import { fadeInAnimation } from '../../animation';
import { DeviceDetectorService } from '../../services';

@Component({
    selector: 'mxc-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    animations: [fadeInAnimation],
})
export class BannerComponent implements OnInit, OnDestroy {
    @Input() banners: Banner[] = [];
    // time of rotation (seconds)
    @Input() rotationTime = 5;
    // how many banners can be showed
    @Input() rotationNum = 10;

    @Input() user?: number;

    activeBanner: Banner;
    newWindow: boolean;

    timer: any = null;
    cursor = 0;
    mobile = false;

    constructor(
        private router: Router,
        @Inject(Window) private window: Window,
        private gaService: GoogleAnalyticsEventsService,
        private deviceDetector: DeviceDetectorService,
    ) {
    }

    @HostListener('window:resize', ['$event'])
    onresize(event: any): void {
        this.deviceDetector.setViewport();
    }

    ngOnInit(): void {
        this.deviceDetector.getViewport().subscribe((value) => {
            this.checkMobile(value);
        });
        // display the first one
        if (this.banners.length >= 1) {
            this.displayBanner();
            this.presentTriggerAnalytics();
        }
        // rotate
        if (this.banners.length >= 2 && this.rotationNum >= 2) {
            this.timer = this.window.setInterval(() => {
                this.displayBanner();
            }, Math.max(3000, this.rotationTime * 1000));
        }

    }

    ngOnDestroy(): void {
        if (this.timer) {
            this.window.clearInterval(this.timer);
        }
    }

    checkMobile(value): void {
        this.mobile = value < 992;
    }

    displayBanner(): void {
        this.activeBanner = this.banners[this.cursor % Math.min(this.banners.length, this.rotationNum)];
        this.isNewWindow();
        this.cursor++;
    }

    isNewWindow(): void {
        this.newWindow = (!this.activeBanner.url
            || (this.activeBanner.url && this.activeBanner.url.indexOf(this.window.location.origin) === 0)) ? false : true;
    }

    navigateToLink(event: any): void {
        this.openTriggerGA();
        if (this.activeBanner.url) {
            if (!this.newWindow) {
                event.preventDefault();
                this.router.navigateByUrl(this.activeBanner.url.replace(this.window.location.origin, ''));
            }
        } else {
            event.preventDefault();
        }
    }
    closeBanner(): void {
        this.closeTriggerGA();
        this.banners.length = null;
    }

    openTriggerGA(): void {
        this.gaService.emitEvent('Banner Opened', this.activeBanner.title, {
            userId: this.user,
            bannerId: this.activeBanner.id,
            bannerTitle: this.activeBanner.title,
        });

        this.window.analytics.track('Banner URL Opened', {
            banner_id: this.activeBanner.id,
            button_url: this.activeBanner.url,
            button_text: this.activeBanner.buttonLabel,
            type: this.activeBanner.type,
            banner_text: this.activeBanner.title,
          });
    }

    presentTriggerAnalytics(): void {
        this.window.analytics.track('Banner Presented', {
            banner_id: this.activeBanner.id,
            button_url: this.activeBanner.url,
            button_text: this.activeBanner.buttonLabel,
            type: this.activeBanner.type,
            banner_text: this.activeBanner.title,
        });
    }

    closeTriggerGA(): void {
        this.gaService.emitEvent('Banner Closed', this.activeBanner.title, {
            userId: this.user,
            bannerId: this.activeBanner.id,
            bannerTitle: this.activeBanner.title,
        });

        this.window.analytics.track('Banner Closed', {
            banner_id: this.activeBanner.id,
            button_url: this.activeBanner.url,
            button_text: this.activeBanner.buttonLabel,
            type: this.activeBanner.type,
            banner_text: this.activeBanner.title,
          });
    }
}
