import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injector } from '@angular/core';
import { UserService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  userService: UserService;
  constructor(private inj: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.userService = this.inj.get(UserService);

    const jwt = this.userService.authTokens?.token;

    return next.handle(req.clone({
      setHeaders: {
        Authorization: `Bearer ${jwt}`,
      },
    }));
  }
}
