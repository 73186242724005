<div id="eula-content" *ngIf="!userSelection" data-hook="eula-modal">
  <p class="h3">
    By clicking "Accept" you agree to the terms of use, and you consent
    to receive commercial electronic messages regarding news, updates,
    promotions, business opportunities and other related communications
    from RE/MAX LLC, its affiliates, and third parties designated by
    RE/MAX, LLC. You can unsubscribe at any time. View the user agreement
    <a routerLink="/user-agreement" data-hook="eula-link" target="_blank">
      here </a>.
  </p>
  <div class="row justify-content-end">
    <div class="form-group col-8 d-flex justify-content-end">
      <button class="btn btn-link mr-3" (click)="declineEula()">Decline</button>
      <button class="btn btn-primary" data-hook="eula-accept-btn" type="submit" (click)="acceptEula()"
        [disabled]="!eula">
        Accept
      </button>
    </div>
  </div>
  <p class="h3">
    RE/MAX, LLC 5075 S. Syracuse Street Denver, CO 80237, 303-770-5531
  </p>
</div>

<div *ngIf="userSelection === 'accept'" class="text-center" data-hook="eula-accepted-modal">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()" data-hook="cancel-button">
    <fa-icon icon="times" title="Close Modal"></fa-icon>
  </button>
  <fa-icon icon="check-circle" class="fa-5x text-success position-relative mb-3"></fa-icon>
  <p class="h2 text-muted">
    You have successfully accepted the new user agreement.
  </p>
  <p class="text-muted">You are being re-directed to MAX/Center</p>
</div>

<div *ngIf="userSelection === 'decline'" class="text-center" data-hook="eula-declined-modal">
  <fa-icon icon="exclamation-triangle" class="fa-5x text-danger position-relative mb-3"></fa-icon>
  <p class="h2 text-muted">
    You have declined the legal agreement. You will not have access to
    MAX/Center unless you accept
  </p>
  <button class="btn btn-primary" (click)="backToEula()">Back</button>
</div>
