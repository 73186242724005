import { Injectable } from '@angular/core';
import { Subject, Observable, throwError, of } from 'rxjs';

import { Notification, Errors, ToasterValidationMessage } from '../models';

@Injectable({
    providedIn: 'root',
})
export class ToasterService {
    private notification = new Subject<Notification>();

    getNotification(): Observable<Notification> {
        return this.notification.asObservable();
    }

    removeNotification(): void {
        this.setNotification({ messages: [undefined], type: null });
    }

    setNotification(notification: Notification): void {
        this.notification.next(notification);
    }

    handleErrors(err: Errors, display: boolean = true): Observable<any> {
        if(display){
            this.setNotification({ messages: [(err.error && err.error.data || err.message)], type: 'danger' });
        }
        return throwError(err);
    }

    handleResponse(res): Observable<any> {
        this.setNotification({ messages: [res.data], type: 'success' });
        return of(res);
    }
}
