import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "highlighter",
})
export class HighlighterPipe implements PipeTransform {
  transform(value: string, searchString: any, phraseSearch: boolean = false, clearSearch: boolean = false): string {
    if (!searchString || clearSearch) return value;
    if (!value) return '';
    if (phraseSearch) {
      const re = new RegExp("\\b(" + searchString + "\\b)", "igmu");
      value = value.replace(re, '<span class="highlighted-text">$1</span>');
    } else {
      searchString = searchString.includes(" ")
        ? searchString + "|" + searchString.replace(" ", "|")
        : searchString;
      const re = new RegExp(`(?:${searchString})`, "igmu");
      value = value.replace(re, '<span class="highlighted-text">$&</span>');
    }

    return value;
  }
}
