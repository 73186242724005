import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { PostTileComponent } from "./post-tile.component";
import { HighlighterPipe, SharedModule } from "../../../shared";

// font-awesome imports
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faBookOpen, faFolder } from "@fortawesome/free-solid-svg-icons";

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [PostTileComponent],
  providers: [],
  declarations: [PostTileComponent],
})
export class PostComponentModule {
  constructor(public library: FaIconLibrary) {
    library.addIcons(faBookOpen, faFolder);
  }
}
