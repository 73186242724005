import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ToasterService } from './toaster.service';
import { AutocompleteLocationResponse, AutocompleteLocationItem } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteLocationService {

  constructor(
      private http: HttpClient,
      private toaster: ToasterService,
  ) { }

  getAutocompleteLocationOptions(query: string): Observable<AutocompleteLocationResponse> {
      if (query && query.length < 3) {
        return;
      }
      return this.http.get(`${environment.API_URL}/autocomplete/location?query=${query}`, {})
      .pipe(
          catchError(err => this.toaster.handleErrors(err)),
          map(res => {
            return res;
          }),
      );
  }

  autocompleteLocationSelection(selection: AutocompleteLocationItem): void {
        this.http.get(`${environment.API_URL}/autocomplete/selected?id=${selection.id}`, {}).subscribe();
  }
}
