import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services';
import { UserProfile } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProfileOfficeGuard implements CanActivate {
  user: UserProfile;
  constructor(private userService: UserService) {
    userService.getMe().subscribe(usr => {
      this.user = usr;
    });
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canDisplayProfileMenuOption();
  }

  canDisplayProfileMenuOption(): boolean {
    return !!this.user.groupAuthorization?.length;
  }
}
