<div [ngClass]="{'mxc-modal': true, 'blurred' : !dismissable}">
    <div class="mxc-modal--content" data-hook="mxc-modal-v2-container">
        <div class="mxc-modal--content--container">
            <h3>{{title}}</h3>
            <div class="mxc-modal--content--body">
                <ng-content></ng-content>
            </div>
            <div class="mxc-modal--content--buttons--wrapper">
                <div class="mxc-modal--content--buttons">
                    <a *ngFor="let button of buttons" [href]="button.url||null" target="_blank" (click)="button.onClick? button.onClick($event) : null" [ngClass]="{'mxc-modal-button': true, 'secondary': button.type === 'secondary', 'single': button.single}" [title]="button.title">
                        {{button.title}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>