import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'mxc-pw-requirements',
    templateUrl: './pw-requirements.component.html',
})
export class PwRequirementsComponent implements OnChanges {
    @Input() passwordToCheck = '';
    @Input() userName = '';
    @Input() oktaError = '';
    checkLength;
    checkUpperCase;
    checkLowerCase;
    checkNum;
    checkSpecial;
    checkForbidden;
    checkUserName;

    sanitizedUserName;

    ngOnChanges(): void {
        this.checkLength = /^.{8,}$/.test(this.passwordToCheck);
        this.checkUpperCase = /[A-Z]/.test(this.passwordToCheck);
        this.checkLowerCase = /[a-z]/.test(this.passwordToCheck);
        this.checkNum = /[0-9]/.test(this.passwordToCheck);
        this.checkSpecial = /[!|#|$|%|&|'|(|)|*|+|,|\-|.|\/|\\|:|;|<|=|>|?|@|\[|\]|^|_|`|{|\||}|~|"]/.test(this.passwordToCheck);
        this.sanitizedUserName = new RegExp('^((?!' + this.userName + ').)*$', 'igm');
        if (!this.userName) {
            this.checkUserName = 'onSubmit';
        } else {
            this.checkUserName = (this.sanitizedUserName).test(this.passwordToCheck);
        }
    }

}
