import { trigger, transition, animate, style } from '@angular/animations';

export const flyOutAnimationRight = trigger('flyOutAnimationRight', [
    transition(':enter', [
        // start
        style({ right: '-22em' }),
        // end
        animate('0.4s ease-out', style({ right: 0 })),
    ]),
    transition(':leave', [
        // start
        style({ right: 0 }),
        // end
        animate('0.4s ease-in', style({ right: '-22em' })),
    ]),
]);
