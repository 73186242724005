<div id="scoped-search">
  <div id="scoped-search-heading" [ngClass]="{ 'border-bottom-0': newsFeed }">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col">
          <div class="form-group">
            <span id="page-title" data-hook="page-title">{{ pageTitle }}</span>
          </div>
        </div>
        <div class="col-sm-4 col-3 d-flex justify-content-end">
          <ng-content select="[data-hook='roster-view-toggle']"></ng-content>
          <ng-content select="[data-hook='create-short-url-component']"></ng-content>
        </div>
      </div>
    </div>
  </div>

  <div id="scoped-search-searchbar">
    <div class="container-fluid">
      <div class="row pb-2" *ngIf="showLocation">
        <div class="col-12 col-sm-7 col-md-4">
          <div class="row">
            <div class="col-3 pr-0">Search by</div>
            <div class="col-9 text-right pt-1" style="font-size: 0.8rem;" data-hook="autocomplete-errors">
              <div class="text-danger" *ngIf="showAutocompleteLocationError && !showAutocompleteLocationTypo">
                Please select from the suggested search list.
                <fa-icon icon="exclamation-triangle" class="ml-1"></fa-icon>
              </div>
              <div class="text-danger" *ngIf="showAutocompleteLocationTypo">
                No results found, please check spelling.
                <fa-icon icon="exclamation-triangle" class="ml-1"></fa-icon>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="row d-flex flex-row" [ngClass]="{ 'mt-4 mt-md-0': !showLocation }">
        <div class="col d-inline-flex mb-0 w-100" data-hook="search-container">
          <div class="row form-group d-inline-flex w-100 mh-5rem">
            <div *ngIf="showLocation" class="col-12 col-sm-7 col-md-4 search-bar px-0">
              <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <button type="button" class="btn btn-icon pl-3">
                  <fa-icon
                    [icon]="icons.location.icon"
                    class="{{ icons.location.classes }}"
                    title="Location"
                    id="location"
                  ></fa-icon>
                </button>
              </div>
              <input
                [value]="locationSearchTerm"
                data-hook="location-search"
                type="text"
                class="form-control search-bar px-0 my-auto"
                placeholder="City, State (Country) ex: Vail, CO (US)"
                autofocus
                autocomplete="false"
                (focus)="locationFocused()"
                (blur)="locationBlurred($event.target.value)"
                (keyup)="processLocationQuery($event.target.value)"
              />
              <div class="input-group-append" *ngIf="locationSearchTerm">
                <button type="button" class="btn btn-icon" (click)="clearLocationSearch()" data-hook="clear-search">
                  <fa-icon icon="times" title="Clear search"></fa-icon>
                </button>
              </div>
              </div>

              <div class="autocomplete-container py-1" data-hook="autocomplete-location"
                   (mouseover)="isSelectingAutocompleteLocation = true; checkCanShowAutocomplete();"
                   (mouseout)="isSelectingAutocompleteLocation = false; checkCanShowAutocomplete();"
                   *ngIf="showAutocompleteLocationList"
              >
                <div class="autocomplete-options py-1">
                  <div class="autocomplete-item pl-5 py-2"
                       *ngFor="let item of autocompleteLocationOptions"
                       (click)="autocompleteSelected(item)"
                  >
                    {{item.displayName}}
                  </div>
                </div>
              </div>

            </div>
            <div class="d-block d-md-none w-100"></div>
            <div
              class="input-group search-bar input-group-lg px-0"
              [ngClass]="
                showLocation ? 'col-12 col-sm-7 col-md-3 mx-md-4 mt-3 mt-md-0' : 'col-12 col-md-4 col-sm-6 my-auto'
              "
            >
              <div class="input-group-prepend">
                <button type="button" class="btn btn-icon pl-3">
                  <fa-icon
                    *ngIf="showLocation"
                    [icon]="icons.search.icon"
                    class="{{ icons.search.classes }}"
                    title="Search"
                  ></fa-icon>
                  <fa-icon *ngIf="!showLocation" icon="search" title="Search"></fa-icon>
                </button>
              </div>
              <input
                [value]="searchTerm"
                data-hook="search"
                type="text"
                class="form-control search-bar px-0"
                [ngClass]="{ 'my-auto': showLocation }"
                [placeholder]="searchPlaceholder"
                (focus)="setIconState(true, 'search', faUserSolid)"
                (blur)="setIconState(false, 'search', faUserAlt)"
                (change)="submitSearch($event.target.value)"
              />
              <div class="input-group-append" *ngIf="searchTerm">
                <button type="button" class="btn btn-icon" (click)="clearSearch()" data-hook="clear-search">
                  <fa-icon icon="times" title="Clear search"></fa-icon>
                </button>
              </div>
            </div>
            <div *ngIf="showLocation" class="d-block d-md-none w-100"></div>
            <div
              *ngIf="showLocation"
              class="col-6 col-sm-4 col-md-3 text-left text-md-left px-0 mt-4 mt-md-0"
            >
              <button type="button" class="btn btn-primary" data-hook="submit-quick-filters" (click)="submitFilters()">
                Search
              </button>
            </div>
            <div *ngIf="newsFeed" class="col-10 col-sm-5 col-md-3 min-col py-auto px-0 ml-md-5">
              <div class="form-group ml-1">
                <div class="input-group">
                  <div ngbDropdown class="d-inline-block news-feed-dropdown form-control custom-select dropdown">
                    <button
                      id="department-select"
                      type="button"
                      class="text-black"
                      data-hook="dropdown-button"
                      ngbDropdownToggle
                    >
                      {{ selectedDept }}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="department-select" data-hook="department-select">
                      <div *ngFor="let item of dropdownItems">
                        <a
                          *ngIf="item.type === ''"
                          class="dropdown-item"
                          (touchend)="selectDropdownItem(item)"
                          (mouseup)="selectDropdownItem(item)"
                          data-hook="dropdown-item"
                        >
                          <strong>{{ item.name }}</strong>
                        </a>
                        <strong *ngIf="item.departments?.length" class="dropdown-header" data-hook="dropdown-header">
                          {{ item.name }}
                        </strong>
                        <div *ngFor="let subItem of item.departments; let last = last" [ngClass]="{ 'mb-3': last }">
                          <a
                            class="dropdown-item"
                            (touchend)="selectDropdownItem(subItem)"
                            (mouseup)="selectDropdownItem(subItem)"
                            data-hook="dropdown-sub-item"
                          >
                            <span class="ml-4">{{ subItem.name }}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="search-filter-toggle"
              class="d-inline-flex ml-sm-0 ml-md-0 ml-auto"
              [ngClass]="showLocation ? 'col-3 col-sm-3 col-md-6 pl-0 mt-3' : 'col-2 col-md-1 mb-md-2 mb-sm-3 col-sm-1'"
              data-hook="filter-container"
              *ngIf="getFilterKeys().length"
            >
              <button
                type="button"
                class="btn btn-icon px-auto mb-auto mt-0 mt-sm-2 mt-md-0"
                (click)="active = !active"
                data-hook="search-filter-toggle"
              >
                <fa-icon icon="sliders-h" [ngClass]="{ active: active }" aria-hidden="true"></fa-icon>
              </button>
              <span *ngIf="showLocation" class="pl-2 my-auto">Advanced</span>
            </div>
            <div *ngIf="newsFeed" class="mt-3 mt-md-0 d-flex flex-row ml-md-auto content-tab-container">
              <span>
                <mxc-content-tab-nav (tabSelected)="selectTab($event)"></mxc-content-tab-nav>
              </span>
              <div class="d-flex d-md-none ml-auto mr-4 my-auto">
                <div *ngIf="totalResults" class="total-results">{{ totalResults }} Results</div>
              </div>
            </div>
            <div
              id="search-nav-tab-wrapper"
              data-hook="tab-container"
              *ngIf="hasTabs"
              class="col-12 col-md-6 text-center text-sm-right order-last col-animation min-col mt-3 mt-md-0"
              [ngClass]="{ 'col-sm-2 col-12': newsFeed }"
            >
              <ng-content select="[data-hook='roster-nav-tabs']"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="getFilterKeys().length">
    <div id="scoped-search-filters" class="" [ngClass]="{ show: active }" data-hook="scoped-search-filters">
      <div class="container-fluid">
        <div class="row justify-content-end">
          <ng-template ngFor let-filterLabel [ngForOf]="getFilterKeys()">
            <div
              class="col-sm-4 col-md-3"
              [ngSwitch]="filters[filterLabel].type"
              *ngIf="(!filters[filterLabel].options || filters[filterLabel].options.length > 1) && !doNotShowFilter.includes(filterLabel)"
            >
              <!-- toggle switch -->
              <div class="form-group" *ngSwitchCase="'checkbox'">
                <label class="toggle-switch" [for]="filterLabel">
                  <input
                    data-hook="filter--checkbox"
                    [id]="filterLabel.replace(' ', '')"
                    type="checkbox"
                    [checked]="filters[filterLabel].selected === '1'"
                    (change)="selectFilterItem(filterLabel, $event.target.checked.toString())"
                  />
                  <div class="switch-toggle"></div>
                  <span class="toggle-label right">
                    {{ filters[filterLabel].label || filterLabel }}
                  </span>
                </label>
              </div>
              <!-- text input -->
              <div class="form-group" *ngSwitchCase="'text'">
                <label [for]="filterLabel" class="sr-only">
                  {{ filterLabel }}
                </label>
                <input
                  data-hook="filter--text"
                  class="form-control"
                  [id]="filterLabel.replace(' ', '')"
                  type="text"
                  [placeholder]="filters[filterLabel].label || filterLabel"
                  [value]="filters[filterLabel].selected"
                  (change)="selectFilterItem(filterLabel, $event.target.value)"
                  (keydown)="selectFilterItem(filterLabel, $event.target.value)"
                  (keydown.enter)="submitFilters()"
                />
              </div>
              <!-- multi select input -->
              <div class="form-group" *ngSwitchCase="'multiselect'">
                <div ngbDropdown class="custom-select" autoClose="outside" container="body">
                  <button data-hook="filter--multi-select-label" type="button" [id]="filterLabel" ngbDropdownToggle>
                    {{ filters[filterLabel].label || filterLabel }}
                  </button>
                  <mxc-multi-select
                    data-hook="filter--multi-select"
                    [filter]="filters[filterLabel]"
                    (selectionUpdated)="selectFilterItem(filterLabel, $event)"
                    ngbDropdownMenu
                  ></mxc-multi-select>
                </div>
              </div>
              <!-- select input -->
              <div class="form-group" *ngSwitchDefault>
                <label [for]="filterLabel" class="sr-only">
                  {{ filterLabel }}
                </label>
                <select
                  data-hook="filter--select"
                  class="form-control custom-select"
                  [id]="filterLabel.replace(' ', '')"
                  (change)="selectFilterItem(filterLabel, $event.target.value)"
                >
                  <option
                    *ngFor="let filterOption of filters[filterLabel].options"
                    [selected]="filters[filterLabel].selected === (filterOption?.value || filterOption)"
                    [value]="setSelectValue(filterOption)"
                  >
                    {{ filterOption.key || filterOption }}
                  </option>
                </select>
              </div>
            </div>
          </ng-template>
          <div class="col-sm text-center text-sm-right">
            <div class="form-group">
              <button data-hook="clear-quick-filters" type="button" class="btn btn-info mr-3" (click)="clearFilters()">
                Clear
              </button>
              <button
                data-hook="submit-quick-filters"
                type="button"
                class="btn btn-primary"
                (click)="submitFilters()"
                *ngIf="!showLocation"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
