import { Component, OnInit, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CountdownEvent } from 'ngx-countdown';
import { UserService } from '../..';

@Component({
  selector: 'mxc-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  currentDate: any =  moment().utc();
  targetDate: any = this.userService.getExpirationAlertTime();
  notify: string = '';

  constructor(
    public userService: UserService,
    public router: Router,
  ) { }

  ngOnInit(): void { }

  handleEvent(e: CountdownEvent) {
    if(e.action === 'done') {
      this.userService.deauthenticate();
      this.router.navigate(['/login']);
    }
  }
}
