<div class="text-left">
  <div class="alert alert-danger mt-3" *ngIf="oktaError">
    {{ oktaError }}
  </div>
  <ul class="list-unstyled"><label><strong>{{ 'components.passwordRequirements.title' | translate }}</strong></label>
    <li [ngClass]="{'text-danger' : !checkLength,'text-success' :checkLength}" data-hook="password-check-character-length">
      <fa-icon *ngIf="checkLength" icon="check-circle" class="pr-2"></fa-icon>
      <fa-icon *ngIf="!checkLength" icon="times-circle" class="pr-2"></fa-icon>
      <strong>{{ 'components.passwordRequirements.labels.length' | translate }}</strong>
    </li>
    <li [ngClass]="{'text-danger' : !checkUpperCase,'text-success' :checkUpperCase}" data-hook="password-check-uppercase">
      <fa-icon *ngIf="checkUpperCase" icon="check-circle" class="pr-2"></fa-icon>
      <fa-icon *ngIf="!checkUpperCase" icon="times-circle" class="pr-2"></fa-icon>
      <strong>{{ 'components.passwordRequirements.labels.uppercase' | translate }}</strong>
    </li>
    <li [ngClass]="{'text-danger' : !checkLowerCase,'text-success' :checkLowerCase}" data-hook="password-check-lowercase">
      <fa-icon *ngIf="checkLowerCase" icon="check-circle" class="pr-2"></fa-icon>
      <fa-icon *ngIf="!checkLowerCase" icon="times-circle" class="pr-2"></fa-icon>
      <strong>{{ 'components.passwordRequirements.labels.lowercase' | translate }}</strong>
    </li>
    <li [ngClass]="{'text-danger' : !checkNum,'text-success' :checkNum}" data-hook="password-check-number">
      <fa-icon *ngIf="checkNum" icon="check-circle" class="pr-2"></fa-icon>
      <fa-icon *ngIf="!checkNum" icon="times-circle" class="pr-2"></fa-icon>
      <strong>{{ 'components.passwordRequirements.labels.number' | translate }}</strong>
    </li>
    <li [ngClass]="{'text-danger' : !checkSpecial,'text-success' :checkSpecial}" data-hook="password-check-character">
      <fa-icon *ngIf="checkSpecial" icon="check-circle" class="pr-2"></fa-icon>
      <fa-icon *ngIf="!checkSpecial" icon="times-circle" class="pr-2"></fa-icon>
      <strong>{{ 'components.passwordRequirements.labels.symbols' | translate }} 
        <div class="ml-4">" ! # $ % & ’ ( ) * + , - . / \ : ; < = > ? @ [ ] ^ _ ` &#123; | &#125; ~</div>
      </strong>
    </li>
    <li class="text-muted">
      <p class="font-italic mb-0">{{ 'components.passwordRequirements.labels.username' | translate }}</p>
    </li>
    <li class="text-muted">
      {{ 'components.passwordRequirements.labels.firstName' | translate }}
    </li>
    <li class="text-muted">
      {{ 'components.passwordRequirements.labels.lastName' | translate }}
    </li>
    <li class="text-muted">
      <p class="font-italic">{{ 'components.passwordRequirements.labels.previousPassword' | translate }}</p>
    </li>
  </ul>
</div>
