import {
    Component,
    OnInit,
    ViewChild,
    TemplateRef,
    Inject,
    ElementRef,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {
    Auth,
    Errors,
    ErrorsMessaging,
    OktaService,
    UserService,
    ToasterService,
    FormComponent,
} from '../shared';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends FormComponent implements OnInit {
    @ViewChild('eulaModal', { static: false }) modal: TemplateRef<any>;
    @ViewChild('emailInput', { static: false }) email: ElementRef;
    @ViewChild('loginButton', { static: false }) loginButton: ElementRef;
    errors: Errors = new Errors();
    isSubmitting = false;
    invalidEmailError: string = '';
    loginEmail: string = '';
    loginPassword: string = '';
    emailStatus: string = '';
    deepLink: string;
    eulaModal: NgbModalRef;
    emailError: string = '';
    loginError: string = '';

    @ViewChild('t', { static: true }) public tooltip: NgbTooltip;

    errorHandling = {
        520: (err: ErrorsMessaging) => this.redirectToReactivate(err),
        530: (err: ErrorsMessaging) => this.displayErrorMessaging(err.data),
        default(): null {
            return null;
        },
    };

    constructor(
        private fb: FormBuilder,
        private oktaService: OktaService,
        private router: Router,
        private toasterService: ToasterService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private meta: Meta,
        private title: Title,
        private modalService: NgbModal,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(Window) private window: Window
    ) {
        super();
        this.meta.updateTag({
            name: 'description',
            content: 'MAX/Center Login Page',
        });
        this.title.setTitle('MAX/Center: Login');
    }

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe(params => {
            this.deepLink = params.get('fromURI');
        });

        this.translate.get('login.errors.invalidEmail').subscribe(value => {
            this.invalidEmailError = value;
        });
    }

    redirectToReactivate({ name, userId, data }: ErrorsMessaging): void {
        this.displayErrorMessaging(data);
        this.router.navigate([`/register/reactivate/${userId}`, { name }]);
    }

    displayErrorMessaging(data: string): void {
        this.toasterService.setNotification({
            type: 'danger',
            messages: [data],
        });
    }

    openEulaModal(): void {
        this.eulaModal = this.modalService.open(this.modal, {
            centered: true,
            backdrop: 'static',
            keyboard: false,
        });
    }

    submit(e: Event): void {
        e.preventDefault();
        this.isSubmitting = true;
        this.errors = new Errors();

        this.userService
            .attemptAuth({
                email: this.loginEmail,
                password: this.loginPassword,
            })
            .subscribe(
                (res: Auth) => {
                    if (!this.deepLink) {
                        this.deepLink = `${location.protocol}//${
                            location.host
                        }/${
                            !res.features
                                ? 'apps'
                                : res.features.filter(f => f.slug === 'home')
                                ? 'home'
                                : 'apps'
                        }`;
                    }
                    localStorage.setItem('deepLink', this.deepLink);
                    if (
                        res.eulaAccepted ||
                        this.userService.isImpersonating()
                    ) {
                        this.oktaService
                            .getAuthClient()
                            .session.setCookieAndRedirect(
                                res.sessionToken,
                                this.deepLink
                            );
                    } else if (res.stateToken) {
                        this.router.navigate(['login', res.stateToken]);
                    } else {
                        this.openEulaModal();
                    }
                },
                (err: Errors) => {
                    if (err.status === 403) {
                        this.loginError =
                            'Username/password is incorrect, please try again';
                    } else {
                        const handler =
                            this.errorHandling[err.status] ||
                            this.errorHandling.default;
                        handler(err.error);
                        this.errors = err;
                    }
                    this.isSubmitting = false;
                }
            );
    }

    userAcceptedEula(res): void {
        if (res === 'accepted') {
            this.oktaService
                .getAuthClient()
                .session.setCookieAndRedirect(
                    this.userService.authTokens.sessionToken,
                    this.deepLink
                );
        } else if (res === 'closeAccepted') {
            this.eulaModal.close();
            this.oktaService
                .getAuthClient()
                .session.setCookieAndRedirect(
                    this.userService.authTokens.sessionToken,
                    this.deepLink
                );
        } else if (!this.userService.isImpersonating()) {
            this.eulaModal.close();
            this.eulaError();
        }
    }

    eulaError(): void {
        this.isSubmitting = false;
        this.toasterService.setNotification({
            type: 'danger',
            messages: [
                'You cannot access MAX/Center without accepting the user agreement',
            ],
        });
    }

    onEmailInput(e: any) {
        this.loginError = '';
        this.emailError = '';
        this.loginEmail = e.target.value;
        this.emailStatus = 'default';

        if (this.loginEmail) {
            if (this.emailValid(this.loginEmail)) {
                this.emailStatus = 'success';
            } else {
                this.emailError = this.invalidEmailError;
                this.emailStatus = 'error';
            }
        }
    }

    onPasswordInput(e: any) {
        this.loginError = '';
        this.loginPassword = e.target.value;
    }

    canSubmit(): boolean {
        return !!(
            this.loginEmail &&
            this.emailValid(this.loginEmail) &&
            this.loginPassword
        );
    }

    emailValid(email: string): boolean {
        return (
            email &&
            /[A-z0-9\.\-\_\+]{1,}\@(remax(test)?\.net|remax\.com|booj\.com|gadberry\.com)$/i.test(
                email
            )
        );
    }
}
