import { Component, Input } from '@angular/core';
import { faMobile } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mxc-short-profile',
    templateUrl: 'short-profile.component.html',
    styleUrls: ['./short-profile.component.scss'],
})

export class ShortProfileComponent {
    @Input() name;
    @Input() userId;
    @Input() showFav;
    @Input() isProfile;
    @Input() photo;
    @Input() title;
}
