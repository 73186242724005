import { Component } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    template: '<div>form</div>',
})
export class FormComponent {
    passwordToCheck = '';

    validateInput(form: string, field: string): boolean {
        const fieldToCheck = this[form].get(field);
        const regex = /^[^@]+@(booj\.io)$/;
        if (field === 'ispEmail' && regex.test(fieldToCheck.value)) {
            fieldToCheck.setErrors({'incorrect': true, 'reservedDomain': true});
            return true;
        }
        return fieldToCheck.invalid && fieldToCheck.touched;
    }

    checkInvalidClass(
        form: string,
        field: string,
    ): { 'is-invalid': boolean; 'is-valid': boolean } {
        const isInputInvalid = this.validateInput(form, field);
        const fieldToCheck = this[form].get(field);

        return {
            'is-invalid': isInputInvalid,
            'is-valid': !isInputInvalid && fieldToCheck.touched,
        };
    }

    inputValidator(form: string, field: string, checks: string[]): boolean {
        const fieldToCheck = this[form].get(field);
        for (const check of checks) {
            if (!fieldToCheck[check]) {
                return false;
            }
        }
        return true;
    }

    passwordValidator({ value }: AbstractControl): Observable<any> {
        if (this) {
            this.passwordToCheck = value;
        }
        return of(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*["!#$%&'()*+,\-.\/\\:;<=>?@\[\]^_`{|}~])[A-Za-z\d"!#$%&'()*+,\-.\/\\:;<=>?@\[\]^_`{|}~]{8,}$/.test(value)
        ).pipe(
            map((results: boolean) => (results ? null : { Invalid: { value } })),
        );
    }

    // This is stupid complicated!
    generatePasswordConfirmationValidator(formKey: string): Function {
        return ({ value }: AbstractControl): Observable<any> => {
            if (this[formKey]) {
                return of(this[formKey].get('newPassword').value === value).pipe(
                    map((results: boolean) => (results ? null : { Invalid: true })),
                );
            } else {
                return of(null);
            }
        };
    }
}
