import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";
import {
  ScopedSearchComponent,
  FavoriteComponent,
  ListItemComponent,
  ListViewComponent,
  BannerComponent,
  BackButtonComponent,
} from "./components";
import { ContentTabNavComponent } from "../content/components/content-tab-nav/content-tab-nav.component";
import { MomentModule } from "ngx-moment";
import {
  FavoriteService,
  SharedService,
  BannerService,
  DeviceDetectorService,
  SharedRosterService,
  BlockService,
  EventsService,
  CustomerIoService,
} from "./services";
import { FormsModule } from "@angular/forms";
import {
  PhonePipe,
  ReplacePipe,
  TruncatePipe,
  OfficeNamePipe,
  RemaxRegionPipe,
  PhotoUrlPipe,
  FilterAppCategoryIdPipe,
  TimeAgoPipe,
  FormatDatePipe,
  HighlighterPipe,
} from "./pipes";

// font awesome imports
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import {
  faHeart,
  faSort,
  faSlidersH,
  faSearch,
  faAngleRight,
  faTimes,
  faPlay,
  faMapMarkerAlt,
  faExclamationTriangle,
  faBookmark,
  faBorderAll,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOpen } from "@fortawesome/free-regular-svg-icons";
import { Segment } from "./models";
import { MultiSelectComponent } from "./components/multi-select/multi-select.component";
import { GoogleAnalyticsEventsService } from "./services/google-analytics-events.service";
import { GoogleTagManagerService } from "./services/google-tag-manager.service";
import { PwRequirementsComponent } from "./components/pw-requirements/pw-requirements.component";
import { ZeroStateImgDirective } from "./directive/zero-state-img/zero-state-img.directive";
import { AppImageComponent } from "./components/apps/app-image/app-image.component";
import { SelectComponent } from "./components/form-components/select/select.component";
import { SegmentService } from "./services/segment.service";
import { PostImagePipe } from "./pipes/post-image.pipe";
import { AuthFormComponent } from "./components/form-components/auth-form/auth-form.component";
import { InputComponent } from "./components/form-components/input/input.component";
import { TranslateModule } from "@ngx-translate/core";
import { TimerComponent } from "./components/timer/timer.component";
import { CountdownModule } from "ngx-countdown";

declare global {
  const analytics: Segment;

  interface Window {
    analytics: Segment;
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    FontAwesomeModule,
    MomentModule,
    TranslateModule,
    CountdownModule,
  ],
  declarations: [
    FavoriteComponent,
    ScopedSearchComponent,
    PhonePipe,
    ReplacePipe,
    TruncatePipe,
    OfficeNamePipe,
    RemaxRegionPipe,
    PhotoUrlPipe,
    ListItemComponent,
    ListViewComponent,
    MultiSelectComponent,
    PwRequirementsComponent,
    BannerComponent,
    BackButtonComponent,
    ContentTabNavComponent,
    FilterAppCategoryIdPipe,
    ZeroStateImgDirective,
    AppImageComponent,
    TimeAgoPipe,
    SelectComponent,
    FormatDatePipe,
    PostImagePipe,
    AuthFormComponent,
    InputComponent,
    TimerComponent,
    HighlighterPipe,
  ],
  providers: [
    CookieService,
    FavoriteService,
    BannerService,
    DeviceDetectorService,
    { provide: Window, useValue: window },
    SharedService,
    GoogleAnalyticsEventsService,
    GoogleTagManagerService,
    SharedRosterService,
    BlockService,
    EventsService,
    FormatDatePipe,
    SegmentService,
    HighlighterPipe,
    CustomerIoService,
  ],
  exports: [
    CommonModule,
    FavoriteComponent,
    ScopedSearchComponent,
    RouterModule,
    PhonePipe,
    ReplacePipe,
    TruncatePipe,
    OfficeNamePipe,
    RemaxRegionPipe,
    PhotoUrlPipe,
    ListItemComponent,
    ListViewComponent,
    FontAwesomeModule,
    PwRequirementsComponent,
    BannerComponent,
    BackButtonComponent,
    FilterAppCategoryIdPipe,
    FormatDatePipe,
    ZeroStateImgDirective,
    MultiSelectComponent,
    AppImageComponent,
    TimeAgoPipe,
    SelectComponent,
    AuthFormComponent,
    InputComponent,
    MomentModule,
    PostImagePipe,
    TranslateModule,
    TimerComponent,
    HighlighterPipe,
  ],
})
export class SharedModule {
  constructor(public library: FaIconLibrary) {
    library.addIcons(
      faHeart,
      faHeartOpen,
      faSort,
      faSlidersH,
      faSearch,
      faAngleRight,
      faTimes,
      faPlay,
      faMapMarkerAlt,
      faExclamationTriangle,
      faBookmark,
      faBorderAll,
      faList
    );
  }
}
