import { images } from "./branding";

export const environment = {
  APP_ENV: "prod",
  APP_NAME: "MAX/Center",
  APP_PREFIX: "www",
  API_URL: "https://api.remax.net/api/v1",
  JOIN_API_URL: "https://api.joinremax.com/api/v1",
  BILLING_PAYMENT_URL:
    "https://aoportal.remax.net/PersonifyEbusiness/MyAccount.aspx",
  SITE_URL: "https://remax.net",
  PROFILE_URL: "https://profile.remax.net",
  PHOTO_URL: "//papiphotos.remax-im.com/",
  OKTA_URL: "https://remax.okta.com/",
  IMAGES: images("https://assets.remax.net/static"),
  STATIC_S3_URL: "https://assets.remax.net/static",
  UPLOADS_S3_URL: "https://assets.remax.net",
  JOIN_REMAX_URL: "https://www.joinremax.com",
  TECH_REMAX_URL: "https://tech.remax.com",
  GTM_AUTH: "UA-30723-71",
  SALESFORCE_URL:
    "https://remax.okta.com/home/salesforce-fedid/0oalww7f48iCuJTfV0x7/2551",
  GOOGLE_MAPS_API_KEY: "AIzaSyCw464FoFvIHQ1nx0xpm4T2Xh6VsxNOjwk",
  APP_DEEP_LINKS: {
    CONTACTS: {
      NAME: "Contacts",
      LINK: "https://admin.remax.com/contacts/list",
      APPID: "0oamlvoxtdbjDWl600x7", // booj
      NAV_KEY: "app-contacts",
    },
    EMAIL: {
      NAME: "Campaigns",
      LINK: "https://admin.remax.com/campaigns/dashboard",
      APPID: "0oamlvoxtdbjDWl600x7", // booj
      NAV_KEY: "app-campaigns",
    },
    WEBSITE: {
      NAME: "Website",
      LINK: "https://admin.remax.com/website/dashboard",
      APPID: "0oamlvoxtdbjDWl600x7", // booj
      NAV_KEY: "app-website",
    },
    EDUCATION: {
      NAME: "Education",
      LINK: null,
      APPID: "0oaryyutpvjpjYzIn0x7", // remax university
      NAV_KEY: "app-education",
    },
    SUPPORT: {
      NAME: "Customer Support",
      LINK: null,
      APPID: "0oalww7f48iCuJTfV0x7",
      NAV_KEY: "app-customer-support",
    },
  },
};
