<div class="container-fluid" data-hook="banner-container">
    <div *ngIf="banners.length" class="banner banner-{{activeBanner.type}} align-items-center no-underline row"
        data-hook="banner">
        <a class="d-flex col-12 col-md-9 align-self-stretch" data-hook="banner-link"
            [ngClass]="{'disabled col-md-11 br-1':!activeBanner.url}"
            [attr.href]="activeBanner.url?activeBanner.url:null" [target]="newWindow ? '_blank' : '_self'"
            (click)="navigateToLink($event)" [@fadeInAnimation]>
            <div class="banner-title p-3" data-hook="banner-title">{{ activeBanner.title }}
            </div>
        </a>
        <a *ngIf="activeBanner.url"
            class="d-flex col-8 col-md-2 br-1 align-self-stretch justify-content-center bt-1 bt-md-0"
            data-hook="banner-button" [attr.href]="activeBanner.url?activeBanner.url:null"
            [target]="newWindow ? '_blank' : '_self'" (click)="navigateToLink($event)" [@fadeInAnimation]>
            <div class="m-auto" *ngIf="activeBanner.buttonLabel" [ngClass]="{'text-truncate': mobile}">
                {{activeBanner.buttonLabel | uppercase}}
            </div>
            <div *ngIf="!activeBanner.buttonLabel">
                LEARN MORE
            </div>
        </a>
        <a role="button"
            class="d-flex col-4 col-md-1 btn align-self-stretch justify-content-center bt-1 bt-md-0 rounded-0"
            [ngClass]="{'col-12':!activeBanner.url}" data-hook="banner-close-button" (click)="closeBanner()">
            <div [ngClass]="!activeBanner.url ? 'ml-auto m-md-auto' : 'm-auto'">
                CLOSE
            </div>
        </a>
    </div>
</div>
