<div *ngIf="controls?.body?.length" id="list-table" class="table-responsive">
    <table class="table table-hover" data-hook="list-view-table">
        <thead>
            <tr data-hook="list-view-header">
                <th *ngFor="let head of controls.header; let last = last" class="d-lg-table-cell"
                    [ngClass]="{'d-none':head.hideOnMobile,'text-right':last,'photo-column':head.key == 'imgUrl'}"
                    data-hook="list-view-head" width="10%">
                    <button class="btn btn-icon text-capitalize" (click)="sortBy.emit(head.key)" [disabled]="!head.isSortable" *ngIf="!head.hide">
                        {{head.display || head.key }} &nbsp;
                        <fa-icon icon="sort" *ngIf="head.isSortable"></fa-icon>
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of controls.body; let i = index" [ngClass]="row.rowClass" [class.clickable]="clickable"
                (click)="clickRow.emit(row)" attr.data-hook="{{'list-view-row-' + i }}">
                <td *ngFor="let data of controls.header; let last = last; let k = index;" class="d-lg-table-cell"
                    [ngClass]="{'d-none':data.hideOnMobile, 'text-right':last,'photo-column':data.key == 'imgUrl'}"
                    data-hook="list-view-data">
                    <img *ngIf="data.key === 'imgUrl'; else displayValue" [src]="row[data.key]">
                    <ng-template #displayValue >
                        <span *ngIf="isString(row[data.key]); else elseBlock" [innerHTML]="row[data.key] | highlighter:searchTerm:false:!searchTerm?.length" attr.data-hook="{{'list-view-data-' + data.key}}">{{row[data.key]}}</span>
                        <ng-template #elseBlock>{{row[data.key]}}</ng-template>
                    </ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>
