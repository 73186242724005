import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter, Host } from '@angular/core';
import { RouteObject } from '../menu.model';
import { ChangeUserComponent } from '../../../../shared/change-user/change-user.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from '../menu.component';
import {UserService} from '../../../../../shared/services';

@Component({
  selector: 'mxc-nested-nav',
  templateUrl: './nested-nav.component.html',
  styleUrls: ['../navigation.scss'],
  providers: [MenuComponent],
})
export class NestedNavComponent implements OnInit {
  @Input() title: string;
  @Input() links: RouteObject[];
  @Input() icon: string;
  @Input() navIsOpen: boolean;
  @Input() isImpersonator: boolean;
  @Input() impersonating: boolean;
  @Input() isActive: boolean;
  @Input() index: number;
  @Input() navKey ?: string;
  @Output() closeMenu: EventEmitter<any> = new EventEmitter<any>();
  @Output() openNav: EventEmitter<any> = new EventEmitter<any>();

  isOpen = false;
  isTools = false;
  openModal: NgbModalRef;
  menuComponent: MenuComponent;

  constructor(
      private modalService: NgbModal,
      private userService: UserService,
      private _menuComponent: MenuComponent,
  ) {
    this.menuComponent = _menuComponent;
  }

  ngOnInit(): void {
    this.isTools = this.title === 'Tools';
  }

  close(): void {
    this.closeMenu.emit();
  }

  open(): void {
    this.openNav.emit();
  }

  openChangeUserModal(): void {
    this.openModal = this.modalService.open(ChangeUserComponent);
  }

  getCurrentIcon(): void {
    return this.menuComponent.getIcon(this.index);
  }
}
