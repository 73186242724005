import { Injectable, Inject, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DeviceDetectorService implements OnInit {
    innerWidth: BehaviorSubject<number>;
    isMobile = false;
    mobileWidthThreshold = 992;

    constructor(@Inject(Window) private window: Window) {
        this.innerWidth = new BehaviorSubject<number>(window.innerWidth);
    }

    ngOnInit (): void {
        this.detectMobile();
    }

    // detect user agent
    getAgent(): string {
        const agent = this.window.navigator.userAgent;
        let device = 'all';

        if (agent.match(/iPad/i) || agent.match(/iPhone/i)) {
            device = 'ios';
        } else if (agent.match(/android/i)) {
            device = 'android';
        }
        return device;
    }

    getViewport(): Observable<number> {
        return this.innerWidth.asObservable();
    }

    setViewport(): void {
        this.innerWidth.next(this.window.innerWidth);
    }

    detectMobile(): void {
        this.getViewport().subscribe(value => {
            if (value < this.mobileWidthThreshold) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        });
    }
}
