import { Post } from '../../content/content.model';
import { App } from '../../apps/app.model';
import { Office } from '../../roster/roster.model';
import { User } from '../../shared/models/user.model';

export class FavoriteData {
  apps: App[];
  posts: Post[];
  offices: Office[];
  users: User[];
}

export class FavoriteCache {
  apps: {[key: string]: true};
  posts: {[key: string]: true};
  offices: {[key: string]: true};
  users: {[key: string]: true};
}

export class FavoriteResponse {
  status: 'success';
  data: FavoriteData;
}

export type favoriteType = 'apps' | 'posts' | 'users' | 'offices';

export type favId = string | number;

export type favEndpointType = 'post' | 'app' | 'user' | 'office';

export interface TypeUrlMap {
  [key: string]: favEndpointType;
}
