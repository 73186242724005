import {Component, Input, OnInit, OnDestroy, Output} from '@angular/core';
import { Subscription } from 'rxjs';
import { App } from '../../../apps/app.model';
import { Post} from '../../../content/content.model';
import { Office } from '../../../roster/roster.model';
import { favId, favoriteType, TypeUrlMap, User } from '../../models';
import { FavoriteService, GoogleTagManagerService, UserService } from '../../services';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { SegmentService } from '../../services/segment.service';
@Component({
    selector: 'mxc-favorite',
    templateUrl: './favorite.component.html',
    styleUrls: ['./favorite.component.scss'],
})
export class FavoriteComponent implements OnInit, OnDestroy {
    @Input() type: favoriteType;
    @Input() id: favId;
    @Input() regionId: string;
    @Input() subclassCode: string;
    @Input() user: User;
    @Input() office: Office;
    @Input() post?: Post;
    @Input() app: App;
    @Input() postName: string;
    @Input() officeName: string;
    @Input() appName: string;
    @Input() postType: string;
    @Input() postFeatured: boolean;
    @Input() viewType: string | null;

    cache: { [key: string]: boolean };
    favCacheSubscription: Subscription;

    typeUrlMap: TypeUrlMap = {
        posts: 'post',
        apps: 'app',
        users: 'user',
        offices: 'office',
    };

    constructor(
        private favoriteService: FavoriteService,
        private gaService: GoogleAnalyticsEventsService,
        private userService: UserService,
        public segmentService: SegmentService,
        protected gtmService: GoogleTagManagerService,
    ) {}

    ngOnInit(): void {
        this.favoriteService.getFavorites().subscribe();
        this.favCacheSubscription = this.favoriteService.getFavoriteCache().subscribe((favCache) => {
            this.cache = favCache[this.type];
        });
    }

    ngOnDestroy(): void {
        this.favCacheSubscription.unsubscribe();
    }

    toggleFavorite(e): void {
        e.stopPropagation();
        const type = this.typeUrlMap[this.type];
        if (this.cache && this.cache[this.id]) {
            this.handleUnfavorited(type);

            this.favoriteService.deleteFavorite(this.id, type).subscribe(() => {
                this.cache[this.id] = false;
                this.favoriteService.favoritesUpdate.next(true);
            });
        } else {
            this.handleFavorited(type);
            this.favoriteService.postFavorite(this.id, type).subscribe(() => {
                this.cache[this.id] = true;
                this.favoriteService.favoritesUpdate.next(true);
            });
        }
    }

    handleUnfavorited(type: string): void {
        switch (type) {
            case 'user': {
                this.gaService.emitEvent('User Unfavorited', `${this.id}`, {
                    userId: this.userService.user.id,
                });
                this.segmentService.userViewTypeSubject.next(this.viewType);
                this.segmentService.segmentUserUnfavoritedEvent(this.user);
                break;
            }
            case 'office': {
                this.gaService.emitEvent('Office Unfavorited', `${this.id}`, {
                    userId: this.userService.user.id,
                });
                this.segmentService.officeViewTypeSubject.next(this.viewType);
                this.segmentService.segmentOfficeUnfavoritedEvent(this.office);
                break;
            }
            case 'post': {
                this.gaService.emitEvent('Post Unfavorited', `${this.id}`, {
                    userId: this.userService.user.id,
                });
                this.segmentService.postViewTypeSubject.next(this.viewType);
                this.segmentService.segmentPostUnfavoritedEvent(this.post);
                break;
            }
            case 'app': {
                this.gaService.emitEvent('App Unfavorited', `${this.id}`, {
                    userId: this.userService.user.id,
                });
                this.segmentService.appViewTypeSubject.next(this.viewType);
                this.segmentService.segmentAppUnfavoritedEvent(this.app);
                break;
            }
        }
    }

    handleFavorited(type: string): void {
        switch (type) {
            case 'user': {
                this.gaService.emitEvent('User Favorited', `${this.id}`, {
                    userId: this.userService.user.id,
                });

                this.gtmService.favoriteEvent({
                    event_name: 'add',
                    object_category: 'agent',
                    name: this.user.name
                });

                this.segmentService.userViewTypeSubject.next(this.viewType);
                this.segmentService.segmentUserFavoritedEvent(this.user);
                break;
            }
            case 'office': {
                this.gaService.emitEvent('Office Favorited', `${this.id}`, {
                    userId: this.userService.user.id,
                });

                this.gtmService.favoriteEvent({
                    event_name: 'add',
                    object_category: 'office',
                    name: this.officeName
                });

                this.segmentService.officeViewTypeSubject.next(this.viewType);
                this.segmentService.segmentOfficeFavoritedEvent(this.office);
                break;
            }
            case 'post': {
                this.gaService.emitEvent('Post Favorited', `${this.id}`, {
                    userId: this.userService.user.id,
                });

                this.gtmService.favoriteEvent({
                    event_name: 'add',
                    object_category: 'resource',
                    name: this.postName
                });

                this.segmentService.postViewTypeSubject.next(this.viewType);
                this.segmentService.segmentPostFavoritedEvent(this.post);
                break;
            }
            case 'app': {
                this.gaService.emitEvent('App Favorited', `${this.id}`, {
                    userId: this.userService.user.id,
                });

                this.gtmService.favoriteEvent({
                    event_name: 'add',
                    object_category: 'app',
                    name: this.appName
                });

                this.segmentService.appViewTypeSubject.next(this.viewType);
                this.segmentService.segmentAppFavoritedEvent(this.app);
                break;
            }
        }
    }
}
