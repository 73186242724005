import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mxc-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() name?: string;
  @Input() label?: string;
  @Input() required?: boolean;
  @Input() tabindex?: string;
  @Input() type?: string = 'text';
  @Input() disabled?: boolean = false;
  @Input() error?: string = '';
  @Input() status?: string = 'default';
  @Input() secureTextEntry?: boolean = false;
  @Input() placeholder?: string = '';
  @Input() dataHook?: string = '';

  @Output() onChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  toggleSecureTextEntry()
  {
    if( ! this.type || this.type === 'text' ){
      this.type = 'password'
    }else{
      this.type = 'text';
    }
  }

}
