import { Component } from '@angular/core';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
    selector: 'mxc-header-alt',
    templateUrl: './header-alt.component.html',
    styleUrls: ['./header-alt.component.scss'],
})
export class HeaderAltComponent {
    logoImage: string = environment.IMAGES.logo.primary;
    showWelcomeText = false;

    constructor(router: Router) {
        if (router.url.indexOf('/login') > -1) {
            this.showWelcomeText = true;
        }
    }
}
