import { Component, Input, OnInit, Inject } from '@angular/core';
import { UserService } from '../../services';
import { SegmentService } from '../../services/segment.service';

@Component({
    selector: 'mxc-paywall',
    templateUrl: 'paywall.component.html',
    styleUrls: ['./paywall.component.scss']
})
export class PaywallComponent implements OnInit {  
    @Input() public user: any;

    public displayed: boolean = false;
    public title: string;
    public paymentPortalUrl: string = 'https://aoportal.remax.net/PersonifyEbusiness/MyAccount.aspx';
    public buttons: Array<{
        title: string,
        type: string,
        onClick?: any,
        url?: string,
        single?: boolean
    }>;

    constructor(
        public userService: UserService,
        public segmentService: SegmentService,
        @Inject(Window) private window: Window,
    ){

    }

    ngOnInit(): void {
        this.displayed = (this.user.newBillDueLock || (this.user.newBillDue && ! this.userService.dismissedModal('paywall.bill_due')));
        
        if( ! this.displayed ) return;

        let popupType = '';
        if( this.user.newBillDueLock ){
            this.title = 'Payment is Now Required';
            this.buttons = [{
                title: 'Pay Dues',
                type: 'primary',
                single: true,
                onClick:()=>this.payDues()
            }];

            let body = document.querySelector('body');
            body.style.overflow = 'hidden';
            body.style.height = '100%';

            popupType = 'Payment Required';
        }else{
            this.title = 'Welcome to MAX/Center!';
            this.buttons = [{
                title: 'Pay Dues Later',
                type: 'secondary',
                onClick: ()=>this.dismiss()
            }, {
                title: 'Pay Dues',
                type: 'primary',
                onClick: ()=>this.payDues()
            }];

            popupType = 'Payment Due';
        }

        this.segmentService.track('Popup Shown', {
            popup_type: popupType,
        });
    }

    dismiss()
    {
        this.userService.setDismissedModal('paywall.bill_due');
        this.displayed = false;

        this.segmentService.track('Popup Clicked', {
            cta: 'Pay Dues Later'
        });

        return false;
    }

    payDues()
    {
        this.segmentService.track('Popup Clicked', {
            cta: 'Pay Dues'
        });

        this.window.open(this.paymentPortalUrl, '_blank');
    }
}
