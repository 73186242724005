<div class="mxc-paywall"
    *ngIf="displayed"
>
    <mxc-modal
        [title]="title"
        [buttons]="buttons"
        [dismissable]="!this.user.newBillDueLock"
    >
        <div class="mxc-paywall-content">
            <p *ngIf="this.user.newBillDueLock else billDue">
                It’s time to pay your annual agent dues. To process your payment, click the <b>Pay Dues</b> button below.<br/><br/>
                It has been 30 days since you’ve joined RE/MAX.  Please pay your dues now in order to get access to all the tools and resources in MAX/Center.
            </p>
            <ng-template #billDue>
                <p>
                    <b>All your tools and information in one spot</b><br/>
                    MAX/Center is your exclusive hub for resources, events and what's new.<br/><br/>
                    <b>Step 1: Pay your dues</b><br/>
                    Before you explore, click the Pay Dues button below to pay your annual agent dues.
                </p>
                
            </ng-template>
            
        </div>
        
    </mxc-modal>
    
</div>