<div class="toaster" [ngClass]="{'show' : messages, 'small-banner-style': smallBannerStyle}" data-hook="toaster">
    <div *ngIf="messages">
        <ul class="alert" [ngClass]="alertClass" role="alert" data-hook="toaster-messages">
            <li *ngFor="let message of messages">
                <span *ngIf="smallBannerStyle">
                    <span *ngIf="alertClass === 'alert-success'" class="material-icons success-icon align-middle">check_circle</span>
                    <span *ngIf="alertClass === 'alert-danger'" class="material-icons-outlined danger-icon align-text-bottom">warning_amber</span>
                </span>
                <span [innerHtml]="message" [ngClass]="{'small-banner-message': smallBannerStyle }"></span>
                <button *ngIf="!link && message" type="button" data-hook="toaster-dismiss" class="close"
                    (click)="dismiss()" aria-label="Close" [ngClass]="{'ml-3': smallBannerStyle }">
                    <span *ngIf="smallBannerStyle">
                        <span class="d-none d-sm-block">Dismiss</span>
                        <span class="d-sm-none material-icons align-middle">close</span>
                    </span>
                    <fa-icon *ngIf="!smallBannerStyle" icon="times" title="Dismiss Message"></fa-icon>
                </button>
                <a *ngIf="link && message" data-hook="toaster-link-to" class="btn btn-link link-to"
                    [routerLink]="link.url">{{ link.text }}</a>
            </li>
        </ul>
    </div>
</div>