import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Asset, Post } from '../../../content/content.model';
import { ContentService } from '../../../content/content.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { TabService, UserService } from '../../services';
import { HighlighterPipe } from '../../pipes';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'mxc-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {

  constructor(
    @Inject(Window) private window: Window,
    private contentService: ContentService,
    private gaService: GoogleAnalyticsEventsService,
    private tabService: TabService,
    private router: Router,
    private userService: UserService,
    public highlighter: HighlighterPipe,
    private searchService: SearchService,
  ) { }
  @Input() departmentName: string;
  @Input() thumbnailUrl: string;
  @Input() title: string;
  @Input() date: string;
  @Input() excerpt: string;
  @Input() postType: string;
  @Input() postId: number;
  @Input() post?: Post;
  @Input() postAsset: Asset;
  @Input() parentAsset: Asset;
  @Input() viewType?: string;

    searchTerm: string | undefined = undefined;

    ngOnInit(): void {
        this.searchService.searchTerm.subscribe((term) => {
            this.searchTerm = term;
        });
    }

  downloadAsset(id: number): void {
    this.contentService.getDownload(id).subscribe((res) => {
        this.window.location.assign(res.data);
    });
  }

  navigateToPost(): void {
    this.triggerPostTrackingEvent();
    // store route in service to we can navigate back to it later
    this.tabService.lastUrl = this.router.url;

    this.router.navigate([`/content/${this.postId}`]);
  }

  triggerPostTrackingEvent(): void {
    this.gaService.emitEvent('Post Opened', this.title, {
      userId: this.userService.user.id,
      page: this.router.url,
      featured: false,
    });
  }

}
