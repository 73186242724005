import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'mxc-logout-modal',
    templateUrl: './logout-modal.component.html',
})

export class LogoutModalComponent {

    constructor(
        private userService: UserService,
        private router: Router,
        private activeModal: NgbActiveModal,
    ) { }

    confirmLogout(): void {
        this.activeModal.close();
        this.userService.deauthenticate();
        this.router.navigate([
            '/login',
        ]);
    }

    closeModal(): void {
        this.activeModal.dismiss();
    }
}
