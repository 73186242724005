import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from 'environments/environment';

import { AppModule } from './app/';

if (environment.APP_ENV.toLowerCase().startsWith('prod')) {

    const serverErrorsRegex = new RegExp(
        `500 Internal Server Error|401 Unauthorized|403 Forbidden|404 Not Found|502 Bad Gateway|503 Service Unavailable`,
        'mi'
    );

    Sentry.init({
        dsn: 'https://9d7b76e9c5aa4281a05c3f73f30a1f1c@o64948.ingest.sentry.io/5514899',
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: ['http://dev-api.maxcntr.com/api', 'https://qa-api.remax.net/api', 'https://api.remax.net/api'],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],
        tracesSampleRate: 0.1,
        ignoreErrors: [
            serverErrorsRegex,
            'Non-Error promise rejection captured',
        ],
    });

    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
