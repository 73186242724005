import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
    DeviceDetectorService,
    FavoriteService,
    GoogleTagManagerService,
    UserService,
} from '../../../services';
import { GoogleAnalyticsEventsService } from '../../../services/google-analytics-events.service';
import { AppsService } from '../../../../apps/apps.service';
import { App } from '../../../../apps/app.model';
import { SegmentService } from '../../../services/segment.service';
import { SearchService } from '../../../services/search.service';
import { HighlighterPipe } from '../../../pipes';

@Component({
    selector: 'mxc-app-image',
    templateUrl: './app-image.component.html',
    styleUrls: ['./app-image.component.scss'],
})
export class AppImageComponent implements OnInit {
    @Input() app: App;
    @Input() title: string;
    @Input() thumbnailUrl?: string;
    @Input() appId: string;
    @Input() appCategoryId: string;
    @Input() linkUrl: string;
    @Input() newWindow: boolean;
    @Input() showLock: boolean;
    @Input() target_device: string;
    @Input() home?: boolean;
    @Input() highlighted?: boolean;
    @Input() description: string;
    @Input() hasPopover: boolean;
    @Input() signOnMode: string;
    @Input() analyticsLocation?: string;

    cache: { [key: string]: boolean };
    favCacheSubscription: Subscription;
    mobile: boolean;
    searchTerm: string | undefined = undefined;

    get canPopover(): boolean {
        return this.hasPopover && this.description && !this.mobile;
    }

    constructor(
        private userService: UserService,
        private gaService: GoogleAnalyticsEventsService,
        private appsService: AppsService,
        private favoriteService: FavoriteService,
        private deviceDetectorService: DeviceDetectorService,
        private gtmService: GoogleTagManagerService,
        private segmentService: SegmentService,
        public searchService: SearchService,
        public highlighter: HighlighterPipe,
        @Inject(Window) private window: any,
    ) {}

    ngOnInit(): void {
        this.deviceDetectorService.getViewport().subscribe((value) => {
            this.mobile = value < 992;
        });

        this.favCacheSubscription = this.favoriteService
            .getFavoriteCache()
            .subscribe((favCache) => {
                this.cache = favCache.apps;
            });
        this.searchService.searchTerm.subscribe((term) => {
            this.searchTerm = term;
        });
    }

    triggerAnalytics(): void {
        this.gaService.emitEvent('App Opened', this.title, {
            userId: this.userService.user.id,
            appId: this.appId,
            appTitle: this.title,
        });

        this.gtmService.appEvent({
            event_name: 'opened',
            name: this.title,
            is_favorited: this.cache && this.cache[this.app.id] ? 'true' : 'false',
        });

        if (this.analyticsLocation) {
            this.segmentService.segmentTrackAppOpened(this.app, {
                cta: this.app.title,
                location: this.analyticsLocation,
            });
            return;
        }
    }

    storeEvent(): void {
        this.appsService.storeAppEvent(this.appId, 'opened').subscribe();
    }

    clickAction(url: string, target = false): void {
    // see if user is logged in before allowing them to continue to url
        this.userService.continueOrLogout();
        if (this.userService.isLoggedIn()) {
            this.triggerAnalytics();
            this.storeEvent();
            window.open(url, target ? '_blank' : '_self');
        }
    }
}
