<mxc-paywall
  [user]="this.currentUser"
></mxc-paywall>
<mxc-session-timeout
  data-hook="mxc-session-timeout"
  [user]="this.currentUser"
></mxc-session-timeout>
<header class="container-fluid">
  <div class="row align-items-center" id="header">
    <div *ngIf="mobile" class="col-1 d-flex">
      <span>
        <div id="mobile-dimmer-mask" *ngIf="isMenuActive || profileOpen"></div>
        <button
          id="header-menu-trigger"
          (click)="toggleMenu()"
          class="btn btn-icon header-btn-icon"
          [ngClass]="{ active: isMenuActive }"
          data-hook="header-menu-trigger"
        >
          <fa-icon icon="bars" title="Navigation Menu" class="nav-menu-icon"></fa-icon>
        </button>
        <mxc-menu
          [mobile]="mobile"
          [isMenuActive]="isMenuActive"
          *ngIf="isMenuActive"
          (closeMenu)="closeMenu()"
          (clickOutside)="closeMenu()"
          exclude="#header-menu-trigger"
        ></mxc-menu>
      </span>
    </div>
    <div class="col-4 col-sm-2 col-md-2">
      <div class="w-100 ml-2">
        <a *ngIf="hasLandingPage; else default" title="Navigate to Homepage" routerLink="/home">
          <img
            id="header-logo"
            data-hook="header-logo"
            class="img-fluid"
            alt="MAX/Center Logo"
            src="{{ logoNavbar }}"
          />
        </a>
        <ng-template #default>
          <a title="Navigate to Homepage" routerLink="/">
            <img
              id="header-logo"
              data-hook="header-logo"
              class="img-fluid"
              alt="MAX/Center Logo"
              src="{{ logoNavbar }}"
            />
          </a>
        </ng-template>
      </div>
    </div>
    <!-- desktop search -->
    <div *ngIf="!mobile" data-hook="desktop-search-column" class="col-md-5">
      <span class="my-auto w-100">
        <form [formGroup]="searchForm" (ngSubmit)="submitSearch()" autocomplete="off">
          <div class="form-group m-0 w-100">
            <fa-icon class="position-absolute search-icon" icon="search" title="Search Trigger"></fa-icon>
            <input
              data-hook="global-search-input"
              formControlName="term"
              name="search"
              class="form-control w-100 search-input"
              placeholder="Search for apps, news, media or people"
            />
          </div>
        </form>
      </span>
    </div>
    <div class="col-5 col-sm-6 col-md-3">
      <div class="d-flex flex-row justify-content-end">
        <div class="ml-3" (click)="goToView('favorites')" data-hook="favorites-icon">
          <img class="my-auto icon pointer" src="../../../assets/icons/favorites.svg" alt="Favorites Icon" />
        </div>
        <div class="favorites-text my-auto montserrat ml-3 d-none d-sm-block pointer" (click)="goToView('favorites')">My Favorites</div>
      </div>
    </div>
    <div class="col-2 col-sm-3 col-md-2">
      <div class="d-flex flex-row justify-content-end ml-2 ml-sm-0">
        <div class="mr-2">
          <img
            *ngIf="currentUser.photoUrl && currentUser.photoUrl !== '0'; else fallback"
            class="nav-img rounded-circle round-shadow"
            data-hook="header-profile-image"
            src="{{ this.currentUser.id | photoUrl: 'profilePhoto':{ staticTimestamp: true } }}"
            (click)="openProfile()"
          />
          <ng-template #fallback>
            <div
              class="nav-img rounded-circle round-shadow default-user-initials-wrapper d-flex justify-content-center align-items-center"
              data-hook="default-header-profile-image"
              (click)="openProfile()"
            >
              <span class="default-user-initials text-white">{{ userInitials(currentUser) }}</span>
            </div>
          </ng-template>
        </div>
        <div class="my-auto d-flex flex-row d-none dm-sm-block">
          <div class="hello-text montserrat d-none d-sm-block pointer ml-2" (click)="openProfile()">Hello {{ currentUser.firstName }}!</div>
          <div (click)="openProfile()" data-hook="header-down-caret" data-nav="header-open-menu" class="d-none d-sm-block">
            <img class="down-caret pointer ml-4" src="../../../assets/icons/down-caret.svg" />
          </div>
          <span *ngIf="profileOpen" (clickOutside)="closeProfile()" [delayClickOutsideInit]="true" class="profile-menu">
            <div class="d-none d-md-block text-right mt-4 mr-4">
              <fa-icon [icon]="closeIcon" (click)="profileOpen = !profileOpen"></fa-icon>
            </div>
            <div class="text-center header-profile-image-wrapper">
              <a routerLink="/roster/user/{{ this.currentUser.id }}">
                <img
                  *ngIf="currentUser.photoUrl && currentUser.photoUrl !== '0'; else fallback"
                  class="header-profile-image rounded-circle round-shadow"
                  data-hook="profile-menu-image"
                  src="{{ this.currentUser.id | photoUrl: 'profilePhoto':{ staticTimestamp: true } }}"
                />
                <ng-template #fallback>
                  <div
                    class="round-shadow rounded-circle header-profile-image d-flex default-user-initials-wrapper d-flex justify-content-center align-items-center"
                    data-hook="default-profile-menu-image"
                    (click)="openProfile()"
                  >
                    <span class="default-user-initials">{{ userInitials(currentUser) }}</span>
                  </div>
                </ng-template>
              </a>
            </div>
            <div class="text-center">
              <div class="mt-1 name">{{ currentUser.firstName }} {{ currentUser.lastName }}</div>
              <div class="mt-1 email">
                {{ currentUser.remaxEmail }}
              </div>
              <div class="mt-2">
                <a class="update-profile" [href]="getEditUrl()" target="_blank" data-hook="update-profile-link"
                  >Update Profile</a
                >
              </div>
            </div>
            <div class="line mx-4 py-2"></div>
            <div class="d-flex flex-column jusify-content-start mt-3">
              <div class="ml-5 account-options">
                <div>
                  <a routerLink="/roster/user/{{ currentUser.id }}/referrals/received" data-hook="referrals-link"
                     (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event)"
                    >Referrals</a
                  >
                </div>
                <div>
                  <a routerLink="/roster/user/{{ this.currentUser.id }}" data-hook="profile-link"
                     (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event)"
                  >View my profile</a>
                </div>
                <div>
                  <a routerLink="/roster/user/{{ currentUser.id }}/settings" data-hook="settings-link"
                     (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event)"
                  >Settings</a>
                </div>
                <div>
                  <a routerLink="/roster/user/{{ currentUser.id }}/reservations" data-hook="reservations-link"
                     (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event)"
                    >Email Reservations</a>
                </div>
                <div>
                  <a [href]="billPayUrl" target="_blank" data-hook="pay-bill-link">Pay Bill</a>
                </div>
                <div *ngIf="isImpersonator() && userService.isImpersonating && !userService.isImpersonating()">
                  <a (click)="openChangeUserModal(); gtmService.clickEvent({event_name: 'nav', type:'header'}, $event)" data-hook="change-user-menu-item"> Change User </a>
                </div>

                <div *ngIf="userService.isImpersonating && userService.isImpersonating()">
                  <a (click)="revertUser(); gtmService.clickEvent({event_name: 'nav', type:'header'}, $event)" data-hook="stop-impersonation"> Stop Impersonating </a>
                </div>
              </div>
            </div>
            <span class="menu-bottom">
              <div class="text-center mt-md-5">
                <p class="logout" (click)="openLogoutModal(); gtmService.clickEvent({event_name: 'nav', type:'header'}, $event)" data-hook="logout-menu-item">Log Out</p>
              </div>
              <div class="d-flex flex-row justify-content-center menu-box-color">
                <a href="/user-agreement" (click)="gtmService.clickEvent({event_name: 'nav', type:'footer'}, $event)" target="_blank" data-hook="user-agreement-link">User Agreement</a>
                <p>|</p>
                <a href="/privacy-policy" (click)="gtmService.clickEvent({event_name: 'nav', type:'footer'}, $event)" target="_blank" data-hook="privacy-policy-link">Privacy Policy</a>
              </div>
            </span>
          </span>
        </div>
      </div>
    </div>
    <!-- mobile search -->
    <div *ngIf="mobile" class="col-12 d-flex mb-auto mt-1" id="mobile-search-searchbar">
      <form [formGroup]="searchForm" (ngSubmit)="submitSearch()" class="w-100">
        <div class="input-group search-bar input-group-lg">
          <div class="input-group-prepend">
            <button type="button" class="btn btn-icon pl-3" data-hook="global-search-submit-button-mobile">
              <fa-icon icon="search" title="Search"></fa-icon>
            </button>
          </div>
          <input
            formControlName="term"
            name="search"
            type="text"
            class="form-control search-bar px-0"
            placeholder="Search for apps, news, media or people"
            data-hook="global-search-input-mobile"
          />
        </div>
      </form>
    </div>
  </div>
</header>
<div class="row width-adjustment">
  <div id="desktop-menu-trigger" *ngIf="!mobile" data-hook="header-menu-trigger">
    <!-- navigation flyout -->
    <mxc-menu-desktop> </mxc-menu-desktop>
  </div>
</div>
