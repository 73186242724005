import { NgModule } from '@angular/core';
import { SharedModule, AuthGuard } from '../../shared';
import { LayoutModule } from '../../layout/layout.module';

import { LegalModalComponent } from './legal-modal.component';
import { LegalService } from '../legal.service';
import { ReactiveFormsModule } from '@angular/forms';

// font awesome imports
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [
    LayoutModule,
    SharedModule,
    ReactiveFormsModule,
    FontAwesomeModule,
  ],
  declarations: [
    LegalModalComponent,
  ],
  providers: [AuthGuard, LegalService],
  exports: [LegalModalComponent],
})
export class LegalModalModule {
  constructor (
      public library: FaIconLibrary,
  ) {
    library.addIcons(faExclamationTriangle, faCheckCircle);
  }
}
