import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfile } from '../models';
import { UserService } from '../services';

@Injectable({
    providedIn: 'root',
})
export class ProfileTeamMemberGuard implements CanActivate {
    user: UserProfile;

    constructor(private userService: UserService) {
        userService.getMe().subscribe(usr => {
            this.user = usr;
        });
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // check for team access
        return this.canDisplayProfileMenuOption();
    }

    canDisplayProfileMenuOption(): boolean {
        return this.user?.teamStatus === 'MEMBER' || this.user?.teamStatus === 'LEADER';
    }
}
