class imageEnvModel {
  logo: {
    primary: string;
    secondary: string;
    navbar: string;
  };
  zeroState: {
    userProfileCard: string;
    officeProfileCard: string;
  };
}

export const images = (s3Path): imageEnvModel => {
  // Paths
  const imgPath: string = `${s3Path}/img`;
  const brandPath: string = `${s3Path}/brand`;
  // Logos
  const primaryLogo: string = `${brandPath}/mxc-logo.svg?v=3`;
  const secondaryLogo: string = `${brandPath}/mxc-reverse.svg?v=3`;
  // Zero State Images
  const userProfileCard: string = `${imgPath}/user-placeholder.png?v=3`;
  const officeProfileCard: string = `${imgPath}/office-placeholder.png?v=3`;

  return {
    logo: {
      primary: primaryLogo,
      secondary: primaryLogo,
      navbar: secondaryLogo,
    },
    zeroState: {
      userProfileCard: userProfileCard,
      officeProfileCard: officeProfileCard,
    },
  };
};
