
<div class="container">
    <div class="row justify-content-center">
        <div class="col-6 col-sm-5 col-md-4 col-lg-2" data-hook="header-logo">
            <div class="header-logo">
                <img class="img-fluid" src="{{logo}}">
            </div>
        </div>
    </div>
</div>
