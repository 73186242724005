import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CustomerIoService {

    constructor() { }
    public hasBeenIdentified: boolean = false;

    identifyUser(user) {
        if (this.hasBeenIdentified) {
            return;
        }
        if (window['_cio'] && typeof window['_cio'].identify === 'function') {
            window['_cio'].identify({
                id: user.id,
                created_at: user.created_at
            });
            this.hasBeenIdentified = true;
        }
    }

    resetIdentification() {
        if (window['_cio'] && typeof window['_cio'].reset === 'function') {
            window['_cio'].reset();
            this.hasBeenIdentified = false;
        }
    }
}
