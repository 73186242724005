<div id="header-alt" class="jumbotron jumbotron-fluid">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-10 col-sm-6 col-lg-4 text-center welcome-text pb-2" data-hook="header-text">
                <span *ngIf="showWelcomeText; else blank">Welcome to MAX/Center!</span>
                <ng-template #blank>
                    <span>&nbsp;</span>
                </ng-template>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-10 col-sm-6 col-lg-4 pb-5">
                <img class="img-fluid" src="{{logoImage}}">
            </div>
        </div>
    </div>
</div>
