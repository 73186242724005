import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  public searchTerm: ReplaySubject<string> = new ReplaySubject<string>();
  constructor() {}
}
