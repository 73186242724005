import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';

@Pipe({
    name: 'photoUrl',
})

export class PhotoUrlPipe implements PipeTransform {

    static timestamp: number = new Date().getTime();
    photoPath: string = environment.PHOTO_URL;

    transform(input: string | number, inputType?: string, config: { staticTimestamp?: boolean } = {}): string {

        let output = '';
        let t: number;
        if (config.hasOwnProperty('staticTimestamp') && config.staticTimestamp === true) {
            t = PhotoUrlPipe.timestamp;
        } else {
            t = new Date().getTime();
        }

        switch (inputType) {
            case 'profilePhoto': {
                output = this.photoPath + 'Person/' + input + '/MainPhoto_cropped/MainPhoto_cropped.jpg?t=' + t;
                break;
            }
            case 'officePhoto': {
                output = this.photoPath + 'Office/' + input + '/MainPhoto_cropped/MainPhoto_cropped.jpg?t=' + t;
                break;
            }
        }

        return output;
    }
}
