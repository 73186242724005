import { Component, OnInit, Output, EventEmitter, Input, HostListener, ViewChild } from '@angular/core';
import { UserService, UserProfile, GoogleTagManagerService } from '../../../../../shared';
import { MenuService } from '../../../../../shared/services/menu.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LogoutModalComponent } from '../../../../shared/logout-modal/logout-modal.component';
import { Router, NavigationEnd, Event } from '@angular/router';

import { environment } from 'environments/environment';
import { RouteObject } from '../menu.model';
import { faTh, faWifi, faBookmark, faAddressBook, faWrench, faHome } from '@fortawesome/pro-light-svg-icons';
import {
    faChevronLeft,
    faTh as faThSolid,
    faWifi as faWifiSolid,
    faBookmark as faBookmarkSolid,
    faAddressBook as faAddressBookSolid,
    faWrench as faWrenchSolid,
    faHome as faHomeSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { ChangeUserComponent } from '..';

@Component({
    selector: 'mxc-menu-desktop',
    templateUrl: './menu-desktop.component.html',
    styleUrls: ['./menu-desktop.component.scss', '../navigation.scss'],
})
export class MenuDesktopComponent implements OnInit {
    @Output() closeMenu: EventEmitter<any> = new EventEmitter<any>();
    @Output() openMenu: EventEmitter<any> = new EventEmitter<any>();
    @Input() open: boolean;
    @Input() mobile: boolean;

    // handling for closing the subnav
    navigated = false;
    insideClick = false;

    // Fontawesome icons
    appsIcon = faTh;
    newsIcon = faWifi;
    savedIcon = faBookmark;
    findIcon = faAddressBook;
    toolsIcon = faWrench;
    homeIcon = faHome;
    appsIconSolid = faThSolid;
    newsIconSolid = faWifiSolid;
    savedIconSolid = faBookmarkSolid;
    findIconSolid = faAddressBookSolid;
    toolsIconSolid = faWrenchSolid;
    homeIconSolid = faHomeSolid;
    chevronLeftIcon = faChevronLeft;

    s3Path = environment.STATIC_S3_URL;

    // defined in OnInit
    findNavigation: RouteObject[];

    findNavKey = 'find-refer';

    currentRoute = '';

    serviceNavigation: RouteObject[] = [
        {
            path: environment.BILLING_PAYMENT_URL,
            name: 'Pay Bill',
            icon: 'dollar-sign',
            navKey: 'pay-bill'
        },
    ];

    appNavigation: Array<RouteObject[]> = [
        [
            {
                path: '/home',
                name: 'Home',
                icon: this.homeIcon,
                nestedNavName: 'Home',
                activeIcon: this.homeIconSolid,
                navKey: 'dashboard'
            },
        ],
        [
            {
                path: '/apps',
                name: 'Apps',
                icon: this.appsIcon,
                nestedNavName: 'Apps',
                activeIcon: this.appsIconSolid,
                navKey: 'apps'
            },
        ],
        [
            // news navigation
            {
                path: '/content',
                name: 'RE/MAX: News & Resources',
                nestedNavName: 'News & Resources',
                icon: this.newsIcon,
                activeIcon: this.newsIconSolid,
                navKey: 'content'
            },
        ],
        [
            {
                path: '/favorites',
                name: 'Favorites',
                icon: this.savedIcon,
                nestedNavName: 'Favorites',
                activeIcon: this.savedIconSolid,
                navKey: 'favorites'
            },
        ],
        this.getFindNavigations(),
        [
            // tools navigation
            {
                path: '/shortener',
                name: 'URL Shortener',
                nestedNavName: 'Tools',
                nestedNavKey: 'tools',
                icon: this.toolsIcon,
                activeIcon: this.toolsIconSolid,
                navKey: 'url-shortener'
            },
            {
                path: '/join',
                name: 'Customize JoinRE/MAX',
                nestedNavName: 'Tools',
                nestedNavKey: 'tools',
                icon: this.toolsIcon,
                activeIcon: this.toolsIconSolid,
                navKey: 'join-remax'
            },
        ],
    ];

    impersonating = this.userService.isImpersonating();

    currentUser: UserProfile;
    closeResult: string;
    openModal: NgbModalRef;
    hasLandingPage: boolean;

    selectedNav: RouteObject[];
    lastSelectedNav: RouteObject[];

    @HostListener('click')
    clickInside(): void {
        this.insideClick = true;
    }

    @HostListener('document:click')
    clickout(): void {
        if (!this.insideClick) {
            // clicked outside
            this.open = false;

            if (this.selectedNav) {
                if (!(this.selectedNav[0].path.indexOf(this.router.url) >= 0) && this.lastSelectedNav) {
                    this.selectedNav = this.lastSelectedNav;
                }
            }
        }
        this.insideClick = false;
    }

    constructor(
        private userService: UserService,
        private modalService: NgbModal,
        private router: Router,
        public menuService: MenuService,
        public gtmService: GoogleTagManagerService,
    ) {
        this.selectedNav = this.appNavigation[this.menuService.routeIndex];
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
                this.appNavigation.forEach(navs => {
                    navs.forEach(nav => {
                        if (event.url.indexOf(nav.path) >= 0) {
                            this.menuService.currentRouteIndex = this.appNavigation.indexOf(navs);
                        }
                    });
                });
            }
        });
    }

    ngOnInit(): void {
        this.userService.getMe().subscribe(res => {
            this.currentUser = res;
            this.hasLandingPage = this.currentUser.features.filter(feature => feature.slug === 'home').length > 0;
        });
        this.appNavigation = this.appNavigation.filter(i => !!i);
        this.appNavigation.forEach(navs => {
            navs.forEach(nav => {
                if (this.router.url.indexOf(nav.path) >= 0) {
                    this.menuService.currentRouteIndex = this.appNavigation.indexOf(navs);
                }
                // sets the initial selectedNav on init of component
                if (this.router.url.startsWith(nav.path)) {
                    this.selectedNav = navs;
                    this.lastSelectedNav = this.selectedNav;
                }
            });
        });
    }

    getFindNavigations(): RouteObject[] {
        let currentUser: UserProfile;
        if (!this.currentUser) {
            this.userService.getMe().subscribe(res => {
                currentUser = res;
            });
        } else {
            currentUser = this.currentUser;
        }

        const isCorporate = currentUser.groups.includes('Corporate');
        if (!isCorporate) {
            if (currentUser.class === 'INDIV' || currentUser.class === 'STAFF' || currentUser.class === 'TEAM') {
                return [
                    {
                        path: '/roster/user/list',
                        name: 'Find',
                        icon: this.findIcon,
                        nestedNavName: 'Find',
                        nestedNavKey: this.findNavKey,
                        activeIcon: this.findIconSolid,
                        navKey: 'find-agent'
                    },
                ];
            } else if (currentUser.class === 'APVCNT' || currentUser.class === 'APVSUP') {
                return [
                    {
                        path: '/approved-supplier/user',
                        name: 'Find',
                        icon: this.findIcon,
                        nestedNavName: 'Find',
                        nestedNavKey: this.findNavKey,
                        activeIcon: this.findIconSolid,
                        navKey: 'find-agent-approved-supplier'
                    },
                ];
            } else if (currentUser.class === 'OFFICE') {
                return [
                    {
                        path: '/roster/office/list',
                        name: 'Find',
                        icon: this.findIcon,
                        nestedNavName: 'Find',
                        nestedNavKey: this.findNavKey,
                        activeIcon: this.findIconSolid,
                        navKey: 'find-office'
                    },
                ];
            }
        } else {
            return [
                {
                    path: '/roster/user/list',
                    name: 'Agents/Create a Referral',
                    nestedNavName: 'Find',
                    nestedNavKey: this.findNavKey,
                    icon: this.findIcon,
                    activeIcon: this.findIconSolid,
                    navKey: 'find-agent'
                },
                {
                    path: '/approved-supplier/user',
                    name: 'Agents (Approved Suppliers)',
                    nestedNavName: 'Find',
                    nestedNavKey: this.findNavKey,
                    icon: this.findIcon,
                    activeIcon: this.findIconSolid,
                    navKey: 'find-agent-approved-supplier'
                },
                {
                    path: '/roster/office/list',
                    name: 'Offices',
                    icon: this.findIcon,
                    activeIcon: this.findIconSolid,
                    navKey: 'find-office',
                    nestedNavKey: this.findNavKey,
                },
                {
                    path: '/approved-supplier/office',
                    name: 'Offices (Approved Supplier)',
                    nestedNavName: 'Find',
                    nestedNavKey: this.findNavKey,
                    icon: this.findIcon,
                    activeIcon: this.findIconSolid,
                    navKey: 'find-office-approved-supplier'
                },
                {
                    path: '/roster/corporate/list',
                    name: 'Corporate Staff',
                    nestedNavName: 'Find',
                    nestedNavKey: this.findNavKey,
                    icon: this.findIcon,
                    activeIcon: this.findIconSolid,
                    navKey: 'find-agent-corporate'
                },
            ];
        }
    }

    triggerGTMEvent(text: string): void {
        this.gtmService.clickEvent( {
            event_name: 'nav',
            element_text: text,
            type: 'header',
        });
    }

    stopImpersonating(): void {
        this.userService.revertUser().subscribe(() => location.reload());
    }

    openLogoutModal(): void {
        this.openModal = this.modalService.open(LogoutModalComponent);
    }

    close(): void {
        this.closeMenu.emit();
    }
    openNav(): void {
        this.openMenu.emit();
    }

    setSelectedNav(index: number): void {
        this.lastSelectedNav = this.selectedNav;
        this.selectedNav = this.appNavigation[index];
        this.menuService.routeIndex = index;
    }

    navigateToRoute(path: string): void {
        this.navigated = true;
        this.router.navigateByUrl(path);
    }

    getIcon(navIndex: number): void {
        if (navIndex === this.appNavigation.indexOf(this.selectedNav)) {
            return this.appNavigation[navIndex][0].activeIcon;
        } else {
            return this.appNavigation[navIndex][0].icon;
        }
    }

    openChangeUserModal(): void {
        this.openModal = this.modalService.open(ChangeUserComponent);
    }

    isImpersonator(): boolean {
        if (this.currentUser.groups.includes('Super Admin') || this.currentUser.groups.includes('Impersonators')) {
            return true;
        }
        return false;
    }

    toggleMenu(nav: RouteObject[]): void {
    // logic that prevents closing the submenu
    // when clicking on another nav item with a submenu
        if (nav !== this.lastSelectedNav) {
            this.open = true;
        } else {
            this.open = !this.open;
        }
    }
}
