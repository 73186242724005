import { Injectable } from '@angular/core';

@Injectable()
export class SharedService {
    copyToClipboard(value): void {
        const tempInput = document.createElement('input');

        // create a temporary input from which to select the text
        document.body.appendChild(tempInput);

        // populate the temp input with the value we want to copy
        tempInput.contentEditable = 'true';
        tempInput.setAttribute('value', value);
        tempInput.select();
        document.execCommand('copy');

        // destroy temporary input
        document.body.removeChild(tempInput);
    }

}
